import { APPS } from 'utils/constants.js';
import { exportRoutesWithAppMetaField } from 'utils/helpers.js';
import { TRANSLATION_TAGS } from 'utils/translate';

export const ratingsModuleRoutes = exportRoutesWithAppMetaField(APPS.RATINGS, [
  {
    path: '/:country_code/ratings',
    component: {
      render: () => <RouterView />
    },
    meta: {
      locale: 'en-US'
    },
    children: [
      {
        path: '',
        name: 'ratings',
        component: () =>
          import(/* webpackChunkName: "ratings-digital" */ '../../views/pages/ratings/Ratings.vue'),
        meta: {
          auth: true,
          module: 'ratings',
          hasCountrySwitcher: true,
          translation_tags: [TRANSLATION_TAGS.FILTERS, TRANSLATION_TAGS.RATINGS]
        }
      },
      {
        path: 'details/:id/:type',
        component: {
          render: () => <RouterView />
        },
        children: [
          {
            path: '',
            name: 'ratings.details',
            component: () =>
              import(
                /* webpackChunkName: "ratings-digital" */ '../../views/pages/ratings/RatingsDetails.vue'
              ),
            meta: {
              auth: true,
              checkRights: true,
              module: 'detail',
              translation_tags: [
                TRANSLATION_TAGS.FILTERS,
                TRANSLATION_TAGS.REACH_CHART,
                TRANSLATION_TAGS.RATINGS
              ]
            }
          },
          {
            path: 'targets',
            name: 'ratings.details.targets',
            component: () =>
              import(
                /* webpackChunkName: "ratings-digital" */ '../../views/pages/ratings/RatingsDetailsTargets.vue'
              ),
            meta: { auth: true, checkRights: true, module: 'detail' }
          },
          {
            path: 'export',
            name: 'ratings.details.export',
            component: () =>
              import(
                /* webpackChunkName: "ratings-digital" */ '../../views/pages/ratings/RatingsDetailsExport.vue'
              ),
            meta: {
              auth: true,
              module: 'ratings',
              translation_tags: [TRANSLATION_TAGS.FILTERS]
            }
          },
          {
            path: 'urls',
            name: 'ratings.details.channel.urls',
            component: () =>
              import(
                /* webpackChunkName: "ratings-digital" */ '../../views/pages/ratings/RatingsDetailsChannelUrls.vue'
              ),
            meta: { auth: true, checkRights: true, module: 'detail' }
          },
          {
            path: 'services',
            name: 'ratings.details.services',
            component: () =>
              import(
                /* webpackChunkName: "ratings-digital" */ '../../views/pages/ratings/RatingsDetailsAllSubServices.vue'
              ),
            meta: { auth: true, checkRights: true, module: 'detail' }
          },
          {
            path: 'search-metrics',
            name: 'ratings.details.search-metrics',
            component: () =>
              import(
                /* webpackChunkName: "ratings-digital" */ '../../views/pages/ratings/RatingsDetailsAllSearchMetrics.vue'
              ),
            meta: { auth: true, checkRights: true, module: 'detail' }
          },
          {
            path: ':movement_type/services',
            name: 'ratings.details.all-traffic-movements',
            component: () =>
              import(
                /* webpackChunkName: "ratings-digital" */ '../../views/pages/ratings/RatingsDetailsAllTrafficMovements.vue'
              ),
            meta: { auth: true, checkRights: true, module: 'detail' }
          }
        ]
      },
      {
        path: 'combine',
        component: {
          render: () => <RouterView />
        },
        children: [
          {
            path: '',
            name: 'ratings.combine',
            component: () =>
              import(
                /* webpackChunkName: "ratings-digital" */ '../../views/pages/ratings/RatingsCombine.vue'
              ),
            meta: {
              auth: true,
              module: 'detail',
              translation_tags: [
                TRANSLATION_TAGS.FILTERS,
                TRANSLATION_TAGS.REACH_CHART,
                TRANSLATION_TAGS.RATINGS
              ]
            }
          },
          {
            path: 'export',
            name: 'ratings.combine.export',
            component: () =>
              import(
                /* webpackChunkName: "ratings-digital" */ '../../views/pages/ratings/RatingsCombineExport.vue'
              ),
            meta: {
              auth: true,
              module: 'ratings',
              translation_tags: [TRANSLATION_TAGS.FILTERS]
            }
          },
          {
            path: 'service-select',
            name: 'ratings.combine.service.select',
            component: () =>
              import(
                /* webpackChunkName: "ratings-digital" */ '../../views/pages/ratings/RatingsCombineSelect.vue'
              ),
            meta: {
              auth: true,
              module: 'detail',
              translation_tags: [TRANSLATION_TAGS.FILTERS]
            }
          }
        ]
      },
      {
        path: 'compare',
        component: {
          render: () => <RouterView />
        },
        children: [
          {
            path: '',
            name: 'ratings.compare',
            component: () =>
              import(
                /* webpackChunkName: "ratings-digital" */ '../../views/pages/ratings/RatingsCompare.vue'
              ),
            meta: {
              auth: true,
              module: 'detail',
              translation_tags: [TRANSLATION_TAGS.FILTERS]
            }
          },
          {
            path: 'service-select',
            name: 'ratings.compare.service.select',
            component: () =>
              import(
                /* webpackChunkName: "ratings-digital" */ '../../views/pages/ratings/RatingsCompareSelect.vue'
              ),
            meta: {
              auth: true,
              module: 'detail',
              translation_tags: [TRANSLATION_TAGS.FILTERS]
            }
          }
        ]
      },
      {
        path: 'audience-reach',
        component: {
          render: () => <RouterView />
        },
        children: [
          {
            path: '',
            name: 'ratings.audience-reach',
            component: () =>
              import(
                /* webpackChunkName: "ratings-digital" */ '../../views/pages/ratings/RatingsAudienceReach.vue'
              ),
            meta: {
              auth: true,
              module: 'detail',
              translation_tags: [TRANSLATION_TAGS.FILTERS]
            }
          },
          {
            path: 'select',
            name: 'ratings.audience-reach.select',
            component: () =>
              import(
                /* webpackChunkName: "ratings-digital" */ '../../views/pages/ratings/RatingsAudienceReachSelect.vue'
              ),
            meta: {
              auth: true,
              module: 'detail',
              translation_tags: [TRANSLATION_TAGS.FILTERS]
            }
          }
        ]
      },
      {
        path: 'channels',
        component: {
          render: () => <RouterView />
        },
        children: [
          {
            path: '',
            name: 'ratings.channels',
            component: () =>
              import(
                /* webpackChunkName: "ratings-digital" */ '../../views/pages/ratings/channels/RatingsChannels.vue'
              ),
            meta: { auth: true, module: 'ratings' }
          },
          {
            path: 'create',
            name: 'ratings.channels.create',
            component: () =>
              import(
                /* webpackChunkName: "ratings-digital" */ '../../views/pages/ratings/channels/RatingsChannelCreate.vue'
              ),
            meta: { auth: true, module: 'ratings' }
          },
          {
            path: ':id/edit',
            name: 'ratings.channels.edit',
            component: () =>
              import(
                /* webpackChunkName: "ratings-digital" */ '../../views/pages/ratings/channels/RatingsChannelEdit.vue'
              ),
            meta: { auth: true, module: 'ratings' }
          }
        ]
      }
    ]
  }
]);
