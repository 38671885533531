import state from './state';
import mutations from './mutations';
import actions from './actions';

// see ../adminModule/index.js for reference in case submodules are needed in the future
export default {
  namespaced: true,
  state,
  mutations,
  actions
};
