/* eslint-disable */
import { $ } from 'libs';
import tinysort from 'tinysort';

var table = '.js-sorting-table',
  button = '.js-sorting-button',
  list = '.js-sorting-list',
  listItem = '.js-sorting-item',
  sortedClass = 'is-sorted';

var bindTables = function (element) {
  var elem = element || table;

  $(elem).each(function () {
    var $table = $(this);
    $table.find(button).on('click', function () {
      var $button = $(this);

      // Determine the order
      var order = getOrder($button);

      // Deselect the rest of the buttons
      $button
        .closest(table)
        .find('.' + sortedClass)
        .removeClass(sortedClass);

      // Enable this button
      toggleButton($button, order);

      $button
        .closest(table)
        .find(list)
        .each(function () {
          // Sort the table based on the provided button
          var sortBy = '.' + $button.data('sort'),
            listItems = $(this).find(listItem).get();

          // Sort the table
          tinysort(listItems, {
            selector: sortBy,
            order: order,
            attr: 'sortValue'
          });
        });
    });
  });
};

var getOrder = function ($button) {
  if ($button.hasClass(sortedClass)) {
    if ($button.data('sort-by') == 'desc') {
      return 'asc';
    } else {
      return 'desc';
    }
  } else {
    return 'desc';
  }
};

var toggleButton = function ($button, order) {
  if (order == 'asc') {
    $button.removeClass(sortedClass + '--desc');
  } else {
    $button.removeClass(sortedClass + '--asc');
  }

  // Enable the button
  $button.addClass(sortedClass);
  $button.addClass(sortedClass + '--' + order);
  $button.data('sort-by', order);
};

var init = function () {
  // Bind the existing tables
  bindTables();
};

export default {
  init: function () {
    // This method initializes the sorting functionality
    init();
  },
  rebind: function () {
    // This method binds the sorting functionality
    // to elements added with javascript.
    bindTable(element);
  }
};
