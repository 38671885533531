import useAxios, { API_METHODS } from 'composables/useAxios';
import { getApiRoute } from 'routes/config';

const useSurveys = () => {
  const {
    state: { loading: findAllLoading, error: findAllError },
    fetcher: findAllFetcher
  } = useAxios({
    initialValue: []
  });
  const {
    state: { loading: findLoading, error: findError },
    fetcher: findFetcher
  } = useAxios({
    initialValue: {}
  });
  const { fetcher: createFetcher } = useAxios();
  const { fetcher: removeFetcher } = useAxios();
  const {
    state: { loading: removeAllLoading, error: removeAllError },
    fetcher: removeAllFetcher
  } = useAxios();

  const findAll = campaignId =>
    findAllFetcher({
      url: getApiRoute({
        name: 'api.admin.campaigns.get.surveys',
        params: {
          id: campaignId
        }
      })
    });

  const find = surveyId =>
    findFetcher({
      url: getApiRoute({
        name: 'api.admin.campaigns.survey.data',
        params: {
          id: surveyId
        }
      }),
      responseAdapter: ({ survey }) => survey
    });

  const create = ({ campaignId, survey }) =>
    createFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: 'api.admin.campaigns.survey.create.raw',
        params: {
          campaign: campaignId
        }
      }),
      requestBody: {
        ...survey
      }
    });

  const remove = surveyId =>
    removeFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: 'api.admin.campaigns.survey.delete',
        params: {
          id: surveyId
        }
      })
    });

  const removeAll = campaignId =>
    removeAllFetcher({
      method: API_METHODS.DELETE,
      url: getApiRoute({
        name: 'api.admin.campaigns.surveys.delete',
        params: {
          campaign: campaignId
        }
      })
    });

  return {
    findAllLoading,
    findAllError,
    findAll,
    findLoading,
    findError,
    find,
    create,
    remove,
    removeAll,
    removeAllLoading,
    removeAllError
  };
};

export default useSurveys;
