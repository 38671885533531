import { appRoutes } from './app-routes.js';
import { apiRoutes } from './api-routes.js';
import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import { store } from '../main.js';
import { jumpTo, isObject, requiredParam, clearCache } from '../utils/helpers.js';
import { BRANCHNAME, API_PATH, OEWA_BUILD, OEWA_PUBLIC_BUILD, DEV_STAGING_BUILD } from '../constants';
import { Action, Mutation as GlobalMutation } from '../store/app/_global/types';
import { APPS, REPPUBLIKA_THEMED_APPS, THEMES, VUE_ROUTER_MODES } from '../utils/constants';
import { OEWA_DOMAIN } from '../views/oewa/OewaConstants';
import { loadTranslations } from 'plugins/i18n';
import { TRANSLATION_TAGS } from 'utils/translate';

export const resolveRoute = (route = requiredParam('route'), newTab = false) => {
  const resolvedRoute = router.resolve(route).fullPath;

  if (!newTab) return resolvedRoute;

  const branchName = BRANCHNAME && BRANCHNAME !== 'master' ? `/${BRANCHNAME}` : '';

  return `${window.location.origin}${branchName}${resolvedRoute}`;
};

export const getApiRoute = (route = requiredParam('route')) => {
  const routeName = isObject(route) ? route.name : route;

  if (!apiRoutes[routeName]?.path) {
    throw new Error(`Unrecognisable API route: ${routeName}`);
  }

  return `${API_PATH}${getResolvedApiRoute(route)}`;
};

const getResolvedApiRoute = route => {
  const additionalParams = {
    country_code: store.state.selectedCountryCode || localStorage.getItem('selectedCountryCode')
  };

  if (isObject(route)) {
    return resolveRoute({ ...route, params: { ...(route.params || {}), ...additionalParams } });
  }

  return resolveRoute({ name: route, params: { ...additionalParams } });
};

export const append = (path, pathToAppend) => path + (path.endsWith('/') ? '' : '/') + pathToAppend;

const routes = [...appRoutes];

for (let record in apiRoutes) {
  routes.push(apiRoutes[record]);
}

const getBase = () => {
  if (!BRANCHNAME) {
    return '/';
  }

  if (!!DEV_STAGING_BUILD) {
    return '/dev';
  }

  if (!!OEWA_BUILD) {
    return window.location.pathname;
  }

  if (!!OEWA_PUBLIC_BUILD) {
    if (BRANCHNAME === 'master' || BRANCHNAME === 'staging') {
      return '/oewa-public';
    }

    return `/oewa-public_${BRANCHNAME}`;
  }

  if (BRANCHNAME !== 'master') {
    return `/${BRANCHNAME}`;
  }

  return '/';
};

const getRouterMode = () => {
  if (location.hostname !== 'dashboard.oewa.at' && (!!OEWA_BUILD || !!OEWA_PUBLIC_BUILD)) {
    return VUE_ROUTER_MODES.HASH;
  }

  return VUE_ROUTER_MODES.HISTORY;
};

export const routerMode = getRouterMode();

const getHistory = () => {
  const base = getBase();

  return routerMode === VUE_ROUTER_MODES.HASH ? createWebHashHistory(base) : createWebHistory(base);
};

const router = createRouter({
  history: getHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      jumpTo(to.hash);
    }

    return { left: 0, top: 0 };
  }
});

router.beforeEach(async to => {
  const { locale } = to.matched.find(record => !!record.meta.locale)?.meta || {};
  const { translation_tags = [] } = to.meta;

  try {
    await loadTranslations({ locale, tags: [TRANSLATION_TAGS.GENERIC, ...translation_tags] });
  } catch (error) {
    console.error('Error while loading translations: ', error);
  }

  const isPrivate = to.matched.some(record => record.meta.auth);

  if (!isPrivate) return;

  // Returns true if the route requires the user to be admin
  const needsPriviledge = to.matched.some(record => record.meta.admin);

  // Returns true if the route requires to check the access level rights
  const checkRights = to.matched.some(record => record.meta.checkRights);

  const checkUserSession = await store.dispatch(Action.CHECK_USER_SESSION, {
    to,
    needsPriviledge,
    checkRights
  });

  store.commit(GlobalMutation.SET_PAGE_LOADING, false);

  return checkUserSession;
});

const setTheme = theme => {
  document.documentElement.setAttribute('data-theme', theme);
};

// Prevent errors when using browser back button
router.beforeResolve(() => {
  store.commit(GlobalMutation.SET_NAVIGATION, []);
  store.commit(GlobalMutation.SET_BREADCRUMBS, []);
});

router.afterEach(to => {
  const app = to.meta.app;
  const currentLocale = store.state.selectedCountryCode;
  const toLocale = to.params.country_code;

  store.commit(GlobalMutation.SET_ACTIVE_APP, app);

  if (!!toLocale && currentLocale !== toLocale) {
    store.commit(GlobalMutation.SET_SELECTED_COUNTRY_CODE, toLocale);

    clearCache();
  }

  if (app === APPS.OEWA_RATINGS) {
    return setTheme(THEMES.OEWA);
  }

  if (REPPUBLIKA_THEMED_APPS.includes(app)) {
    return setTheme(THEMES.REPPUBLIKA);
  }

  const isAppHostedOnOewa = window.location.hostname.includes(OEWA_DOMAIN);

  setTheme(isAppHostedOnOewa ? THEMES.OEWA : THEMES.REPPUBLIKA);
});

export default router;
