import useAxios, { API_METHODS } from 'composables/useAxios';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { getApiRoute } from 'routeconfig';
import { buildFormData } from 'helper';

const useManageCustomers = () => {
  const store = useStore();

  const isAdmin = computed(() => store.getters.isAdmin);

  const {
    state: { loading: findLoading, error: findError, data: item },
    fetcher: findFetcher
  } = useAxios();
  const {
    state: { loading: saving },
    fetcher: saveFetcher
  } = useAxios();

  /**
   * Non-admin customer should have a filtered-out list of available modules
   * based on modules that were selected by the super-admin when creating the customer.
   * #REPFE-1613.
   *
   * @param Array availableModules
   * @param Array selected_customer
   * @returns Array
   */
  const getCustomerAvailableModules = ({ availableModules, selected_customer }) => {
    // TODO: Also, if the full list is not used anywhere by the limited user,
    // then this filtering should be done in the BE #REPFE-1613.
    if (isAdmin.value) {
      return availableModules;
    }
    if (!availableModules.length || !selected_customer?.modules.length) {
      return availableModules;
    }

    const currentUserModules = {};
    selected_customer.modules.forEach(({ name }) => {
      currentUserModules[name] = true;
    });

    const filterChildren = module => currentUserModules[module.name];
    const traverseChildren = module => {
      if (module.children) module.children = module.children.filter(filterChildren).map(traverseChildren);
      return module;
    };

    return availableModules.filter(filterChildren).map(traverseChildren);
  };

  const loadData = customerId => {
    // TODO: Just pass `selectedCustomerId.value` when #REPFE-2984 is completed #REPFE-1613.
    const query = {};
    if (isAdmin.value && (!!customerId || customerId === 0)) {
      query.customer = customerId;
    }

    const url = getApiRoute({ name: 'api.customer.details', query });

    return findFetcher({
      url: url,
      responseAdapter: response => {
        const { availableModules = [], selected_customer = {} } = response;
        return {
          ...response,
          availableModules: getCustomerAvailableModules({ availableModules, selected_customer })
        };
      }
    });
  };

  const save = ({ id, payload }) => {
    payload.customerID = id;
    return saveFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: 'api.customer.save'
      }),
      requestBody: buildFormData(payload)
    });
  };

  return {
    save,
    saving,
    loadData,
    findLoading,
    findError,
    item
  };
};

export default useManageCustomers;
