import { BRANCHNAME } from '../constants.js';

export const ALLOWED_TOKEN_PROPERTIES = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  TOKEN_TYPE: 'token_type'
};

export const APPS = {
  HOME: 'home',
  RATINGS: 'ratings',
  OEWA_RATINGS: 'owa_ratings',
  CAMPAIGN_CONTROL: 'campaign',
  PUBLISHER: 'publisher',
  DMP_FEED: 'dmpFeed',
  KEYWORD_TOOL: 'keyword_tool',
  STREAMING: 'streaming',
  MANAGEMENT: 'admin',
  BRANDLIFT: 'brandlift_benchmark',
  SOCIAL_ANALYTICS: 'social_analytics',
  ADEFFX: 'adeffx'
};

export const REPPUBLIKA_THEMED_APPS = [
  APPS.RATINGS,
  APPS.CAMPAIGN_CONTROL,
  APPS.PUBLISHER,
  APPS.MANAGEMENT,
  APPS.DMP_FEED,
  APPS.STREAMING,
  APPS.KEYWORD_TOOL,
  APPS.BRANDLIFT,
  APPS.SOCIAL_ANALYTICS,
  APPS.ADEFFX
];

export const THEMES = {
  REPPUBLIKA: 'reppublika',
  OEWA: 'oewa'
};

export const ALLOWED_FILTERS = {
  RATINGS: ['date', 'interval_type', 'calculation_type'],
  RATINGS_DETAILS: ['date', 'interval_type', 'calculation_type', 'registered_only', 'device_type']
};

export const CHART_COLORS = ['#b36cc4', '#eda6ff', '#ffcb00', '#ed1e79', '#a10a4c', '#288fab'];

export const VUE_ROUTER_MODES = {
  HISTORY: 'history',
  HASH: 'hash'
};

export const INTERVAL_TYPES = {
  MONTH: 'month',
  QUARTER: 'quarter',
  HALF_YEAR: 'halfyear',
  YEAR: 'year'
};

export const CALCULATION_TYPES = {
  ACCUMULATED: 'accumulated',
  AVERAGE_MONTH: 'average_month',
  AVERAGE_WEEK: 'average_week',
  AVERAGE_DAY: 'average_day',
  AVERAGE_WORKDAY: 'average_workday',
  AVERAGE_WEEKEND_DAY: 'average_weekend_day'
};

export const ASSET_PATH = !!BRANCHNAME && BRANCHNAME !== 'master' ? `/${BRANCHNAME}/` : '/';

export const PASSWORD_RESET_TYPES = {
  SET: 'set',
  RESET: 'reset'
};
