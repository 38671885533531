import router from 'routeconfig';

export default {
  isAdmin: state => {
    return state.currentUser && state.currentUser.admin;
  },
  ratingsPoolHasItemWithUniquePanelistsProperty: (state, getters) =>
    getters.isAdmin && state.ratingsPool.some(item => !!item.stats?.unique_panelists),
  currentUserModules: state => {
    if (!state.currentUser) {
      return {};
    }

    const currentUserModules = {};

    state.currentUser.modules.forEach(({ name }) => {
      currentUserModules[name] = true;
    });

    return currentUserModules;
  },
  countries: state => state.currentUser?.countries || [],
  selectedCountry: (state, getters) => {
    if (!state.currentUser) return;

    const country = getters.countries.find(country => country.code === state.selectedCountryCode);

    return country || getters.countries[0];
  },
  isPageFiltered: () => !!Object.keys(router.currentRoute.value.query).length,
  appliedPageFilters: state => {
    return state.pageFilters.filter(pageFilter => {
      if (Array.isArray(pageFilter.value)) {
        return !!pageFilter.value.length;
      }

      // if the filter is of type number, it should be treated distinctively because its default value is zero
      if (pageFilter.type === 'number') {
        return !!pageFilter.value;
      }

      return !!pageFilter.value || pageFilter.value === 0;
    });
  },
  // TODO removable
  visiblePageFilters: state => {
    return state.pageFilters.filter(filter => filter.visible);
  }
};
