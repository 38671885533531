<template>
  <div class="c-page-hero__header u-fixed-t cf">
    <div class="o-container u-pt-x4 u-pb-x4 u-pt-x2@800 u-pb-x2@800">
      <div class="o-grid o-grid--middle">
        <div class="o-grid__col u-6/24 u-7/24@800 u-6/24@400">
          <router-link :to="{ name: 'initialpage' }">
            <img :src="`${ASSET_PATH}img/ReppublikaColor.svg`" class="c-logo--medium u-inline-block" />
          </router-link>
        </div>

        <div class="o-grid__col u-18/24 u-text-right u-17/24@800 u-18/24@400">
          <slot name="links"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ASSET_PATH } from 'utils/constants.js';

export default {
  setup() {
    return {
      ASSET_PATH
    };
  }
};
</script>
