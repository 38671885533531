import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import qs from 'qs';
import { getApiRoute } from 'routeconfig';
import { useAxios } from 'composables';
import { API_ROUTES, FILTER_KEYS } from 'views/oewa/OewaConstants';
import { shallowEqual } from 'helper';
import { translateFilters } from 'utils/translate';
import { formatDataFrame } from 'utils/formatDataFrame';

const filters = ref();

export const appliedFilters = computed(() => {
  if (!filters.value) return;

  return Object.fromEntries(filters.value.map(filter => [filter.key, filter.value]));
});

// TODO temporary
const adaptOptions = filter => {
  if (filter.key !== FILTER_KEYS.DATA_FRAME) {
    return filter.options;
  }

  return filter.options.map(option => {
    const { date, interval_type, calculation_type } = option;

    let formattedDataFrame = formatDataFrame({
      date,
      intervalType: interval_type
    });

    if (!!calculation_type) {
      formattedDataFrame = `${formattedDataFrame} (Zervice)`;
    }

    return {
      ...option,
      name: formattedDataFrame
    };
  });
};

const useOewaRatingsFilters = () => {
  const route = useRoute();
  const {
    state: { loading },
    fetcher
  } = useAxios();

  const loadingFilters = computed(() => loading.value || !filters.value);

  const api = computed(() => route.meta.api);

  const fetchRatingsFilters = () => {
    resetFilters();

    return fetchFilters(getApiRoute(API_ROUTES.RANKING_LIST.FILTERS[api.value]));
  };

  const fetchRatingsDetailsFilters = () => {
    resetFilters();

    return fetchFilters(getApiRoute(API_ROUTES.RANKING_ITEM.FILTERS[api.value]));
  };

  const fetchFilters = apiRoute => {
    resetFilters();

    return fetcher({
      url: apiRoute,
      responseAdapter: filters => {
        const parsedQuery = qs.parse(route.query);

        return filters.map(filter => {
          const newFilter = {
            ...filter,
            label: translateFilters(`filter>${filter.key}`)
          };

          const filterValueFromQuery = parsedQuery?.[newFilter.key];
          const defaultValue = newFilter.options[0];

          if (!filterValueFromQuery) {
            return {
              ...newFilter,
              value: defaultValue,
              options: adaptOptions(newFilter)
            };
          }

          const value = newFilter.options.find(option => shallowEqual(option, filterValueFromQuery));

          if (!value) {
            return {
              ...newFilter,
              options: adaptOptions(newFilter),
              value: defaultValue
            };
          }

          return {
            ...newFilter,
            options: adaptOptions(newFilter),
            value
          };
        });
      }
    }).then(data => {
      filters.value = data;
    });
  };

  const resetFilters = () => {
    filters.value = null;
  };

  return {
    loadingFilters,
    fetchRatingsFilters,
    fetchRatingsDetailsFilters,
    filters
  };
};

export default useOewaRatingsFilters;
