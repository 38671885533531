import { RATINGS_METHOD_NAMES } from './constants';
import RATINGS_METHODS from './ratings/methods.js';

const ALL_METHOD_NAMES = {
  RATINGS_WORKER: {
    ...RATINGS_METHOD_NAMES
  }
};

const ALL_METHODS = {
  RATINGS_WORKER: { ...RATINGS_METHODS }
};

export { ALL_METHOD_NAMES, ALL_METHODS, RATINGS_METHOD_NAMES };
