import { Mutation } from '../types';

export default {
  [Mutation.STORE_CONTRACTS]: (store, value) => {
    store.contracts = value;
  },
  [Mutation.STORE_CONTRACT]: (store, value) => {
    store.contract = value;
  }
};
