/* eslint-disable */

export const BRANCHNAME = process.env.VUE_APP_BRANCHNAME;
export const API_PATH = process.env.VUE_APP_API_PATH;
export const ENV = process.env.NODE_ENV || 'local';
export const VERSION = +process.env.VUE_APP_BUILDNUMBER;
export const OEWA_BUILD = +process.env.VUE_APP_OEWA_BUILD;
export const OEWA_PUBLIC_BUILD = +process.env.VUE_APP_OEWA_PUBLIC_BUILD;
export const DEV_STAGING_BUILD = +process.env.VUE_APP_DEV_STAGING_BUILD;
export const BRANDLIFT_API = process.env.VUE_APP_BRANDLIFT_API;
export const SOCIAL_ANALYTICS_API = process.env.VUE_APP_SOCIAL_ANALYTICS_API;

export * from './constants/index';
