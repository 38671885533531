export default {
  maintenance_notification: '',
  ratingsPool: [],
  tooltips: [],
  authorization: '',
  currentUser: null,
  selectedCountryCode: null,
  campaign_id: null,
  customers: [],
  redirectPath: '',
  availableApps: [],
  activeApp: '',
  navigation: [],
  maintenance: [],
  breadcrumbs: [],
  calculationType: '',
  pageFilters: [],
  pageFiltersLoading: false,
  pageLoading: false
};
