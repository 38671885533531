import { useAxios } from 'composables';
import { getApiRoute } from 'routeconfig';
import { useRoute } from 'vue-router';

const useCampaignTrackingChannelNames = () => {
  const route = useRoute();

  const {
    state: {
      loading: loadingTrackingChannelNames,
      error: loadingTrackingChannelNamesError,
      data: trackingChannelNames
    },
    fetcher: trackingChannelNamesFetcher
  } = useAxios({
    initialValue: []
  });

  const { fetcher: modifyingChannelNameFetcher } = useAxios();

  const fetchTrackingChannelNames = campaignId => {
    const apiRoute = getApiRoute({
      name: 'api.campaigns.channels.name.management',
      params: {
        id: campaignId
      }
    });

    return trackingChannelNamesFetcher({
      url: apiRoute,
      responseAdapter: ({ channels }) => channels
    });
  };

  const modifyChannelNameMapFn = ({ channelName, channelId, method }) => {
    const apiRoute = getApiRoute({
      name: 'api.campaigns.channels.name.management.custom',
      params: {
        id: route.params.id,
        channel_id: channelId
      }
    });

    return modifyingChannelNameFetcher({
      url: apiRoute,
      method,
      requestBody: {
        name: channelName
      }
    });
  };

  return {
    fetchTrackingChannelNames,
    loadingTrackingChannelNames,
    loadingTrackingChannelNamesError,
    trackingChannelNames,
    modifyChannelNameMapFn
  };
};

export default useCampaignTrackingChannelNames;
