import { Message } from 'reppublika_components';
import { ref, computed, watch } from 'vue';
import { useAxios, useDebouncedSearch } from 'composables';
import { getApiRoute } from 'routeconfig';

const DEFAULT_SELECTED_TRACKING_IDENTIFIERS = [];

const useOewaTrackingIdentifiers = (selectedRankingItem, selectedDeviceType) => {
  const {
    state: { data: trackingIdentifiers, loading: loadingTrackingIdentifiers },
    fetcher
  } = useAxios({
    initialValue: []
  });
  const {
    debouncedSearchString: trackingIdentifierSearchString,
    resetSearchString: resetTrackingIdentifierSearchString,
    handleSearch: handleTrackingIdentifierSearchStringChange
  } = useDebouncedSearch();
  const selectedTrackingIdentifiers = ref(DEFAULT_SELECTED_TRACKING_IDENTIFIERS);

  const fetchTrackingIdentifiersAPIRoute = computed(() => {
    if (!selectedRankingItem.value) {
      return getApiRoute({
        name: 'api.oewa.tracking-identifiers.list-for-all-items',
        query: {
          device_type: selectedDeviceType.value
        }
      });
    }

    const { id, type } = selectedRankingItem.value;

    return getApiRoute({
      name: `api.oewa.tracking-identifiers.by.${type}`,
      params: { id },
      query: {
        device_type: selectedDeviceType.value
      }
    });
  });

  const trackingIdentifiersDropdownOptions = computed(() =>
    trackingIdentifiers.value.map(trackingIdentifier => ({
      id: trackingIdentifier
    }))
  );

  const isTrackingIdentifiersDropdownDisabled = computed(
    () => loadingTrackingIdentifiers.value || !selectedDeviceType.value
  );

  const isTrackingIdentifiersEmpty = computed(() => !trackingIdentifiersDropdownOptions.value.length);

  const trackingIdentifiersDropdownPlaceholder = computed(() => {
    if (isTrackingIdentifiersDropdownDisabled.value || !isTrackingIdentifiersEmpty.value) {
      return '';
    }

    return 'Für die aktuelle Auswahl sind keine Angebotskennungen verfügbar.';
  });

  const trackingIdentifiersMatchingSearchString = computed(() =>
    trackingIdentifiersDropdownOptions.value.filter(({ id }) =>
      id.includes(trackingIdentifierSearchString.value)
    )
  );

  const isSearchBeforeRenderOptionsDisabled = computed(() => !!selectedRankingItem.value);

  const filteredTrackingIdentifiersDropdownOptions = computed(() => {
    if (isSearchBeforeRenderOptionsDisabled.value) {
      if (!trackingIdentifierSearchString.value) {
        return trackingIdentifiersDropdownOptions.value;
      }

      return trackingIdentifiersMatchingSearchString.value;
    }

    if (!trackingIdentifierSearchString.value || trackingIdentifierSearchString.value.length < 2) {
      return [];
    }

    return trackingIdentifiersMatchingSearchString.value;
  });

  watch(selectedRankingItem, newSelectedRankingItem => {
    resetTrackingIdentifierSearchString();

    if (!!selectedDeviceType.value) {
      fetchTrackingIdentifiers();
    }

    // evaluates to true if the dropdown gets cleared
    if (!newSelectedRankingItem) {
      resetTrackingIdentifierSearchString();
    }
  });

  watch(selectedDeviceType, newSelectedDeviceType => {
    // after each submission, we reset the fields (incl. device type) to their initial value
    // this condition makes sure that the emptied device filter does _not_ trigger the function
    if (!!newSelectedDeviceType) {
      fetchTrackingIdentifiers();

      // reset the search query so that the previous tracking codes matching
      // the string do not show up after selecting a new device type
      resetTrackingIdentifierSearchString();
    }
  });

  const fetchTrackingIdentifiers = () => {
    resetSelectedTrackingIdentifiers();

    return fetcher({
      url: fetchTrackingIdentifiersAPIRoute.value
    }).catch(() => {
      Message.error('Something went wrong while loading tracking codes. Please try again later.');
    });
  };

  function resetSelectedTrackingIdentifiers() {
    selectedTrackingIdentifiers.value = DEFAULT_SELECTED_TRACKING_IDENTIFIERS;
  }

  return {
    selectedTrackingIdentifiers,
    trackingIdentifiersDropdownOptions,
    loadingTrackingIdentifiers,
    resetSelectedTrackingIdentifiers,
    handleTrackingIdentifierSearchStringChange,
    filteredTrackingIdentifiersDropdownOptions,
    isTrackingIdentifiersDropdownDisabled,
    trackingIdentifiersDropdownPlaceholder,
    isTrackingIdentifiersEmpty
  };
};

export default useOewaTrackingIdentifiers;
