import { APPS } from 'utils/constants.js';
import { exportRoutesWithAppMetaField } from 'utils/helpers';

export const brandliftRoutes = exportRoutesWithAppMetaField(APPS.BRANDLIFT, [
  {
    name: 'brandlift.overview',
    path: '/:country_code/brandlift/overview',
    component: () =>
      import(
        /* webpackChunkName: "brandlift" */ '../../views/pages/brandlift/BrandliftBenchmarksOverview.vue'
      ),
    meta: { auth: true }
  },
  {
    name: 'brandlift.categories',
    path: '/:country_code/brandlift/categories',
    component: () =>
      import(
        /* webpackChunkName: "brandlift" */ '../../views/pages/brandlift/BrandliftBenchmarksCategories.vue'
      ),
    meta: { auth: true }
  },
  {
    name: 'brandlift.columns',
    path: '/:country_code/brandlift/columns',
    component: () =>
      import(
        /* webpackChunkName: "brandlift" */ '../../views/pages/brandlift/BrandliftBenchmarksColumns.vue'
      ),
    meta: { auth: true }
  }
]);
