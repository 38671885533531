<template>
  <div v-if="breadcrumbs.length" class="c-breadcrumb">
    <template v-for="(breadcrumb, index) in breadcrumbs">
      <router-link
        v-if="breadcrumb.title"
        :key="breadcrumb.title"
        :to="breadcrumb.url"
        class="c-breadcrumb__item"
        :class="{ 'c-breadcrumb__item--active': isLast(index) }"
      >
        <span
          v-if="index === 0 && breadcrumbs.length > 1"
          class="icon c-breadcrumb__arrow icon-point-left"
        ></span>
        {{ breadcrumb.title }}
      </router-link>

      <span v-if="!isLast(index)" :key="`crumb-${breadcrumb.title}`" class="c-breadcrumb__seperator">/</span>
    </template>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { Mutation } from 'store/_global/types';

export default {
  computed: {
    ...mapState(['breadcrumbs'])
  },
  methods: {
    isLast(i) {
      return i + 1 === this.breadcrumbs.length;
    },
    ...mapMutations({
      setBreadcrumbs: Mutation.SET_BREADCRUMBS
    })
  }
};
</script>
