/* eslint-disable */
import { $ } from 'libs';

var activeClass = 'is-active';
var confirmModalSelector = '.js-confirm-modal';
var ajaxModalSelector = '.js-ajax-modal';
var confirmModalButton;
var ajaxModalTitle;
var ajaxModalContent;
var ajaxModalButton;
var ajaxModalLoader;
var ajaxModalMessage;
var defaultConfirmValues = {
  title: 'Confirmation',
  content: 'Are you sure?',
  url: '#'
};
var loading = false;

var open = function (modalSelector) {
  var modal = $(modalSelector);

  //modal.data('scroll', $(window).scrollTop());
  modal.addClass(activeClass);
  modal.on('click.inside', function (ev) {
    ev.stopPropagation();
  });
};

var close = function (modalSelector) {
  var modal = $(modalSelector);

  modal.off('click.inside');
  modal.removeClass(activeClass);
  $(window).scrollTop(modal.data('scroll'));
};

var openConfirm = function (title, content) {
  $('.js-confirm-modal-title').html(title ? title : defaultConfirmValues['title']);
  $('.js-confirm-modal-content').html(content ? content : defaultConfirmValues['content']);

  open(confirmModalSelector);
};

var closeConfirm = function () {
  confirmModalButton.off();
  close(confirmModalSelector);
};

var openAjaxModal = function (title, content, submit_url) {
  ajaxModalTitle.html(title ? title : defaultConfirmValues['title']);
  ajaxModalContent.html(content ? content : defaultConfirmValues['content']);
  ajaxModalButton.attr('data-url', submit_url ? submit_url : defaultConfirmValues['url']);
  open(ajaxModalSelector);
};

var closeAjaxModal = function () {
  confirmModalButton.off();
  close(ajaxModalSelector);
};

var init = function (modalSelector) {
  confirmModalButton = $('.js-confirm-modal-button');
  ajaxModalTitle = $('.js-ajax-modal-title');
  ajaxModalContent = $('.js-ajax-modal-content');
  ajaxModalButton = $('.js-ajax-modal-button');
  ajaxModalLoader = $('#ajax-modal-loader');
  ajaxModalMessage = $('#ajax-modal-message');
  $(document).on('click', '[data-open-modal]', function (event) {
    event.preventDefault();

    modalSelector = $(this).data('open-modal');
    open(modalSelector);
  });

  // For filters only, because the event bubbling is propagated
  $('.js-filter-panel-content').on('click', '[data-open-modal]', function (event) {
    event.preventDefault();

    modalSelector = $(this).data('open-modal');
    open(modalSelector);
  });

  $('.js-modal-window').on('click', '.js-close-modal', function (event) {
    event.preventDefault();

    modalSelector = '#' + $(this).closest('.js-modal-window').attr('id');
    close(modalSelector);
  });
};

var initConfirm = function () {
  $(document).on('click', 'a.js-confirm', function (event) {
    var title = $(this).data('confirm-title');
    var content = $(this).data('confirm-content');
    openConfirm(title, content);

    confirmModalButton.on('click', function (event) {
      event.preventDefault();
      closeConfirm();
    });

    $('.js-close-confirm-modal').on('click', function (event) {
      event.preventDefault();
      closeConfirm();
    });

    event.preventDefault();
  });
};

function initModalWithAjax() {
  $(document).on('click', 'a.js-ajax-modal-open', function (event) {
    var href = $(this).data('url');
    var title = $(this).data('confirm-title');
    var content = $(this).data('confirm-content');

    openAjaxModal(title, content, href, href);

    ajaxModalButton.on('click', function (event) {
      var url = $(this).data('url');
      if (loading == false) {
        loading = true;
        // show loader
        ajaxModalLoader.show();
        $.ajax({
          url: url,
          type: 'GET',
          dataType: 'json',
          success: function (data) {
            // show message
            // remove loader
            ajaxModalLoader.hide();
            ajaxModalMessage.text(data['message']);
            if (data['success'] != undefined) {
              setTimeout(function () {
                close('.js-ajax-modal');
                ajaxModalMessage.text('');
                $('#url').val('');
              }, 1500);
            }
            loading = false;
          },
          error: function () {
            // remove loader
            ajaxModalLoader.hide();
            // show error
            loading = false;
          }
        }).done(function () {
          ajaxModalLoader.hide();
          loading = false;
        });
      }
      event.preventDefault();
    });

    $('.js-close-ajax-modal').on('click', function (event) {
      event.preventDefault();
      closeAjaxModal();
    });
    event.preventDefault();
  });
}

export default {
  init: function (modalSelector) {
    init(modalSelector);
    initConfirm();
    initModalWithAjax();
  },
  open: function (modalSelector) {
    open(modalSelector);
  },
  close: function (modalSelector) {
    close(modalSelector);
  }
};
