import { Message } from 'reppublika_components';
import { getApiRoute } from 'routeconfig';
import { useAxios } from 'composables';

const useOewaChannelTimeframes = () => {
  const {
    state: { loading: loadingTimeframes },
    fetcher
  } = useAxios();

  function fetchTimeframes(id) {
    const fetchTimeframesAPIRoute = getApiRoute({
      name: 'api.oewa.channel.available-quarters',
      params: { id }
    });

    return fetcher({
      url: fetchTimeframesAPIRoute
    }).catch(() => {
      Message.error('Something went wrong while fetching timeframes. Please try again later.');
    });
  }

  return {
    fetchTimeframes,
    loadingTimeframes
  };
};

export default useOewaChannelTimeframes;
