import useAxios, { API_METHODS } from '../useAxios';
import { getApiRoute } from 'routeconfig';

const useTranslations = () => {
  const {
    state: { loading, data: translations },
    fetcher: findAllFetcher
  } = useAxios({
    initialValue: {}
  });
  const { fetcher: findFetcher } = useAxios();
  const { fetcher: createFetcher } = useAxios();
  const { fetcher: updateFetcher } = useAxios();
  const { fetcher: removeFetcher } = useAxios();

  const findAll = localeId =>
    findAllFetcher({
      url: getApiRoute({
        name: 'api.admin.i18n.module',
        params: {
          localeId
        }
      }),
      responseAdapter: data => data?.data || {}
    });

  const find = id =>
    findFetcher({
      url: getApiRoute({
        name: 'api.admin.i18n.keys.key',
        params: {
          id
        }
      })
    });

  const create = newTranslation =>
    createFetcher({
      url: getApiRoute('api.admin.i18n.keys'),
      method: API_METHODS.POST,
      requestBody: newTranslation
    });

  const update = (id, updatedTranslation) =>
    updateFetcher({
      url: getApiRoute({
        name: 'api.admin.i18n.keys.key',
        params: {
          id
        }
      }),
      method: API_METHODS.PUT,
      requestBody: updatedTranslation
    });

  const remove = id =>
    removeFetcher({
      url: getApiRoute({
        name: 'api.admin.i18n.keys.key',
        params: {
          id
        }
      }),
      method: API_METHODS.DELETE
    });

  return {
    loading,
    translations,
    findAll,
    find,
    create,
    update,
    remove
  };
};

export default useTranslations;
