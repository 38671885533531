import { APPS } from 'utils/constants.js';
import { exportRoutesWithAppMetaField } from 'utils/helpers.js';

export const publisherModuleRoutes = exportRoutesWithAppMetaField(APPS.PUBLISHER, [
  {
    path: '/:country_code/publishers',
    component: {
      render: () => <RouterView />
    },
    children: [
      {
        path: '',
        name: 'publishers',
        component: () =>
          import(/* webpackChunkName: "publisher-live" */ '../../views/pages/publishers/Publishers.vue'),
        meta: { auth: true, module: 'publisher', hasCountrySwitcher: true }
      },
      {
        path: ':id/details',
        name: 'publisher.details',
        component: () =>
          import(
            /* webpackChunkName: "publisher-live" */ '../../views/pages/publishers/PublisherDetails.vue'
          ),
        meta: { auth: true, checkRights: true, module: 'publisher' }
      }
    ]
  }
]);
