import useAxios, { API_METHODS } from 'composables/useAxios';
import { getApiRoute } from 'routeconfig';

const useOewaManageServices = () => {
  const {
    state: { loading: findAllLoading, error: findAllError, data: services },
    fetcher: findAllFetcher
  } = useAxios({
    initialValue: []
  });
  const {
    state: { loading: removing },
    fetcher: removeFetcher
  } = useAxios();
  const {
    state: { loading: findLoading, error: findError, data: service },
    fetcher: findFetcher
  } = useAxios();
  const {
    state: { loading: updating },
    fetcher: updateFetcher
  } = useAxios();
  const {
    state: { loading: creating },
    fetcher: createFetcher
  } = useAxios();

  const findAll = () =>
    findAllFetcher({
      url: getApiRoute('api.oewa.services')
    });

  const create = newService =>
    createFetcher({
      method: API_METHODS.POST,
      url: getApiRoute('api.oewa.service'),
      requestBody: newService
    });

  const remove = id =>
    removeFetcher({
      method: API_METHODS.DELETE,
      url: getApiRoute({
        name: 'api.oewa.service',
        params: {
          id
        }
      })
    });

  const find = id =>
    findFetcher({
      url: getApiRoute({
        name: 'api.oewa.service',
        params: {
          id
        }
      })
    });

  const update = ({ id, payload }) =>
    updateFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: 'api.oewa.service',
        params: {
          id
        }
      }),
      requestBody: payload
    });

  return {
    findAllLoading,
    findAllError,
    services,
    findAll,
    findLoading,
    findError,
    service,
    findFetcher,
    find,
    updating,
    update,
    removing,
    remove,
    creating,
    create
  };
};

export default useOewaManageServices;
