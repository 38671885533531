import { APPS } from 'utils/constants.js';
import { exportRoutesWithAppMetaField } from 'utils/helpers.js';
import { TRANSLATION_TAGS } from 'utils/translate';

export const campaignControlModuleRoutes = exportRoutesWithAppMetaField(APPS.CAMPAIGN_CONTROL, [
  {
    path: '/:country_code/campaigns',
    component: {
      render: () => <RouterView />
    },
    meta: {
      locale: 'en-US'
    },
    children: [
      {
        path: '',
        name: 'campaigns',
        component: () =>
          import(/* webpackChunkName: "campaign-control" */ '../../views/pages/campaigns/Campaigns.vue'),
        meta: {
          auth: true,
          module: 'campaign',
          hasCountrySwitcher: true,
          translation_tags: [TRANSLATION_TAGS.FILTERS]
        }
      },
      {
        path: 'create',
        name: 'campaigns.create',
        component: () =>
          import(
            /* webpackChunkName: "campaign-control" */ '../../views/pages/campaigns/CampaignCreateOrEdit.vue'
          ),
        meta: { auth: true, module: 'create_edit' }
      },
      {
        path: ':id/edit',
        name: 'campaigns.edit',
        component: () =>
          import(
            /* webpackChunkName: "campaign-control" */ '../../views/pages/campaigns/CampaignCreateOrEdit.vue'
          ),
        meta: { auth: true, checkRights: true, module: 'create_edit' }
      },
      {
        path: 'details/:id',
        component: {
          render: () => <RouterView />
        },
        children: [
          {
            path: '',
            name: 'campaigns.details',
            component: () =>
              import(
                /* webpackChunkName: "campaign-control" */ '../../views/pages/campaigns/CampaignDetails.vue'
              ),
            meta: {
              auth: true,
              checkRights: true,
              module: 'report',
              translation_tags: [TRANSLATION_TAGS.FILTERS]
            }
          },
          {
            path: 'ad-impressions',
            name: 'campaigns.details.ad-impressions',
            component: () =>
              import(
                /* webpackChunkName: "campaign-control" */ '../../views/pages/campaigns/CampaignDetailsAdImpressions.vue'
              ),
            meta: {
              auth: true,
              checkRights: true,
              module: 'report',
              translation_tags: [TRANSLATION_TAGS.FILTERS]
            }
          },
          {
            path: 'breakdown',
            name: 'campaigns.details.breakdown',
            component: () =>
              import(
                /* webpackChunkName: "campaign-control" */ '../../views/pages/campaigns/CampaignDetailsBreakdown.vue'
              ),
            meta: {
              auth: true,
              checkRights: true,
              module: 'report',
              translation_tags: [TRANSLATION_TAGS.FILTERS]
            }
          },
          {
            path: 'export',
            name: 'campaigns.details.export',
            component: () =>
              import(
                /* webpackChunkName: "brand-check-and-campaign-control" */ '../../views/shared/CampaignAndFlightDetailsExport.vue'
              ),
            meta: { auth: true, checkRights: true, module: 'report' }
          }
        ]
      },
      {
        path: ':id/trackingcodes',
        component: {
          render: () => <RouterView />
        },
        children: [
          {
            path: '',
            name: 'campaigns.trackingcodes',
            component: () =>
              import(
                /* webpackChunkName: "campaign-control" */ '../../views/shared/CampaignAndFlightTrackingCodes.vue'
              ),
            meta: { auth: true, checkRights: true, module: 'report' }
          },
          {
            path: 'upload',
            name: 'campaigns.trackingcodes.upload',
            component: () =>
              import(
                /* webpackChunkName: "campaign-control" */ '../../views/shared/CampaignAndFlightTrackingCodesUpload.vue'
              ),
            meta: { auth: true, checkRights: true, module: 'report' }
          }
        ]
      },
      {
        path: ':id/creatives',
        component: {
          render: () => <RouterView />
        },
        children: [
          {
            path: '',
            name: 'campaigns.creatives',
            component: () =>
              import(
                /* webpackChunkName: "campaign-control" */ '../../views/shared/CampaignAndFlightCreatives.vue'
              ),
            meta: { auth: true, checkRights: true, module: 'report' }
          },
          {
            path: 'new',
            name: 'campaigns.creatives.new',
            component: () =>
              import(
                /* webpackChunkName: "campaign-control" */ '../../views/shared/CampaignAndFlightCreativeNewAndEdit.vue'
              ),
            meta: { auth: true, checkRights: true, module: 'report' }
          },
          {
            path: ':sujetId/edit',
            name: 'campaigns.creatives.edit',
            component: () =>
              import(
                /* webpackChunkName: "campaign-control" */ '../../views/shared/CampaignAndFlightCreativeNewAndEdit.vue'
              ),
            meta: { auth: true, checkRights: true, module: 'report' }
          }
        ]
      },
      {
        path: ':id/response',
        name: 'campaigns.response',
        component: () =>
          import(
            /* webpackChunkName: "brand-check-and-campaign-control" */ '../../views/shared/CampaignAndFlightResponse.vue'
          ),
        meta: { auth: true, checkRights: true, module: 'report' }
      },
      {
        path: ':id/admin-special-functions',
        name: 'campaigns.admin-special-functions',
        component: () =>
          import(
            /* webpackChunkName: "campaign-control" */ '../../views/pages/campaigns/CampaignAdminSpecialFunctions.vue'
          ),
        meta: { auth: true, checkRights: true, admin: true }
      },
      {
        path: ':id/kpi',
        component: {
          render: () => <RouterView />
        },
        children: [
          {
            path: '',
            name: 'campaigns.kpi',
            component: () =>
              import(
                /* webpackChunkName: "campaign-control" */ '../../views/pages/campaigns/CampaignKpi.vue'
              ),
            meta: { auth: true, checkRights: true, module: 'kpi' }
          },
          {
            path: 'breakdown/:widget_id/survey/:survey_id',
            name: 'campaigns.kpi.widget.breakdown',
            component: () =>
              import(
                /* webpackChunkName: "campaign-control" */ '../../views/pages/campaigns/CampaignKpiBreakdown.vue'
              ),
            meta: { auth: true, checkRights: true, module: 'kpi' }
          }
        ]
      },
      {
        path: ':id/tracking-channel-groups',
        component: {
          render: () => <RouterView />
        },
        children: [
          {
            path: '',
            name: 'campaigns.tracking-channel-groups',
            component: () =>
              import(
                /* webpackChunkName: "campaign-control" */ '../../views/pages/campaigns/CampaignTrackingChannelGroups.vue'
              ),
            meta: { auth: true, module: 'report' }
          },
          {
            path: 'create',
            name: 'campaigns.tracking-channel-groups.create',
            component: () =>
              import(
                /* webpackChunkName: "campaign-control" */ '../../views/pages/campaigns/CampaignTrackingChannelGroupsCreate.vue'
              ),
            meta: { auth: true, module: 'create_edit' }
          },
          {
            path: 'edit/:groupId',
            name: 'campaigns.tracking-channel-groups.edit',
            component: () =>
              import(
                /* webpackChunkName: "campaign-control" */ '../../views/pages/campaigns/CampaignTrackingChannelGroupsEdit.vue'
              ),
            meta: { auth: true, module: 'create_edit' }
          }
        ]
      },
      {
        path: ':id/channel-name-mapping',
        component: {
          render: () => <RouterView />
        },
        children: [
          {
            path: '',
            name: 'campaigns.channel-name-mapping',
            component: () =>
              import(
                /* webpackChunkName: "campaign-control" */ '../../views/pages/campaigns/CampaignTrackingChannelNames.vue'
              ),
            meta: { auth: true, module: 'report' }
          }
        ]
      }
    ]
  }
]);
