import { i18n } from 'plugins/i18n';

export const TRANSLATION_TAGS = {
  GENERIC: 'generic',
  OWA_RATINGS: 'owa_ratings',
  FILTERS: 'filters',
  ATTRIBUTES: 'attributes',
  DEMOGRAPHICS: 'demographics',
  REACH_CHART: 'reach-chart',
  RATINGS: 'ratings'
};

export const translate = (key, count, tag, named = {}) => {
  const tagExists = Object.values(TRANSLATION_TAGS).includes(tag);

  if (!tagExists) {
    throw new Error(`Unrecognisable translation tag: ${tag}`);
  }

  const genericKey = `${TRANSLATION_TAGS.GENERIC}>${key}`;

  // makes sure if the passed key exists in the generic namespace
  if (i18n.global.te(genericKey)) {
    return i18n.global.tc(genericKey, count, named);
  }

  return i18n.global.tc(`${tag}>${key}`, count, named);
};

export const translateOewaRatings = (key, count = 1) => translate(key, count, TRANSLATION_TAGS.OWA_RATINGS);

export const translateFilters = (key, count = 1) => translate(key, count, TRANSLATION_TAGS.FILTERS);

export const translateAttributes = (key, count = 1) => translate(key, count, TRANSLATION_TAGS.ATTRIBUTES);

export const translateDemographics = (key, count = 1) => translate(key, count, TRANSLATION_TAGS.DEMOGRAPHICS);

export const translateReachChart = (key, count = 1) => translate(key, count, TRANSLATION_TAGS.REACH_CHART);

export const translateRatings = ({ key, count = 1, named }) =>
  translate(key, count, TRANSLATION_TAGS.RATINGS, named);
