import { useAxios } from 'composables';
import { getApiRoute } from 'routeconfig';
import { getRatingsQueryParams } from 'views/ratings/helpers';
import { round } from 'helper';
import { TRAFFIC_MOVEMENT_TYPES } from 'views/ratings/RatingsConstants';

export const useTrafficMovements = () => {
  const {
    state: { loading: trafficMovementsLoading, error: trafficMovementsError, data: trafficMovements },
    fetcher
  } = useAxios({
    initialValue: []
  });

  const bulkFetchTrafficMovements = ({ services, limit = 50 }) => {
    if (!Array.isArray(services)) {
      throw new Error('services must be of type array');
    }

    const promises = services.map(({ id, type }) =>
      fetchTrafficMovements({
        id,
        type,
        limit
      })
    );

    // produces a lookup object keyed by services
    return Promise.all(promises).then(trafficMovementsRes =>
      Object.fromEntries(
        trafficMovementsRes.map((trafficMovement, idx) => [services[idx].key, trafficMovement])
      )
    );
  };

  const fetchTrafficMovements = ({ id, type, limit = 50 } = {}) =>
    fetcher({
      url: getApiRoute({
        name: 'api.ratings.details.traffic-movements',
        params: {
          id,
          type
        },
        query: getRatingsQueryParams({
          extraQuery: {
            limit
          }
        })
      }),
      responseAdapter: trafficMovements => {
        const groupedMovements = groupMovements(trafficMovements);

        return {
          [TRAFFIC_MOVEMENT_TYPES.BEFORE]: groupedMovements[TRAFFIC_MOVEMENT_TYPES.BEFORE],
          [TRAFFIC_MOVEMENT_TYPES.AFTER]: groupedMovements[TRAFFIC_MOVEMENT_TYPES.AFTER]
        };
      }
    });

  const groupMovements = movements =>
    movements.reduce(
      (result, { id, type, name, before, after }) => {
        const movement = {
          id,
          type,
          name,
          key: `${id}_${type}`
        };

        return {
          ...result,
          [TRAFFIC_MOVEMENT_TYPES.BEFORE]: [
            ...result[TRAFFIC_MOVEMENT_TYPES.BEFORE],
            {
              ...movement,
              value: {
                raw: before,
                rounded: round({
                  number: before * 100,
                  precision: 2
                })
              }
            }
          ].sort((a, b) => b.value.raw - a.value.raw),
          [TRAFFIC_MOVEMENT_TYPES.AFTER]: [
            ...result[TRAFFIC_MOVEMENT_TYPES.AFTER],
            {
              ...movement,
              value: {
                raw: after,
                rounded: round({
                  number: after * 100,
                  precision: 2
                })
              }
            }
          ].sort((a, b) => b.value.raw - a.value.raw)
        };
      },
      {
        [TRAFFIC_MOVEMENT_TYPES.BEFORE]: [],
        [TRAFFIC_MOVEMENT_TYPES.AFTER]: []
      }
    );

  return {
    fetchTrafficMovements,
    bulkFetchTrafficMovements,
    trafficMovementsLoading,
    trafficMovementsError,
    trafficMovements
  };
};
