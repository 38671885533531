import { Mutation } from '../types';

export default {
  [Mutation.STORE_PUBLISHERS]: (store, value) => {
    store.publishers = value;
  },
  [Mutation.STORE_CURRENT_PUBLISHER]: (store, value) => {
    store.currentPublisher = value;
  },
  [Mutation.STORE_CURRENT_PUBLISHER_INFO]: (store, value) => {
    store.currentPublisher = { ...store.currentPublisher, ...value };
  },
  [Mutation.STORE_CURRENT_PUBLISHER_CHART]: (store, value) => {
    store.currentPublisher = { ...store.currentPublisher, ...value };
  },
  [Mutation.STORE_CURRENT_PUBLISHER_INSIGHTS]: (store, value) => {
    store.currentPublisher = { ...store.currentPublisher, ...value };
  }
};
