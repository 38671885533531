import axios from 'axios';
import { createI18n } from 'vue-i18n';
import { getApiRoute } from 'routeconfig';

export const i18n = createI18n();

export const loadTranslations = ({ locale, tags }) => {
  if (!locale || !Array.isArray(tags)) {
    return Promise.resolve();
  }

  if (locale !== i18n.global.locale) {
    i18n.global.locale = locale;
  }

  const promises = tags.map(tag => {
    const apiRoute = getApiRoute({
      name: 'api.admin.i18n.module.tag',
      params: {
        tag,
        locale
      }
    });

    return axios.get(apiRoute).then(({ data: { data } }) => {
      const formattedMessages = data.reduce(
        (result, { msgid, msgstr0: singular, msgstr1: plural }) => ({
          ...result,
          // https://kazupon.github.io/vue-i18n/guide/pluralization.html
          // example: owa_ratings>dashboard>internal-preview>title
          [`${tag}>${msgid}`]: !!plural ? `${singular} | ${plural}` : singular
        }),
        {}
      );

      return formattedMessages;
    });
  });

  return Promise.allSettled(promises).then(results => {
    const localeMessages = results.reduce((result, { status, value }) => {
      if (status === 'rejected') {
        return result;
      }

      return {
        ...result,
        ...value
      };
    }, {});

    i18n.global.mergeLocaleMessage(locale, localeMessages);
  });
};
