import { useAxios } from 'composables';
import { getApiRoute } from 'routes/config';

export const useCustomers = () => {
  const {
    state: { loading: customersLoading, error: customersError, data: customers },
    fetcher
  } = useAxios({
    initialValue: []
  });

  const fetchCustomers = () =>
    fetcher({
      url: getApiRoute('api.customers'),
      responseAdapter: ({ customers }) => customers || []
    });

  return {
    customersLoading,
    customersError,
    customers,
    fetchCustomers
  };
};
