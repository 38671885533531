import useAxios, { API_METHODS } from 'composables/useAxios';
import { getApiRoute } from 'routeconfig';

const useManageTargets = () => {
  const {
    state: { loading: fetchingAssignedTargets, data: assignedTargets },
    fetcher: assignedTargetsFetcher
  } = useAxios({
    initialValue: []
  });

  const {
    state: { loading: fetchingUnassignedTargets, data: unassignedTargets },
    fetcher: unassignedTargetsFetcher
  } = useAxios({
    initialValue: []
  });

  const {
    state: { loading: assigningTarget },
    fetcher: assignTargetFetcher
  } = useAxios();

  const {
    state: { loading: assigningTargets },
    fetcher: assignTargetsFetcher
  } = useAxios();

  const {
    state: { loading: unassigningTargets },
    fetcher: unassignTargetsFetcher
  } = useAxios();

  const {
    state: { loading: hidingTarget },
    fetcher: hideTargetFetcher
  } = useAxios();

  const {
    state: { loading: fetchingHiddenUnassignedTargets, data: hiddenUnassignedTargets },
    fetcher: hiddenUnassignedTargetsFetcher
  } = useAxios({
    initialValue: []
  });

  const {
    state: { loading: unhidingTarget },
    fetcher: unhideTargetFetcher
  } = useAxios();

  const fetchAssignedTargets = ({ id }) =>
    assignedTargetsFetcher({
      url: getApiRoute({
        name: 'api.ratings.details.targets',
        params: { id: id }
      }),
      responseAdapter: ({ service }) => service?.targets ?? []
    });

  const fetchUnassignedTargets = ({ searchQuery = {} }) =>
    unassignedTargetsFetcher({
      url: getApiRoute({
        name: 'api.admin.ratings.targets.unassigned',
        ...(Object.keys(searchQuery).length && {
          query: {
            search: searchQuery
          }
        })
      }),
      responseAdapter: ({ targets }) => targets ?? []
    });

  const assignTarget = ({ id, serviceId }) =>
    assignTargetFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: 'api.admin.ratings.target.assign',
        params: {
          target_id: id
        }
      }),
      requestBody: {
        service_id: serviceId
      }
    });

  const assignTargets = ({ serviceId, targetIds }) =>
    assignTargetsFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: 'api.admin.ratings.targets.assign'
      }),
      requestBody: {
        service_id: serviceId,
        target_ids: targetIds
      }
    });

  const unassignTargets = ({ serviceId, targetIds }) =>
    unassignTargetsFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: 'api.admin.ratings.targets.unassign'
      }),
      requestBody: {
        service_id: serviceId,
        target_ids: targetIds
      }
    });

  const hideTarget = ({ id, action, note }) =>
    hideTargetFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: 'api.admin.ratings.target.hide',
        params: {
          target_id: id
        }
      }),
      requestBody: {
        note,
        action
      }
    });

  const fetchHiddenUnassignedTargets = ({ type }) =>
    hiddenUnassignedTargetsFetcher({
      url: getApiRoute({
        name: 'api.admin.ratings.target.hidden',
        query: {
          action: type
        }
      }),
      responseAdapter: ({ targets }) => targets ?? []
    });

  const unhideTarget = ({ id, actionType }) =>
    unhideTargetFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: 'api.admin.ratings.targets.unhide',
        params: {
          target_id: id
        },
        query: {
          action: actionType
        }
      })
    });

  return {
    fetchAssignedTargets,
    fetchingAssignedTargets,
    assignedTargets,
    fetchUnassignedTargets,
    fetchingUnassignedTargets,
    unassignedTargets,
    assignTarget,
    assigningTarget,
    assignTargets,
    assigningTargets,
    unassignTargets,
    unassigningTargets,
    hideTarget,
    hidingTarget,
    fetchHiddenUnassignedTargets,
    fetchingHiddenUnassignedTargets,
    hiddenUnassignedTargets,
    unhideTarget,
    unhidingTarget
  };
};

export default useManageTargets;
