import { Mutation } from '../types';

export default {
  [Mutation.STORE_USERDATA]: (store, value) => {
    store.userData = value;
  },
  [Mutation.STORE_CONNECTABLE_CUSTOMERS]: (store, value) => {
    store.connectableCustomers = value;
  }
};
