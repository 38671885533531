import { useAxios } from 'composables';
import { getApiRoute } from 'routeconfig';
import { getRatingsQueryParams } from 'views/ratings/helpers';

export const useChannelAssignedUrls = () => {
  const {
    state: { loading: loadingAssignedUrls, error: assignedUrlsError, data: assignedUrls },
    fetcher
  } = useAxios({
    initialValue: []
  });

  const fetchAssignedUrls = channelId =>
    fetcher({
      url: getApiRoute({
        name: 'api.ratings.details.channel-urls',
        params: {
          id: channelId
        },
        query: getRatingsQueryParams()
      }),
      responseAdapter: ({ assigned_urls }) => (assigned_urls || []).filter(({ string: url }) => !!url)
    });

  return {
    fetchAssignedUrls,
    loadingAssignedUrls,
    assignedUrlsError,
    assignedUrls
  };
};
