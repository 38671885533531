import { Message } from 'reppublika_components';
import { computed, ref } from 'vue';
import { useAxios } from 'composables';
import { getApiRoute } from 'routeconfig';

const DEFAULT_SELECTED_RANKING_ITEM_KEY = '';

const SERVICE_TYPE_ABBREVATIONS_IN_GERMAN = {
  service: 'EA',
  mediahouse: 'DA',
  adnetwork: 'VG'
};

const useOewaRankingItems = () => {
  const {
    state: { data, loading: loadingRankingItems },
    fetcher
  } = useAxios({
    initialValue: []
  });
  const selectedRankingItemKey = ref(DEFAULT_SELECTED_RANKING_ITEM_KEY);

  const rankingItems = computed(() =>
    data.value.map(({ id, name, type }) => ({
      id,
      type,
      name: `${name} (${SERVICE_TYPE_ABBREVATIONS_IN_GERMAN[type]})`,
      key: `${id}_${type}`
    }))
  );

  const rankingItemsLookup = computed(() =>
    Object.fromEntries(rankingItems.value.map(({ key, id, type }) => [key, { id, type }]))
  );

  const selectedRankingItem = computed(() => rankingItemsLookup.value[selectedRankingItemKey.value]);

  function fetchRankingItems() {
    const fetchRakingItemsAPIRoute = getApiRoute({
      name: 'api.oewa.ranking.items',
      query: {
        'exclude[]': 'channels'
      }
    });

    return fetcher({
      url: fetchRakingItemsAPIRoute
    }).catch(() => {
      Message.error('Something went wrong while loading ranking items. Please try again later.');
    });
  }

  function resetSelectedRankingItemKey() {
    selectedRankingItemKey.value = DEFAULT_SELECTED_RANKING_ITEM_KEY;
  }

  return {
    rankingItems,
    loadingRankingItems,
    rankingItemsLookup,
    selectedRankingItem,
    selectedRankingItemKey,
    resetSelectedRankingItemKey,
    fetchRankingItems
  };
};

export default useOewaRankingItems;
