export const Action = {
  FETCH_CONNECTED_SEARCH_TERMS: 'FETCH_CONNECTED_SEARCH_TERMS',
  FETCH_SEARCH_ENGINE_DISTRIBUTION: 'FETCH_SEARCH_ENGINE_DISTRIBUTION',
  FETCH_PAGE_FILTERS: 'FETCH_PAGE_FILTERS'
};
export const Mutation = {
  STORE_CONNECTED_SEARCH_TERMS: 'STORE_CONNECTED_SEARCH_TERMS',
  STORE_SEARCH_ENGINE_DISTRIBUTION: 'STORE_SEARCH_ENGINE_DISTRIBUTION',
  STORE_SELECTED_KEYWORDS: 'STORE_SELECTED_KEYWORDS',
  SET_DEFAULT_FILTER_DATE: 'SET_DEFAULT_FILTER_DATE'
};
