import moment from 'moment';
import { INTERVAL_TYPES } from 'utils/constants';
import { translateFilters } from 'utils/translate';

const formatDate = ({ date, intervalType, owa }) => {
  if (intervalType === INTERVAL_TYPES.MONTH) {
    if (!owa) return moment(date).format('MMMM YYYY');

    const month = moment(date).format('MMMM').toLowerCase();
    const year = moment(date).get('year');

    return `${translateFilters(`months>${month}`)} ${year}`;
  }

  if (intervalType === INTERVAL_TYPES.QUARTER) {
    return moment(date).format('YYYY - [Q]Q');
  }

  if (intervalType === INTERVAL_TYPES.HALF_YEAR) {
    const wrappedDate = moment(date);

    // https://stackoverflow.com/questions/61153029/half-year-date-format
    return `${wrappedDate.get('year')} - H${wrappedDate.get('quarter') < 3 ? 1 : 2}`;
  }

  if (intervalType === INTERVAL_TYPES.YEAR) {
    return `${moment(date).get('year')}`;
  }

  throw new Error(`Unrecognisable interval type: ${intervalType}`);
};

export const formatDataFrame = ({ date, intervalType, calculationType, owa = false }) => {
  const formattedDate = formatDate({
    date,
    intervalType,
    owa
  });

  if (!calculationType) {
    return formattedDate;
  }

  return `${formattedDate} - ${calculationType}`;
};
