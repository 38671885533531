import { APPS } from 'utils/constants.js';
import { exportRoutesWithAppMetaField } from 'utils/helpers.js';

export const streamingRatingsModuleRoutes = exportRoutesWithAppMetaField(APPS.STREAMING, [
  {
    path: '/:country_code/ratings-streaming-services',
    name: 'streaming',
    component: () =>
      import(
        /* webpackChunkName: "ratings-streaming-services" */ '../../views/pages/streaming/StreamingRatings.vue'
      ),
    meta: { auth: true, module: 'streaming', hasCountrySwitcher: true }
  }
]);
