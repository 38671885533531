import { useRoute } from 'vue-router';
import { useAxios } from 'composables';
import { getApiRoute } from 'routeconfig';
import { API_METHODS } from 'composables/useAxios';

const useCampaign = () => {
  const route = useRoute();
  const {
    state: { loading: fetchingCampaign, error: fetchCampaignError, data: campaign },
    fetcher
  } = useAxios();

  const {
    state: {
      loading: loadingCampaignsCustomers,
      error: loadingCampaignsCustomersError,
      data: campaignsCustomers
    },
    fetcher: campaignsFetcher
  } = useAxios();

  const {
    state: {
      loading: loadingAdImpressionsChart,
      error: loadingAdImpressionsChartError,
      data: adImpressionsChartData
    },
    fetcher: adImpressionsChartFetcher
  } = useAxios();

  const {
    state: {
      loading: loadingAdImpressionsTable,
      error: loadingAdImpressionsTableError,
      data: adImpressionsTableData
    },
    fetcher: adImpressionsTableFetcher
  } = useAxios();

  const {
    state: { loading: loadingCampaignCost, data: campaignCost },
    fetcher: campaignCostFetcher
  } = useAxios();

  const {
    state: { loading: loadingCampaignCreateOrEditData, data: campaignCreateOrEditData },
    fetcher: campaignCreateOrEditDataFetcher
  } = useAxios();

  const {
    state: { loading: savingCampaign },
    fetcher: saveCampaignFetcher
  } = useAxios();

  const {
    state: { loading: loadingAdminSpecialFunctionsData, data: adminSpecialFunctionsData },
    fetcher: adminSpecialFunctionsDataFetcher
  } = useAxios();

  const {
    state: { loading: uploadingTrackedUsers, data: trackedUsersBlob },
    fetcher: uploadTrackedUsersFetcher
  } = useAxios();

  const {
    state: { loading: loadingCampaignsResponse, data: campaignsResponse },
    fetcher: campaignsResponseFetcher
  } = useAxios();

  const {
    state: { loading: loadingCampaignTargetAttributes, data: campaignTargetAttributes },
    fetcher: campaignTargetAttributesFetcher
  } = useAxios({
    initialValue: []
  });

  const {
    state: { loading: loadingCampaignTargetAccuracy, data: campaignTargetAccuracy },
    fetcher: campaignTargetAccuracyFetcher
  } = useAxios();

  const {
    state: { loading: loadingCampaignAttributes, data: campaignAttributes },
    fetcher: campaignAttributesFetcher
  } = useAxios();

  const {
    state: { loading: loadingCampaignReachChart, data: campaignReachChartData },
    fetcher: campaignReachChartFetcher
  } = useAxios();

  const fetchCampaign = campaignId => {
    const apiRoute = getApiRoute({
      name: 'api.campaigns.details.data',
      params: {
        id: campaignId
      },
      query: {
        ...route.query
      }
    });

    return fetcher({
      url: apiRoute,
      responseAdapter: data => data.campaign
    });
  };

  const fetchCampaignsCustomers = () => {
    const apiRoute = getApiRoute({
      name: 'api.campaigns.list',
      query: {
        ...route.query
      }
    });

    return campaignsFetcher({
      url: apiRoute,
      responseAdapter: data => data.customers
    });
  };

  const fetchAdImpressionsChart = campaignId => {
    const apiRoute = getApiRoute({
      name: 'api.campaigns.details.ad-impressions.chart',
      params: {
        id: campaignId
      },
      query: {
        ...route.query
      }
    });

    return adImpressionsChartFetcher({
      url: apiRoute,
      responseAdapter: ({ chart_data }) => chart_data
    });
  };

  const fetchAdImpressionsTable = campaignId => {
    const apiRoute = getApiRoute({
      name: 'api.campaigns.details.ad-impressions.table',
      params: {
        id: campaignId
      },
      query: {
        ...route.query
      }
    });

    return adImpressionsTableFetcher({
      url: apiRoute,
      responseAdapter: ({ table_data }) => table_data
    });
  };

  const fetchCampaignCost = ({ billingUrl, items, customer, cancelToken }) => {
    return campaignCostFetcher({
      url: billingUrl,
      method: API_METHODS.POST,
      cancelToken,
      requestBody: {
        items,
        customer
      }
    });
  };

  const fetchCampaignCreateOrEditData = ({ adeffx = false } = {}) => {
    const editMode = !!route.params.id || route.params.id === 0;
    const routeName = 'api.campaigns.create-or-edit.data';
    let query = {};

    if (adeffx) {
      query = {
        adeffx: 1
      };
    }

    if (editMode) {
      query = {
        ...query,
        id: route.params.id
      };
    }

    const apiRoute = getApiRoute({
      name: routeName,
      query
    });

    return campaignCreateOrEditDataFetcher({
      url: apiRoute,
      responseAdapter: data => data
    });
  };

  const saveCampaign = ({ formData }) => {
    const editMode = !!route.params.id || route.params.id === 0;
    const routeName = editMode ? 'api.campaigns.update' : 'api.campaigns.store';

    const apiRoute = getApiRoute({
      name: routeName,
      params: route.params,
      query: route.query
    });

    return saveCampaignFetcher({
      url: apiRoute,
      method: API_METHODS.POST,
      requestBody: formData
    });
  };

  const fetchAdminSpecialFunctionsData = () => {
    const url = getApiRoute({
      name: 'api.campaigns.admin-special-functions',
      params: route.params,
      query: route.query
    });

    return adminSpecialFunctionsDataFetcher({
      url
    });
  };

  const uploadTrackedUsers = ({ formData }) => {
    const url = getApiRoute({
      name: 'api.campaigns.details.tracked-users-export',
      params: route.params,
      query: route.query
    });

    return uploadTrackedUsersFetcher({
      url,
      method: API_METHODS.POST,
      requestBody: formData,
      requestHeaders: {
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'blob'
    });
  };

  const fetchCampaignsResponse = ({ flightId = null } = {}) => {
    const query = flightId ? { ...route.query, flight_id: flightId } : { ...route.query };

    const url = getApiRoute({
      name: 'api.campaigns.response',
      params: route.params,
      query
    });

    return campaignsResponseFetcher({
      url
    });
  };

  const fetchCampaignTargetAccuracy = ({ campaignId, flightId = null } = {}) => {
    const url = getApiRoute({
      name: 'api.campaigns.details.campaign-target-accuracy',
      params: {
        id: campaignId
      },
      query: {
        ...route.query,
        ...(flightId && { flight_id: flightId })
      }
    });

    return campaignTargetAccuracyFetcher({
      url
    });
  };

  const fetchCampaignAttributes = ({ url }) =>
    campaignAttributesFetcher({
      url,
      responseAdapter: data => data.attributes
    });

  const fetchCampaignReachChart = ({ url }) =>
    campaignReachChartFetcher({
      url,
      responseAdapter: data => data.chart_data
    });

  const fetchCampaignTargetAttributes = ({ campaignId }) => {
    const url = getApiRoute({
      name: 'api.campaigns.target-attributes',
      params: { id: campaignId }
    });

    return campaignTargetAttributesFetcher({
      url,
      responseAdapter: data => data.attributes
    });
  };

  return {
    fetchingCampaign,
    fetchCampaignError,
    campaign,
    fetchCampaign,
    fetchCampaignsCustomers,
    loadingCampaignsCustomers,
    loadingCampaignsCustomersError,
    campaignsCustomers,
    fetchAdImpressionsChart,
    loadingAdImpressionsChart,
    loadingAdImpressionsChartError,
    adImpressionsChartData,
    fetchAdImpressionsTable,
    loadingAdImpressionsTable,
    loadingAdImpressionsTableError,
    adImpressionsTableData,
    fetchCampaignCost,
    loadingCampaignCost,
    campaignCost,
    fetchCampaignCreateOrEditData,
    loadingCampaignCreateOrEditData,
    campaignCreateOrEditData,
    saveCampaign,
    savingCampaign,
    fetchAdminSpecialFunctionsData,
    loadingAdminSpecialFunctionsData,
    adminSpecialFunctionsData,
    uploadTrackedUsers,
    uploadingTrackedUsers,
    trackedUsersBlob,
    fetchCampaignsResponse,
    loadingCampaignsResponse,
    campaignsResponse,
    fetchCampaignTargetAccuracy,
    loadingCampaignTargetAccuracy,
    campaignTargetAccuracy,
    fetchCampaignAttributes,
    loadingCampaignAttributes,
    campaignAttributes,
    fetchCampaignReachChart,
    loadingCampaignReachChart,
    campaignReachChartData,
    fetchCampaignTargetAttributes,
    loadingCampaignTargetAttributes,
    campaignTargetAttributes
  };
};

export default useCampaign;
