import { getApiRoute } from 'routeconfig';
import { useAxios } from 'composables';
import { API_METHODS } from 'composables/useAxios';

export const useManageModules = () => {
  const {
    state: { loading: findAllLoading, error: findAllError, data: modules },
    fetcher: findAllFetcher
  } = useAxios({ initialValue: [] });
  const {
    state: { loading: findLoading, error: findError, data: module },
    fetcher: findFetcher
  } = useAxios();
  const {
    state: { loading: updating },
    fetcher: updateFetcher
  } = useAxios();

  const {
    state: { loading: saving },
    fetcher: saveFetcher
  } = useAxios();

  const {
    state: { loading: deleting },
    fetcher: deleteFetcher
  } = useAxios();

  const findAll = () =>
    findAllFetcher({
      url: getApiRoute('api.admin.modules'),
      responseAdapter: data => data?.modules || []
    });

  const find = id =>
    findFetcher({
      url: getApiRoute({
        name: 'api.admin.module.details',
        params: {
          id
        }
      }),
      responseAdapter: data => data?.module || {}
    });

  const save = payload => {
    return saveFetcher({
      method: API_METHODS.POST,
      url: getApiRoute('api.admin.modules.create'),
      requestBody: payload
    });
  };

  const update = ({ id, payload }) =>
    updateFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: 'api.admin.module.update',
        params: {
          id
        }
      }),
      requestBody: payload
    });

  const remove = id =>
    deleteFetcher({
      method: API_METHODS.DELETE,
      url: getApiRoute({
        name: 'api.admin.modules.delete',
        params: {
          id
        }
      })
    });

  return {
    findAll,
    findAllLoading,
    findAllError,
    modules,
    find,
    findLoading,
    findError,
    module,
    update,
    updating,
    save,
    saving,
    remove,
    deleting
  };
};
