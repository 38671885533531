import { useAxios } from 'composables';
import { getApiRoute } from 'routeconfig';
import { API_METHODS } from 'composables/useAxios';

const useSujets = () => {
  const { fetcher: storeSujetsFetcher } = useAxios();

  const storeSujets = ({ campaignId, sujets }) => {
    const apiRoute = getApiRoute({
      name: 'api.campaigns.survey.sujets.permanent-store',
      params: {
        id: campaignId
      }
    });

    return storeSujetsFetcher({
      url: apiRoute,
      method: API_METHODS.POST,
      requestBody: {
        sujets
      }
    });
  };

  return {
    storeSujets
  };
};

export default useSujets;
