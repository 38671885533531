import useAxios, { API_METHODS } from '../useAxios';
import { getApiRoute } from 'routeconfig';

const useFlights = () => {
  const {
    state: { loading: fetchingFlights, error: fetchFlightsError, data: flights },
    fetcher: flightsFetcher
  } = useAxios({
    initialValue: []
  });

  const { fetcher: approveFlightFetcher } = useAxios();

  const { fetcher: declineFlightFetcher } = useAxios();

  const fetchFlights = async () => {
    const apiRoute = getApiRoute({
      name: 'api.campaigns.flights.approve-status'
    });

    return flightsFetcher({
      url: apiRoute,
      responseAdapter: ({ flights }) => flights
    });
  };

  const approveFlight = async ({ campaignId, flightId }) => {
    const apiRoute = getApiRoute({
      name: 'api.campaigns.flights.approve',
      params: {
        id: campaignId,
        flight_id: flightId
      }
    });

    return approveFlightFetcher({
      url: apiRoute,
      method: API_METHODS.POST
    });
  };

  const declineFlight = async ({ campaignId, flightId, reason }) => {
    const apiRoute = getApiRoute({
      name: 'api.campaigns.flights.decline',
      params: {
        id: campaignId,
        flight_id: flightId
      }
    });

    return declineFlightFetcher({
      url: apiRoute,
      method: API_METHODS.POST,
      requestBody: {
        reason
      }
    });
  };

  return {
    fetchFlights,
    fetchingFlights,
    fetchFlightsError,
    flights,
    approveFlight,
    declineFlight
  };
};

export default useFlights;
