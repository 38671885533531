export const oewaAdministrationRoutes = [
  {
    path: 'oewa-ratings',
    component: {
      render: () => <RouterView />
    },
    children: [
      {
        path: 'mediahouses',
        component: {
          render: () => <RouterView />
        },
        children: [
          {
            path: '',
            name: 'admin.oewa.mediahouses',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ 'views/pages/management/oewa/OewaMediaHousesManagement.vue'
              ),
            meta: {
              auth: true,
              modules: ['owa_admin']
            }
          },
          {
            path: 'create',
            name: 'admin.oewa.mediahouses.create',
            component: () =>
              import(/* webpackChunkName: "admin" */ 'views/pages/management/oewa/OewaMediaHouseCreate.vue'),
            meta: {
              auth: true,
              modules: ['owa_admin']
            }
          },
          {
            path: ':id/edit',
            name: 'admin.oewa.mediahouses.edit',
            component: () =>
              import(/* webpackChunkName: "admin" */ 'views/pages/management/oewa/OewaMediaHouseEdit.vue'),
            meta: {
              auth: true,
              modules: ['owa_admin']
            }
          }
        ]
      },
      {
        path: 'adnetworks',
        component: {
          render: () => <RouterView />
        },
        children: [
          {
            path: '',
            name: 'admin.oewa.adnetworks',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ 'views/pages/management/oewa/OewaAdNetworksManagement.vue'
              ),
            meta: {
              auth: true,
              modules: ['owa_admin']
            }
          },
          {
            path: 'create',
            name: 'admin.oewa.adnetworks.create',
            component: () =>
              import(/* webpackChunkName: "admin" */ 'views/pages/management/oewa/OewaAdNetworkCreate.vue'),
            meta: {
              auth: true,
              modules: ['owa_admin']
            }
          },
          {
            path: ':id/edit',
            name: 'admin.oewa.adnetworks.edit',
            component: () =>
              import(/* webpackChunkName: "admin" */ 'views/pages/management/oewa/OewaAdNetworkEdit.vue'),
            meta: {
              auth: true,
              modules: ['owa_admin']
            }
          }
        ]
      },
      {
        path: 'services',
        component: {
          render: () => <RouterView />
        },
        children: [
          {
            path: '',
            name: 'admin.oewa.services',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ 'views/pages/management/oewa/OewaServicesManagement.vue'
              ),
            meta: {
              auth: true,
              modules: ['owa_admin']
            }
          },
          {
            path: 'create',
            name: 'admin.oewa.services.create',
            component: () =>
              import(/* webpackChunkName: "admin" */ 'views/pages/management/oewa/OewaServiceCreate.vue'),
            meta: {
              auth: true,
              modules: ['owa_admin']
            }
          },
          {
            path: ':id/edit',
            name: 'admin.oewa.services.edit',
            component: () =>
              import(/* webpackChunkName: "admin" */ 'views/pages/management/oewa/OewaServiceEdit.vue'),
            meta: {
              auth: true,
              modules: ['owa_admin']
            }
          }
        ]
      },
      {
        path: 'tracking-identifiers',
        name: 'admin.oewa.tracking-identifiers',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ 'views/pages/management/oewa/OewaTrackingIdentifiersManagement.vue'
          ),
        meta: {
          auth: true,
          modules: ['owa_admin']
        }
      }
    ]
  }
];
