import useAxios from 'composables/useAxios';
import { getApiRoute } from 'routeconfig';

export const useCountries = () => {
  const {
    state: { loading: findAllLoading, error: findAllError, data: items },
    fetcher: findAllFetcher
  } = useAxios({
    initialValue: []
  });

  const findAll = () =>
    findAllFetcher({
      url: getApiRoute('api.admin.countries'),
      responseAdapter: ({ countries }) => countries || []
    });

  return {
    findAll,
    findAllLoading,
    findAllError,
    items
  };
};
