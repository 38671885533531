import { APPS } from 'utils/constants.js';
import { exportRoutesWithAppMetaField } from 'utils/helpers.js';

export const dmpFeedModuleRoutes = exportRoutesWithAppMetaField(APPS.DMP_FEED, [
  {
    path: '/:country_code/dmp-feed',
    name: 'dmp-feed',
    component: () => import(/* webpackChunkName: "dmp-feed" */ '../../views/pages/dmp-feed/DmpFeed.vue'),
    meta: { auth: true, module: 'dmpFeed', hasCountrySwitcher: true }
  }
]);
