<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import AuthLayout from 'views/layout/AuthLayout.vue';
import useAxios, { API_METHODS } from 'composables/useAxios';
import { getApiRoute } from 'routeconfig';
import { Mutation as GlobalMutation, Action as GlobalAction } from 'store/_global/types';
import { clearCache, handleAsyncError, getTokenFromCookies } from 'helper';
import { Spinner } from 'views';

const store = useStore();
const router = useRouter();

const { fetcher } = useAxios();

const logout = () =>
  fetcher({
    method: API_METHODS.POST,
    url: getApiRoute('api.logout')
  });

const cleanUp = ({ redirect = null }) => {
  store.commit(GlobalMutation.REMOVE_TOKEN);
  store.commit(GlobalMutation.SET_MAINTENANCE, []);
  store.commit(GlobalMutation.SET_SELECTED_COUNTRY_CODE, null);
  store.commit(GlobalMutation.SET_NAVIGATION, []);
  store.commit(GlobalMutation.SET_BREADCRUMBS, []);

  try {
    clearCache();
  } catch (error) {
    console.error('could not clear cache storage: ', error);
  }

  if (redirect) {
    location.href = redirect;
  } else {
    router.push({ name: 'login' });
  }
};

store.commit(GlobalMutation.UPDATE_TOKEN);

const token = getTokenFromCookies();

if (!token) {
  cleanUp();
}

try {
  await store.dispatch(GlobalAction.CHECK_TOKENS, { token });

  logout().then(({ redirect }) => {
    cleanUp({ redirect });
  });
} catch (error) {
  handleAsyncError(error, 'Something went wrong - an error occurred while signing out.');

  cleanUp();
}
</script>

<template>
  <AuthLayout>
    <Spinner />
  </AuthLayout>
</template>
