import { computed, ref } from 'vue';
import useAxios, { RESPONSE_TYPES } from '../useAxios';
import { SOCIAL_ANALYTICS_API } from 'constants';
import { saveFile } from 'utils/helpers';
import moment from 'moment';

const platforms = [{ key: 'oewa', name: 'Oewa' }];

const selectedFilters = ref({});
const loading = {};
const rawScrapings = {};
platforms.forEach(platform => {
  loading[platform.key] = ref(false);
  rawScrapings[platform.key] = ref([]);
});

const useSocialAnalyticsScraping = () => {
  function setContent(platform, scrapings) {
    rawScrapings[platform].value = scrapings.sort((a, b) => {
      return +b.timestamp - +a.timestamp;
    });
  }

  const { fetcher: loadFetcher } = useAxios({
    initialValue: []
  });
  const { fetcher: exportFetcher } = useAxios({
    initialValue: ''
  });

  async function loadScrapings(platform, queryParams = {}) {
    let query = '';
    const paramItems = Object.entries({ ...selectedFilters.value, ...queryParams });

    if (paramItems.length) {
      query = `?${paramItems.map(([key, value]) => `${key}=${value}`).join('&')}`;
    }

    let data;
    try {
      loading[platform].value = true;
      data = await loadFetcher({
        method: 'GET',
        url: `${SOCIAL_ANALYTICS_API}/${platform}/scrapings${query}`
      });
      loading[platform].value = false;
    } catch (err) {
      console.error('failed to fetch social analytics scrapings');
    }
    if (!data) return;
    setContent(platform, data);
  }

  async function loadExport(platform, queryParams = { format: 'csv' }) {
    let query = '';
    const paramItems = Object.entries({ ...selectedFilters.value, ...queryParams });

    if (paramItems.length) {
      query = `?${paramItems.map(([key, value]) => `${key}=${value}`).join('&')}`;
    }

    try {
      const blob = await exportFetcher({
        method: 'GET',
        url: `${SOCIAL_ANALYTICS_API}/${platform}/scrapings${query}`,
        responseType: RESPONSE_TYPES.BLOB
      });

      let dateString = '-';
      if (selectedFilters.value.start)
        dateString = `${dateString}${moment(selectedFilters.value.start).format('YYYY-MM-DD')}`;
      if (selectedFilters.value.start && selectedFilters.value.end) dateString = `${dateString}-`;
      if (selectedFilters.value.end)
        dateString = `${dateString}${moment(selectedFilters.value.end).format('YYYY-MM-DD')}`;

      saveFile({ blob, fileName: `${platform}${dateString}` });
    } catch (err) {
      console.error('failed to fetch social analytics scrapings');
    }
  }

  function scrapings(platform) {
    return rawScrapings[platform].value || [];
  }

  const hasFilters = computed(() => Object.keys(selectedFilters.value).length);

  function clearFilters() {
    selectedFilters.value = {};
  }

  function setFilters(filterValues) {
    selectedFilters.value = filterValues;
  }

  return {
    loading,
    loadScrapings,
    selectedFilters,
    hasFilters,
    clearFilters,
    setFilters,
    scrapings,
    platforms,
    loadExport
  };
};

export default useSocialAnalyticsScraping;
