export const Action = {
  // Users
  UPDATE_USERDATA: 'UPDATE_USERDATA',
  // Connected Customers
  UPDATE_CONNECTABLE_CUSTOMERS: 'UPDATE_CONNECTABLE_CUSTOMERS',
  ADD_NEW_CONNECTION: 'ADD_NEW_CONNECTION',
  DELETE_CONNECTION: 'DELETE_CONNECTION'
};
export const Mutation = {
  STORE_USERDATA: 'STORE_USERDATA',
  STORE_CONNECTABLE_CUSTOMERS: 'STORE_CONNECTABLE_CUSTOMERS'
};
