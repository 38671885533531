import { ref } from 'vue';
import { DEVICE_TYPES } from '../../../views/oewa/OewaConstants';

const DEFAULT_SELECTED_DEVICE_TYPE = '';

const useOewaDevices = () => {
  const selectedDeviceType = ref(DEFAULT_SELECTED_DEVICE_TYPE);

  const deviceTypes = [
    {
      name: 'Alle',
      value: DEVICE_TYPES.ALL
    },
    {
      name: 'Nur Desktop',
      value: DEVICE_TYPES.DESKTOP
    },
    {
      name: 'Nur Mobile Web',
      value: DEVICE_TYPES.MOBILE_WEB
    },
    {
      name: 'Nur Mobile Apps',
      value: DEVICE_TYPES.MOBILE_APP
    },
    {
      name: 'Mobil',
      value: DEVICE_TYPES.MOBILE
    },
    {
      name: 'Web',
      value: DEVICE_TYPES.WEB
    }
  ];

  function resetSelectedDeviceType() {
    selectedDeviceType.value = DEFAULT_SELECTED_DEVICE_TYPE;
  }

  return {
    selectedDeviceType,
    deviceTypes,
    resetSelectedDeviceType
  };
};

export default useOewaDevices;
