export const Action = {
  // ad networks
  UPDATE_ADNETWORKS: 'UPDATE_ADNETWORKS',
  UPDATE_ADNETWORK: 'UPDATE_ADNETWORK',
  DELETE_ADNETWORK: 'DELETE_ADNETWORK',
  SAVE_ADNETWORK: 'SAVE_ADNETWORK',
  // categories
  UPDATE_CATEGORIES: 'UPDATE_CATEGORIES',
  CREATE_OR_EDIT_CATEGORY: 'CREATE_OR_EDIT_CATEGORY',
  COMBINE_CATEGORY: 'COMBINE_CATEGORY',
  DELETE_CATEGORY: 'DELETE_CATEGORY',
  UPDATE_CATEGORY_TARGETS: 'UPDATE_CATEGORY_TARGETS',
  // mediahouses
  UPDATE_MEDIAHOUSES: 'UPDATE_MEDIAHOUSES',
  UPDATE_MEDIAHOUSE: 'UPDATE_MEDIAHOUSE',
  SAVE_MEDIAHOUSE: 'SAVE_MEDIAHOUSE',
  DELETE_MEDIAHOUSE: 'DELETE_MEDIAHOUSE',
  // widgets
  UPDATE_WIDGETS: 'UPDATE_WIDGETS',
  SAVE_WIDGET: 'SAVE_WIDGET',
  DELETE_WIDGET: 'DELETE_WIDGET'
};
export const Mutation = {
  STORE_ADNETWORKS: 'STORE_ADNETWORKS',
  STORE_ADNETWORK: 'STORE_ADNETWORK',
  STORE_CATEGORIES: 'STORE_CATEGORIES',
  STORE_CATEGORY_TARGETS: 'STORE_CATEGORY_TARGETS',
  STORE_MEDIAHOUSES: 'STORE_MEDIAHOUSES',
  STORE_MEDIAHOUSE: 'STORE_MEDIAHOUSE',
  STORE_WIDGETS: 'STORE_WIDGETS'
};
