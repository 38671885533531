import { useAxios } from 'composables';
import { getApiRoute } from 'routeconfig';
import { API_METHODS } from 'composables/useAxios';

const verifyParams = ({
  campaignId,
  customerId,
  contactBreakdowns = null,
  selectedContactBreakdowns = null,
  typeOfRequest = API_METHODS.GET
}) => {
  if (!campaignId && !customerId) {
    throw new Error('Either campaignId or customerId must be provided');
  }

  if (typeOfRequest === API_METHODS.POST && (!contactBreakdowns || !selectedContactBreakdowns)) {
    throw new Error('contactBreakdowns and selectedContactBreakdowns are required');
  }
};

export const useCampaignContacts = () => {
  const {
    state: { loading: fetchingCampaignContacts, data: campaignContacts },
    fetcher: campaignContactsFetcher
  } = useAxios();

  const {
    state: { loading: updatingCampaignContacts },
    fetcher: updateCampaignContactsFetcher
  } = useAxios();

  const fetchCampaignContacts = async ({ campaignId = null, customerId = null } = {}) => {
    verifyParams({ campaignId, customerId });

    const query = campaignId ? { campaign_id: campaignId } : { customer_id: customerId };

    const apiRoute = getApiRoute({
      name: 'api.campaigns.contact-breakdowns',
      query
    });

    return campaignContactsFetcher({
      url: apiRoute
    });
  };

  const updateCampaignContacts = async ({
    campaignId = null,
    customerId = null,
    contactBreakdowns,
    selectedContactBreakdowns
  }) => {
    verifyParams({
      campaignId,
      customerId,
      contactBreakdowns,
      selectedContactBreakdowns,
      typeOfRequest: API_METHODS.POST
    });

    const requestBody = {
      contact_breakdowns: contactBreakdowns,
      selected_contact_breakdowns: selectedContactBreakdowns
    };

    if (campaignId) {
      requestBody.campaign_id = campaignId;
    } else {
      requestBody.customer_id = customerId;
    }

    const apiRoute = getApiRoute({
      name: 'api.campaigns.contact-breakdowns.store'
    });

    return updateCampaignContactsFetcher({
      url: apiRoute,
      method: API_METHODS.POST,
      requestBody
    });
  };

  return {
    fetchCampaignContacts,
    fetchingCampaignContacts,
    campaignContacts,
    updateCampaignContacts,
    updatingCampaignContacts
  };
};
