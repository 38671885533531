import { computed, ref } from 'vue';
import useAxios from '../useAxios';
import { BRANDLIFT_API } from 'constants';
import { Message } from 'reppublika_components';
import { camelize } from 'helper';

const columns = ref([]);

const useBrandliftBenchmarksColumns = () => {
  const {
    state: { loading },
    fetcher
  } = useAxios({
    initialValue: []
  });

  async function loadColumns() {
    let data;
    try {
      data = await fetcher({
        method: 'GET',
        url: `${BRANDLIFT_API}/columns`
      });
    } catch (err) {
      console.error('failed to fetch benchmarks');
    }
    if (!data) return;
    columns.value = data.sort(({ order: a }, { order: b }) => {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    });
  }

  function deleteColumn(record) {
    columns.value = columns.value.filter(column => record.key !== column.key);

    return fetcher({
      method: 'DELETE',
      url: `${BRANDLIFT_API}/column/${record.key}`
    })
      .then(() => {
        Message.success('Successfully deleted column.', 2.5);
      })
      .catch(e => {
        console.log(e);
        Message.error("Couldn't delete column.", 5);
        columns.value = [...columns.value, record];
      });
  }

  function saveColumn(payload) {
    const key = camelize(payload.name).replace(/\W/g, '');
    if (columns.value.find(item => item.key === key)) {
      Message.error(
        `Couldn't create unique key from name. ${key} already exists. Please change the name.`,
        5
      );
      return;
    }

    const item = { ...payload, key, order: columns.value.length };
    columns.value = [...columns.value, item];

    return fetcher({
      method: 'POST',
      url: `${BRANDLIFT_API}/column/${key}`,
      requestBody: item
    })
      .then(() => {
        Message.success('Successfully saved column.', 2.5);
      })
      .catch(e => {
        console.log(e);
        Message.error("Couldn't save column.", 5);
        columns.value = columns.value.filter(column => key !== column.key);
      });
  }

  function resortTo(targetPosition, resorting) {
    const unchangedItems = [];
    const itemsToChange = [];
    const itemToReposition = columns.value.find(item => item.key === resorting);

    columns.value.forEach(item => {
      if (item.order < targetPosition && item.key !== resorting) {
        unchangedItems.push(item);
      } else if (item.key !== resorting) {
        itemsToChange.push(item);
      }
    });

    columns.value = [...unchangedItems, itemToReposition, ...itemsToChange].map((item, index) => ({
      ...item,
      order: index
    }));

    fetcher({
      method: 'POST',
      url: `${BRANDLIFT_API}/columns/resort`,
      requestBody: columns.value
    })
      .then(() => {
        Message.success('Successfully saved resorting.', 2.5);
      })
      .catch(e => {
        console.log(e);
        Message.error("Couldn't save resorting. Reverting.", 5);
        loadColumns();
      });
  }

  const numberColumns = computed(() => {
    const byKey = [];

    columns.value.forEach(column => {
      if (column.type !== 'number') return;
      byKey.push(column.key);
    });

    return byKey;
  });

  const columnByKey = computed(() => {
    const byKey = {};
    columns.value.forEach(column => {
      byKey[column.key] = column;
    });
    return byKey;
  });

  return {
    loading,
    columns,
    loadColumns,
    deleteColumn,
    saveColumn,
    resortTo,
    numberColumns,
    columnByKey
  };
};

export default useBrandliftBenchmarksColumns;
