import { useAxios } from 'composables';
import { getApiRoute } from 'routeconfig';
import { API_METHODS } from 'composables/useAxios';

const useAttributes = () => {
  const {
    state: { loading: loadingAttributes, error: loadingAttributesError, data: attributes },
    fetcher: attributesFetcher
  } = useAxios();

  const fetchAttributes = ({ searchValue, campaignStartDate = null }) => {
    let query = { search_value: searchValue };

    if (campaignStartDate) {
      query = { ...query, date: campaignStartDate };
    }

    return attributesFetcher({
      method: API_METHODS.GET,
      url: getApiRoute({ name: 'api.panel-attributes.search', query }),
      responseAdapter: data => data?.attributes || []
    });
  };

  return {
    fetchAttributes,
    loadingAttributes,
    loadingAttributesError,
    attributes
  };
};

export default useAttributes;
