import { pickBy } from 'lodash';
import { store } from '../../../main';

export const getColumns = ({
  item,
  showType = true,
  registeredOnlyStats = false,
  showChannelType = false,
  ignoreRatingsPool = false
}) => {
  if (!item || !item.stats) {
    return {};
  }

  const columns = {};

  let stats = item.stats;

  if (registeredOnlyStats && !!item.registered_only_stats) {
    stats = item.registered_only_stats;
  }

  if (showType) {
    columns.type = {
      value: item.type,
      formatted: item.type
    };
  }

  if (showChannelType) {
    columns.channelType = {
      value: item.channel_types?.map(el => el.name).join(', '),
      formatted: item.channel_types?.map(el => el.name).join(', ')
    };
  }

  columns.population_reach = stats.population_reach;
  columns.unique_users = stats.unique_users;

  if (store.getters.isAdmin) {
    columns.visits = stats.visits;
    columns.impressions = stats.impressions;
  }

  if (!!stats.affinities) {
    columns.affinities = stats.affinities;
  }

  if (ignoreRatingsPool) {
    if (!!stats.unique_panelists) {
      columns.unique_panelists = stats.unique_panelists;
    }
  }

  if (!ignoreRatingsPool && store.getters.ratingsPoolHasItemWithUniquePanelistsProperty) {
    columns.unique_panelists = stats.unique_panelists || {
      formatted: '–',
      value: 0
    };
  }

  columns.usetime = stats.usetime;

  //Remove undefined columns
  return pickBy(columns, v => v !== undefined);
};
