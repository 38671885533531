import { Action, Mutation } from '../types';
import { getApiRoute } from 'routeconfig';
import { errorlog, handleCallback } from 'helper';
import axios from 'axios';

export default {
  [Action.UPDATE_BILLING_DATA]: ({ commit }, payload = {}) => {
    return axios
      .get(
        getApiRoute({
          name: 'api.customer.billing.overview',
          query: {
            customer: payload.customer,
            date: payload.date
          }
        })
      )
      .then(({ data }) => {
        commit(`${Mutation.STORE_BILLING_DATA}`, data);
        handleCallback(payload.callback);
      })
      .catch(errorlog);
  },
  [Action.UPDATE_DETAIL_BILLING_DATA]: ({ commit }, payload = {}) => {
    return axios
      .get(
        getApiRoute({
          name: 'api.customer.billing.details',
          query: {
            id: payload.id,
            date: payload.date
          }
        })
      )
      .then(({ data }) => {
        commit(`${Mutation.STORE_DETAIL_BILLING_DATA}`, data);
        handleCallback(payload.callback);
      })
      .catch(errorlog);
  }
};
