import { useAxios } from 'composables';
import { API_METHODS } from 'composables/useAxios';
import { getApiRoute } from 'routeconfig';

const useChannelTypes = () => {
  const {
    state: { loading: channelTypesLoading, error: channelTypesError, data: channelTypes },
    fetcher: fetchChannelTypesFetcher
  } = useAxios({
    initialValue: []
  });
  const {
    state: { loading: creatingChannelType },
    fetcher: createChannelTypeFetcher
  } = useAxios();
  const {
    state: { loading: updatingChannelType },
    fetcher: updateChannelTypeFetcher
  } = useAxios();
  const {
    state: { loading: removingChannelType },
    fetcher: removeChannelTypeFetcher
  } = useAxios();

  const fetchChannelTypes = () =>
    fetchChannelTypesFetcher({
      url: getApiRoute('api.admin.ratings.channels.types.list')
    });

  const createChannelType = ({ name }) =>
    createChannelTypeFetcher({
      method: API_METHODS.POST,
      url: getApiRoute('api.admin.ratings.channels.types.create'),
      requestBody: {
        name
      }
    });

  const updateChannelType = ({ id, name }) =>
    updateChannelTypeFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: 'api.admin.ratings.channels.types.update',
        params: {
          id
        }
      }),
      requestBody: {
        name
      }
    });

  const removeChannelType = id =>
    removeChannelTypeFetcher({
      method: API_METHODS.DELETE,
      url: getApiRoute({
        name: 'api.admin.ratings.channels.types.delete',
        params: {
          id
        }
      })
    });

  return {
    fetchChannelTypes,
    channelTypesLoading,
    channelTypesError,
    createChannelType,
    creatingChannelType,
    channelTypes,
    updateChannelType,
    updatingChannelType,
    removeChannelType,
    removingChannelType
  };
};

export default useChannelTypes;
