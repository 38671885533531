import { nextTick } from 'vue';
import Cookies from 'js-cookie';
import accounting from 'accounting';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { $ } from 'libs';
import { Message } from 'reppublika_components';
import { ALLOWED_TOKEN_PROPERTIES } from 'utils/constants';

export const chartColors = ['#3C8ADB', '#95D5EC', '#A4E5D4', '#EE6F66'];

export const handleAsyncError = (error, fallbackMessage) => {
  const { error: errorResponse } = error?.response?.data || {};

  if (!errorResponse) {
    return Message.error(fallbackMessage);
  }

  if (!isObject(errorResponse)) {
    return Message.error(errorResponse);
  }

  const errorEntries = Object.entries(errorResponse);

  const formattedErrorMessage = errorEntries.reduce((result, [field, validationError], currentIndex) => {
    const separator = currentIndex !== errorEntries.length - 1 ? ' | ' : '';

    if (!Array.isArray(validationError)) {
      return (result += `${field}: ${validationError}${separator}`);
    }

    return (result += `${field}: ${validationError.join(', ')}${separator}`);
  }, '');

  Message.error(formattedErrorMessage || fallbackMessage);
};

export const errorlog = error => {
  if (!!error.response) {
    console.log(
      `%cError: ${error.response.config.method} request failed.\nResponse: HTTP Status ${error.response.data.code}: ${error.response.data.error}`,
      'background: #222; color:#ff6363; font-size:18px '
    );
    if (typeof error.response.data.error === 'string') {
      Message.error({
        content: `Error: ${error.response.data.code} ${error.response.data.error}`,
        duration: 3
      });
    } else {
      const errorMessage = Object.keys(error.response.data.error)
        .map(key =>
          error.response.data.error[key]
            .map(msg => msg.replace(/\s1\.$/, ' active.').replace(/\s0\.$/, ' inactive.'))
            .join(' ')
        )
        .join('\n');
      Message.error({
        content: `Error: ${error.response.data.code} ${errorMessage}`,
        duration: 3
      });
    }
  } else if (error == 'Error: Network Error') {
    Message.error({
      content: `Request failed. Please check your internet connection or turn off ad blocking browser addons (Ghostery, Disconnect, etc.)`,
      duration: 3
    });
  } else {
    if (!!Object.getPrototypeOf(error).__CANCEL__) return;

    console.log(`%cNon-HTTP Error in request:\n${error}`, 'background: #222; color:#ff6363; font-size:18px');
    console.log(error);
    Message.error({
      content: `Javascript Error: ${error}`,
      duration: 3
    });
  }
};

export const clearCache = () => window?.caches?.delete?.('reppublika_api') || Promise.resolve();

export const handleCallback = callback => {
  if (!!callback && typeof callback === 'function') callback();
};

export const delayListRender = callback => {
  if (!!callback && typeof callback === 'function') {
    nextTick(() => {
      setTimeout(callback, 0);
    });
  } else {
    errorlog(`delayListRender takes one argument of type function.\nReceived type: ${typeof callback}`);
  }
};

export const chartColorsMono = [
  '#2A72D4',
  '#397BD9',
  '#4885DE',
  '#568EE2',
  '#6597E7',
  '#74A1EC',
  '#83AAF1',
  '#91B3F5',
  '#A0BDFA',
  '#AFC6FF'
];

export const chartDualMono = ['#83AAF1', '#2A72D4'];
export const chartColorDuo = ['#B1BECB', '#5FA3EA'];

export const chartCrosshair = {
  width: 1,
  color: 'rgba(51,51,105, .14)'
};

export const formatMoney = (amount, currency = '€') => {
  return accounting.formatMoney(amount, currency, 2, '.', ',');
};

export const progressStates = {
  0: {
    name: 'Finished',
    class: 'c-progress__bar--success',
    percentage: '100%'
  },
  1: {
    name: 'Running',
    class: 'c-progress__bar--warning',
    percentage: '58%'
  },
  2: {
    name: 'Not enough data',
    class: 'c-progress__bar--error',
    percentage: '20%'
  },
  3: {
    name: 'In progress',
    class: 'c-progress__bar--neutral',
    percentage: '50%'
  }
};

export const jumpTo = id => {
  if (typeof id !== 'string') return;

  let jumpId = id;
  if (id.indexOf('=') !== -1) {
    jumpId = id.split('=')[1];
  }

  const element = document.querySelector(jumpId[0] !== '#' ? `#${jumpId}` : jumpId);

  if (!element) return;

  element.scrollIntoView({
    behavior: 'smooth'
  });
};

const omitArraysFromKey = key => {
  return key.replace(/(\[\])$/g, '');
};

// This function exports parameters from a url
// Returns: Object
export const exportParams = (query, omitArrays = false) => {
  // Remove ? from query
  query = query.slice(0, 1) === '?' ? query.slice(1, query.length) : query;
  const vars = query.split('&');
  const queryString = {};

  if (query) {
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      let key = decodeURIComponent(pair[0]);
      const value = decodeURIComponent(pair[1]);
      const arrayExp = new RegExp('(\\[\\])$', 'gm');
      let isArray = arrayExp.test(key);
      if (key.includes('[') && key.includes(']') && key.indexOf(']') > key.indexOf('[')) {
        isArray = true;
      }

      if (omitArrays) {
        // Remove brackets to arrays
        key = omitArraysFromKey(key);
      }

      // If first entry with this name
      const decodedValue = decodeURIComponent(value.replace(/\+/g, ' '));
      if (typeof queryString[key] === 'undefined') {
        queryString[key] = isArray ? [decodedValue] : decodedValue;
        // If second entry with this name
      } else if (typeof queryString[key] === 'string') {
        const arr = [queryString[key], decodedValue];
        queryString[key] = arr;
        // If third or later entry with this name
      } else {
        queryString[key].push(decodedValue);
      }
    }
  }
  return queryString;
};

const castValue = value => {
  const castedValue = Number(value);

  if (Number.isNaN(castedValue)) {
    return value;
  } else {
    return castedValue;
  }
};

export const parseQueryValue = filterValue => {
  if (Array.isArray(filterValue)) {
    return filterValue.map(value => castValue(value));
  }

  return castValue(filterValue);
};

const getDefaultFilterValue = filter => {
  if (filter.type === 'switch') {
    return false;
  }

  if (filter.type === 'daterange') {
    return [];
  }

  if (filter.type === 'number') {
    return 0;
  }

  return '';
};

export const applyDefaultFilterValuesBasedOnQueryParams = ({ filters, queryParams }) =>
  filters.map(filter => {
    const filterValueExistsInQuery = !!queryParams[filter.name];

    if (!filterValueExistsInQuery) {
      if (filter?.value?.fromDate) {
        const { fromDate } = filter.value;

        return {
          ...filter,
          value: fromDate,
          formattedDate: moment(fromDate).format('MMMM YYYY')
        };
      }

      if (filter.value || filter.value === 0) {
        return filter;
      }

      return {
        ...filter,
        value: getDefaultFilterValue(filter)
      };
    }

    const filterValueFromQuery = queryParams[filter.name];
    const wrapValueInArray = filter.multiple && !Array.isArray(filterValueFromQuery);
    const parsedValue = parseQueryValue(wrapValueInArray ? [filterValueFromQuery] : filterValueFromQuery);

    if (filter.type === 'daterange') {
      // the range picker component expects the value in the array of strings
      const value = filterValueFromQuery?.split('-');
      const formattedDate = value.map(date => moment(date).format('MMM DD YYYY')).join(' - ');

      return {
        ...filter,
        value,
        formattedDate
      };
    }

    return {
      ...filter,
      value: parsedValue
    };
  });

export const RChart = {
  colors: {
    graphPurple: '#BE72D1',
    graphBlue: '#5FA3EA',
    graphBlueTransparent: 'rgba(63, 148, 237, .72)',
    graphGray: 'rgba(172,164,184,0.55)',
    graphDark: '#48324D',
    text: '#8B8191',
    textStrong: '#48324D',
    textSecondary: '#9F9AA6',
    yellow: '#EFB93F'
  },

  defaults: {
    chart: {
      height: 320,
      spacingTop: 0,
      spacingBottom: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: undefined,
      marginLeft: 0,
      backgroundColor: '',
      reflow: true
    },
    colors: chartColors,
    title: {
      text: null
    },
    subtitle: {
      text: null
    },
    credits: {
      enabled: false
    },
    tooltip: {
      padding: 3,
      backgroundColor: 'rgba(255,255,255,0)',
      borderRadius: 3,
      borderColor: 'rgba(255,255,255,0)',
      shadow: false,
      useHTML: true,
      formatter: function () {
        let allPoints;
        const { point, points } = this;
        // Convert one point into array
        allPoints = points || [point];

        return `
          <div class="highcharts-tooltip--html c-chart-tooltip o-type-12">
            <div class="o-media o-media--top c-chart-tooltip__item" style="min-width: 180px">
              <b>${moment(this.x).format('dddd, DD.MM.YYYY')}</b>
            </div>
            ${allPoints
              .map(
                p => `
              <div class="o-media o-media--top c-chart-tooltip__item" style="min-width: 180px">
                <div class="o-media__fixed u-pr">
                    <span style="
                      display: inline-block;
                      width: 6px; 
                      height: 6px; 
                      margin-top: 4px;
                      background-color: ${p.color};
                      border-radius: 100%;
                    "></span>
                </div>
                <div class="o-media__fluid">
                  <span class="u-weight-bold u-block">${p.category || ''}</span>
                  <div class="o-media">
                    <div class="o-media__fluid u-pr-x3">
                      ${p.name || (p.series && p.series.name) || 'Values:'}
                    </div>
                    <div class="o-media__fixed u-pr-no u-weight-bold">
                      ${p.y.toLocaleString('en-US')}
                    </div>
                  </div>
                </div>
              </div>
            `
              )
              .join('')}
          </div>
      `;
      }
    },
    plotOptions: {
      area: {
        marker: {
          enabled: false
        }
      },
      series: {
        borderWidth: 0,
        marker: {
          enabled: false
        }
      },
      bar: {
        borderRadius: 1,
        minPointLength: 2
      },
      column: {
        borderRadius: 2,
        minPointLength: 2
      },
      line: {
        lineWidth: 3,
        marker: {
          enabled: false,
          symbol: 'square'
        }
      }
    },
    legend: {
      symbolRadius: 1,
      symbolHeight: 10,
      symboldWidth: 10,
      useHTML: true,
      squareSymbol: true
    },
    xAxis: {
      title: {
        text: null
      }
    },
    yAxis: {
      title: {
        text: null
      }
    },
    exporting: {
      enabled: false,
      filename: 'reppublika-chart',
      fallbackToExportServer: false,
      buttons: {
        contextButton: {
          className: 'highcharts-export-button',
          align: 'right',
          menuItems: ['printChart', 'downloadPNG', 'downloadJPEG', 'downloadPDF']
        }
      }
    }
  },

  options: function (options) {
    let defaults = cloneDeep(this.defaults);

    if (options.chart && options.chart.marginLeft === 'auto') {
      defaults.chart.marginLeft = undefined;
      options.chart.marginLeft = undefined;
    }

    return $.extend(true, {}, defaults, options);
  },

  switcher: function () {
    var switcher = '.js-chart-switcher',
      tab = '.js-chart-switcher-tab',
      buttons = '.js-chart-switcher-button',
      buttonActiveClass = 'c-panel__tab--active';

    var updateChart = function () {};

    var bindButtons = function () {
      if ($(switcher).length) {
        $(buttons).on('click', function (e) {
          var $this = $(this);

          // Update button styles
          $this.closest(switcher).find(tab).removeClass(buttonActiveClass);
          $this.closest(tab).addClass(buttonActiveClass);

          // Then update the chart
          e.preventDefault();
          updateChart();
        });
      }
    };

    return {
      init: function () {
        bindButtons();
      }
    };
  }
};

export const exportRoutesWithAppMetaField = (appName, routes) =>
  routes.map(route => {
    const newRoute = {
      ...route,
      meta: {
        ...(route.meta || {}),
        app: appName
      }
    };

    if (!route.children) {
      return newRoute;
    }

    return {
      ...newRoute,
      children: exportRoutesWithAppMetaField(appName, route.children)
    };
  });

const FILE_EXTENSIONS_MAP = {
  'application/pdf': 'pdf',
  'text/csv': 'csv',
  'application/csv': 'csv',
  'application/vnd.ms-excel': 'xls'
};

export const saveFile = ({ blob, fileName, overrideType }) => {
  // the application/xls MIME type needs to be explicitly overridden to infer the xls file type properly
  // https://stackoverflow.com/a/55546791/9599137
  let blobType = blob.type === 'application/xls' ? 'application/vnd.ms-excel' : blob.type;
  if (overrideType) blobType = overrideType;
  const newBlob = new Blob([blob], { type: blobType });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');

  let fileExtension = FILE_EXTENSIONS_MAP[blobType];

  if (blobType.includes?.(';')) {
    const mimeType = blobType.split(';')[0];

    fileExtension = FILE_EXTENSIONS_MAP[mimeType];
  }

  link.href = data;
  link.download = `${fileName}.${fileExtension}`;
  link.click();

  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
};

export const exportPdf = ({
  path = requiredParam('path'),
  keys = requiredParam('keys'),
  pubkeys = requiredParam('pubkeys')
}) => {
  if (!Array.isArray(keys) || !Array.isArray(pubkeys)) {
    return Promise.reject('`keys` and `pubkeys` must be of type array.');
  }

  const body = {
    access_token: Object.fromEntries(document.cookie.split('; ').map(x => x.split('='))).access_token,
    refresh_token: Object.fromEntries(document.cookie.split('; ').map(x => x.split('='))).refresh_token,
    host: location.host,
    url: `${location.origin}${path}`,
    keys: keys, // will be appear as a hash in filename
    pubkeys: pubkeys // will appear as plain text in filename, concatenated with '_'
  };

  if (process.env.VUE_APP_PDF_EXPORT_URL) {
    const pdfName = (pubkeys, keys) => {
      return `${pubkeys.join('_')}_${keys.map(key => Buffer.from(key).toString('base64')).join('_')}.pdf`;
    };
    return fetch(process.env.VUE_APP_PDF_EXPORT_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...body,
        host: 'host.docker.internal:3600',
        url: body.url.replace('reppublika.local', 'host.docker.internal')
      })
    }).then(response =>
      response
        .blob()
        .then(pdf => ({ name: pdfName(body.pubkeys, body.keys), pdf }))
        .then(blob => {
          saveFile({ blob: blob.pdf, fileName: blob.name, overrideType: 'application/pdf' });
        })
    );
  }

  return fetch('https://zihh7auhvmmgsh7j2musi7462i0dwnwq.lambda-url.eu-west-1.on.aws', {
    method: 'POST',
    body: JSON.stringify(body)
  })
    .then(r => r.json())
    .then(data => {
      if (!data?.pdfLocation) return;

      return fetch(data.pdfLocation).then(r =>
        r
          .blob()
          .then(pdf => ({ name: r.url.replace(/https:\/\/.*\//, ''), pdf }))
          .then(blob => {
            saveFile({ blob: blob.pdf, fileName: blob.name });
          })
      );
    });
};

export const formatOrNull = date => {
  if (!!date && typeof date.format === 'function') {
    return date.format('YYYY-MM-DD');
  }

  return null;
};

export const deepPrintValue = item => {
  if (Array.isArray(item)) {
    return item.join('_');
  }
  return item;
};

export const debounce = (callback, delay) => {
  let timeoutId;

  return function (...args) {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      timeoutId = null;
      callback.call(this, ...args);
    }, delay);
  };
};

export const buildFormData = (formObj, keysToExclude = []) => {
  const formData = new FormData();

  Object.entries(formObj).map(([key, value]) => {
    if (formData.has(key)) return;

    if ((!value && value !== 0) || value === null) return;

    if (keysToExclude.indexOf(key) > -1) return;

    if (value instanceof Date || value instanceof File) {
      formData.append(key, value);

      return;
    }

    // https://stackoverflow.com/a/28434829/9599137
    if (Array.isArray(value)) {
      value.forEach(arrayValue => {
        formData.append(`${key}[]`, typeof arrayValue === 'object' ? JSON.stringify(arrayValue) : arrayValue);
      });

      return;
    }

    if (typeof value === 'object') {
      Object.entries(value).map(([objKey, objValue]) => {
        if (Array.isArray(objValue)) {
          objValue.forEach(val => {
            formData.append(`${key}[${objKey}][]`, val);
          });
        } else {
          formData.append(key, value);
        }
      });

      return;
    }

    formData.append(key, value);
  });

  return formData;
};

export const parseKeywordsFromUrl = url => {
  const { keywords } = url;

  if (!keywords) {
    return [''];
  }

  if (Array.isArray(keywords)) {
    return [...keywords];
  }

  return [keywords];
};

export const capitalize = str => {
  if (typeof str !== 'string') {
    return '';
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};

// https://stackoverflow.com/a/38340730/9599137
export const removeEmptyPropertyValuesFromObject = (obj = requiredParam('obj')) => {
  if (!isObject(obj)) {
    throw new Error(`${obj} must be of type object`);
  }

  return Object.fromEntries(
    // eslint-disable-next-line no-unused-vars
    Object.entries(obj).filter(([key, value]) => {
      if (Array.isArray(value)) {
        return !!value.length;
      }

      if (isObject(value)) {
        return !!Object.keys(value).length;
      }

      return !!value || value === 0;
    })
  );
};

// https://stackoverflow.com/a/56030135/9599137
export const removeProperties = (object, ...keys) => {
  // keys of type integers need to be coerced to strings as object keys can only be of type strings
  const adaptedKeys = keys.map(key => (Number.isInteger(key) ? `${key}` : key));

  return Object.entries(object).reduce(
    (prev, [key, value]) => ({
      ...prev,
      ...(!adaptedKeys.includes(key) && { [key]: value })
    }),
    {}
  );
};

export const requiredParam = (param = '') => {
  const msg = `Param ${param} is required`;

  console.error(msg);

  throw new Error(msg);
};

export const isObject = maybeObject =>
  typeof maybeObject === 'object' && maybeObject !== null && !Array.isArray(maybeObject);

export const isBoolean = maybeBoolean => typeof maybeBoolean === 'boolean';

export function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '')
    .replace(/\W/g, '');
}

export const shallowEqual = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  return keys1.every(key => obj1[key] == obj2[key]);
};

export const uniqueIdGenerator = () => Math.random().toString(36).substring(2, 8);

export const stripHTMLTags = string => {
  // https://stackoverflow.com/a/1499916/9599137
  const regex = /(<([^>]+)>)/gi;

  if (typeof string !== 'string') {
    throw new Error(`stripHTMLTags error: ${string} is not of type string`);
  }

  return string.replace(regex, '');
};

export const convertToArray = value => [].concat(value ?? []);

export const trimObject = objectToBeTrimmed => {
  if (!isObject(objectToBeTrimmed)) {
    throw new Error(`trimObject error: ${objectToBeTrimmed} is not an object`);
  }

  return Object.fromEntries(
    Object.entries(objectToBeTrimmed).map(([key, value]) => [key, value?.trim?.() || value])
  );
};

export const getTokenFromCookies = () => {
  const token = Cookies.get(ALLOWED_TOKEN_PROPERTIES.ACCESS_TOKEN);
  const type = Cookies.get(ALLOWED_TOKEN_PROPERTIES.TOKEN_TYPE);

  if (!token || !type) return;

  return `${type} ${token}`;
};

export const round = ({ number, precision }) => {
  const factorOfTen = Math.pow(10, precision);

  return Math.round(number * factorOfTen) / factorOfTen;
};

export const pluralize = ({ count, noun, suffix = 's' }) => `${count} ${noun}${count > 1 ? suffix : ''}`;

// https://stackoverflow.com/a/54974076/9599137
export const hasDuplicates = (arr = requiredParam('arr')) => new Set(arr).size !== arr.length;

export const injectExternalScript = ({ src, async = false, defer = false }) => {
  const script = document.createElement('script');

  script.src = src;
  script.async = async;
  script.defer = defer;

  document.head.appendChild(script);
};

export const getTabHashValue = () => {
  const tab = window.location.hash.split('#tab=')?.[1];

  return tab ? `#tab=${tab}` : '';
};
