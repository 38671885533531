import { computed, ref } from 'vue';
import useAxios from '../useAxios';
import { BRANDLIFT_API } from 'constants';
import { Message } from 'reppublika_components';
import { camelize } from 'helper';

const categories = ref([]);
const selectedCategory = ref('');

const useBrandliftBenchmarksCategories = () => {
  const {
    state: { loading },
    fetcher
  } = useAxios({
    initialValue: []
  });

  async function loadCategories() {
    let data;
    try {
      data = await fetcher({
        method: 'GET',
        url: `${BRANDLIFT_API}/categories`
      });
    } catch (err) {
      console.error('failed to fetch benchmarks');
    }
    if (!data) return;
    categories.value = data;
    selectedCategory.value = [data[0].key];
  }

  function deleteCategory(record) {
    categories.value = categories.value.filter(category => record.key !== category.key);

    return fetcher({
      method: 'DELETE',
      url: `${BRANDLIFT_API}/category/${record.key}`
    })
      .then(() => {
        Message.success('Successfully deleted category.', 2.5);
      })
      .catch(e => {
        console.log(e);
        Message.error("Couldn't delete category.", 5);
        categories.value = [...categories.value, record];
      });
  }

  function saveCategory(payload) {
    const key = camelize(payload.name);

    if (categories.value.find(item => item.key === key)) {
      Message.error(
        `Couldn't create unique key from name. ${key} already exists. Please change the name.`,
        5
      );
      return;
    }

    const item = { ...payload, key };
    categories.value = [...categories.value, item];

    return fetcher({
      method: 'POST',
      url: `${BRANDLIFT_API}/category/${key}`,
      requestBody: payload
    })
      .then(() => {
        Message.success('Successfully saved category.', 2.5);
      })
      .catch(e => {
        console.log(e);
        Message.error("Couldn't save category.", 5);
        categories.value = categories.value.filter(categories => key !== categories.key);
      });
  }

  const categoryNameByKey = computed(() => {
    const byKey = {};
    categories.value.forEach(category => {
      byKey[category.key] = category.name;
    });
    return byKey;
  });

  return {
    loading,
    categories,
    categoryNameByKey,
    selectedCategory,
    loadCategories,
    deleteCategory,
    saveCategory
  };
};

export default useBrandliftBenchmarksCategories;
