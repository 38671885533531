<template>
  <PublicLayout>
    <template #header>
      <PublicHeader />
    </template>

    <section class="c-page">
      <div class="o-container">
        <article class="u-text-center u-pb-x10">
          <header class="u-mb-x10 u-mb-x5@600">
            <h1 class="o-type-34 u-weight-300 u-line-height--sm u-text-center u-pt-x5">
              You have no permissions to access this page.
            </h1>
          </header>
          <p class="o-type-16 u-inline-block u-12/24 u-color-text-transparent-2 u-24/24@800">
            You dont have the right permission to access that page. If for any reason you think that is wrong,
            please contact us
            <a href="mailto:support@reppublika.com" class="c-link u-weight-bold">support@reppublika.com</a>
            <br /><br />
            Go back to
            <router-link :to="{ name: 'startpage' }" class="c-link u-weight-bold">home</router-link>.
          </p>
          <div>
            <div style="width: 60px" class="u-inline-block u-border-bottom u-mt-x10"></div>
          </div>
        </article>
      </div>
    </section>

    <template #footer>
      <PublicFooter />
    </template>
  </PublicLayout>
</template>

<script>
import PublicLayout from './layout/PublicLayout.vue';
import PublicHeader from 'views/layout/partials/PublicHeader.vue';
import PublicFooter from 'views/layout/partials/PublicFooter.vue';

export default {
  components: { PublicLayout, PublicHeader, PublicFooter }
};
</script>
