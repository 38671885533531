export const DD_RUM_ClIENT_TOKEN =
  process.env.VUE_APP_DD_RUM_ClIENT_TOKEN || 'pub52db19ae8e5ac936119045d89d67d761';
export const DD_RUM_APPLICATION_ID =
  process.env.VUE_APP_DD_RUM_APPLICATION_ID || 'f80b23b6-c21f-4826-93aa-5d15867de269';
export const DD_RUM_SITE = process.env.VUE_APP_DD_RUM_SITE || 'datadoghq.eu';
export const DD_RUM_ENV = process.env.VUE_APP_DD_RUM_ENV;
export const DD_RUM_SESSION_SR = +process.env.VUE_APP_DD_RUM_SESSION_SR || 100;
export const DD_RUM_SESSION_REPLAY_SR = +process.env.VUE_APP_DD_RUM_SESSION_REPLAY_SR || 20;
export const DD_RUM_APP_VERSION = process.env.VUE_APP_BUILDNUMBER;
export const DD_RUM_ENABLED = process.env.VUE_APP_DD_RUM_ENABLED === 'true';
