import { computed } from 'vue';
import { useAxios } from 'composables';
import { getApiRoute } from 'routeconfig';

const useOewaHistoricChannels = ({ year, quarter }) => {
  const {
    state: { loading: loadingHistoricChannels, data: historicChannels },
    fetcher
  } = useAxios({
    initialValue: []
  });

  const fetchHistoricChannelsAPIRoute = computed(() =>
    getApiRoute({
      name: 'api.oewa.channels.history',
      query: { year, quarter }
    })
  );

  function fetchHistoricChannels() {
    return fetcher({
      url: fetchHistoricChannelsAPIRoute.value
    });
  }

  return {
    fetchHistoricChannels,
    loadingHistoricChannels,
    historicChannels
  };
};

export default useOewaHistoricChannels;
