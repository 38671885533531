import { computed } from 'vue';
import { useRoute } from 'vue-router';
import useOewaRatingsRankingList from './useOewaRatingsRankingList';
import useOewaChannels from './useOewaChannels';
import useOewaRatingsView from './useOewaRatingsView';
import { RANKING_ITEM_TYPES, API_ROUTES, RATINGS_VIEWS } from 'views/oewa/OewaConstants';
import { requiredParam } from 'utils/helpers';
import { getOewaRatingsApiRoute } from 'views/oewa/helpers';
import { translateOewaRatings } from 'utils/translate';

const useOewaRatings = (searchString = requiredParam('searchString')) => {
  const route = useRoute();
  const { currentView } = useOewaRatingsView();
  const {
    loading: loadingServices,
    error: hasServicesRequestErroredOut,
    fetchRankingList: fetchServices,
    rankingItemsMatchingSearchString: services,
    subRankingItems: subServices
  } = useOewaRatingsRankingList(searchString, RANKING_ITEM_TYPES.SERVICE);
  const {
    loading: loadingMediahouses,
    error: hasMediahousesRequestErroredOut,
    fetchRankingList: fetchMediahouses,
    rankingItemsMatchingSearchString: mediahouses,
    subRankingItems: subMediahouses
  } = useOewaRatingsRankingList(searchString, RANKING_ITEM_TYPES.MEDIAHOUSE);
  const {
    loading: loadingAdNetworks,
    error: hasAdNetworksRequestErroredOut,
    fetchRankingList: fetchAdNetworks,
    rankingItemsMatchingSearchString: adNetworks,
    subRankingItems: subAdNetworks
  } = useOewaRatingsRankingList(searchString, RANKING_ITEM_TYPES.AD_NETWORK);
  const { channels, fetchChannels, loadingChannels, hasChannelsRequestErroredOut } =
    useOewaChannels(searchString);

  const publicTabs = computed(() => [
    {
      name: translateOewaRatings(`unit-types>${RANKING_ITEM_TYPES.SERVICE}`, services.value?.length),
      type: RANKING_ITEM_TYPES.SERVICE,
      items: services.value,
      subItems: subServices.value,
      disabled: currentView.value === RATINGS_VIEWS.CHANNEL,
      fetcher: fetchServices,
      loading: loadingMediahouses.value || loadingServices.value,
      loadingMessage: translateOewaRatings(`tabs>${RANKING_ITEM_TYPES.SERVICE}>loading-msg`),
      error: hasServicesRequestErroredOut.value
    },
    {
      name: translateOewaRatings(`unit-types>${RANKING_ITEM_TYPES.MEDIAHOUSE}`, mediahouses.value?.length),
      type: RANKING_ITEM_TYPES.MEDIAHOUSE,
      items: mediahouses.value,
      subItems: subMediahouses.value,
      disabled: currentView.value === RATINGS_VIEWS.CHANNEL,
      default: currentView.value === RATINGS_VIEWS.MONTHLY || currentView.value === RATINGS_VIEWS.ZERVICE,
      fetcher: fetchMediahouses,
      loading: loadingMediahouses.value,
      loadingMessage: translateOewaRatings(`tabs>${RANKING_ITEM_TYPES.MEDIAHOUSE}>loading-msg`),
      error: hasMediahousesRequestErroredOut.value
    },
    {
      name: translateOewaRatings(`unit-types>${RANKING_ITEM_TYPES.AD_NETWORK}`, adNetworks.value?.length),
      type: RANKING_ITEM_TYPES.AD_NETWORK,
      items: adNetworks.value,
      subItems: subAdNetworks.value,
      disabled: currentView.value === RATINGS_VIEWS.CHANNEL,
      fetcher: fetchAdNetworks,
      loading: loadingMediahouses.value || loadingAdNetworks.value,
      loadingMessage: translateOewaRatings(`tabs>${RANKING_ITEM_TYPES.AD_NETWORK}>loading-msg`),
      error: hasAdNetworksRequestErroredOut.value
    }
  ]);

  const previewTabs = computed(() => [
    ...publicTabs.value,
    {
      name: translateOewaRatings(`unit-types>${RANKING_ITEM_TYPES.CHANNEL}`, channels.value?.length),
      type: RANKING_ITEM_TYPES.CHANNEL,
      items: channels.value,
      disabled: currentView.value === RATINGS_VIEWS.MONTHLY,
      default: currentView.value === RATINGS_VIEWS.CHANNEL,
      fetcher: fetchChannels,
      loading: loadingChannels.value,
      loadingMessage: translateOewaRatings(`tabs>${RANKING_ITEM_TYPES.CHANNEL}>loading-msg`),
      error: hasChannelsRequestErroredOut.value
    }
  ]);

  const publicExportList = computed(() => ({
    name: translateOewaRatings('export-list-as-csv'),
    items: publicTabs.value.map(({ type, items }) => ({
      name: translateOewaRatings(`unit-types>${type}`, items?.length),
      url: getOewaRatingsApiRoute({
        name: API_ROUTES.RANKING_LIST.EXPORT[route.meta.api],
        params: {
          type
        }
      })
    }))
  }));

  const previewExportList = computed(() => {
    const enabledTabs = previewTabs.value.filter(tab => !tab.disabled);

    return {
      name: translateOewaRatings('export-list-as-csv'),
      items: enabledTabs.map(({ type, items }) => ({
        name: translateOewaRatings(`unit-types>${type}`, items?.length),
        url: getOewaRatingsApiRoute({
          name: API_ROUTES.RANKING_LIST.EXPORT[route.meta.api],
          params: {
            type
          }
        })
      }))
    };
  });

  return {
    publicTabs,
    previewTabs,
    publicExportList,
    previewExportList
  };
};

export default useOewaRatings;
