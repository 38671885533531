import { store } from '../../main';

const MENUS = {
  list: [
    {
      items: [
        {
          name: 'ÖWA Kennzahlen (veröffentlicht)',
          url: { name: 'oewa.ratings' }
        },
        {
          name: 'ÖWA Kennzahlen intern (Vorschau)',
          url: { name: 'oewa.ratings.private' },
          modules: ['owa_admin', 'owa_dashboard']
        },
        {
          name: 'Belegungseinheiten verwalten',
          url: { name: 'oewa.channels' },
          modules: ['owa_admin', 'owa_mapping_tool']
        }
      ]
    }
  ]
};

export const RATINGS_VIEWS = {
  MONTHLY: Symbol('dashboardMontlyView'),
  ZERVICE: Symbol('dashboardZerviceView'),
  CHANNEL: Symbol('dashboardChannelView')
};

export const DEVICE_TYPES = {
  ALL: 'all',
  DESKTOP: 'desktop',
  MOBILE_WEB: 'mobile_web',
  MOBILE_APP: 'mobile_app',
  MOBILE: 'mobile',
  WEB: 'web'
};

export const RANKING_ITEM_TYPES = {
  SERVICE: 'service',
  MEDIAHOUSE: 'mediahouse',
  AD_NETWORK: 'adnetwork',
  CHANNEL: 'channel'
};

export const TABLE_COLUMN_KEYS = {
  NAME: 'name',
  NET_REACH: 'net_reach',
  UNIQUE_USERS: 'unique_users',
  UNIQUE_CLIENTS: 'unique_clients',
  VISITS: 'visits',
  IMPRESSIONS: 'impressions',
  IMPRESSIONS_AT: 'impressions_at',
  IMPRESSIONS_PER_VISITS: 'impressions_per_visits',
  USETIME: 'usetime'
};

export const FILTER_KEYS = {
  DATA_FRAME: 'data-frame',
  BOOKABLE: 'bookable'
};

export const PIXEL_PATH_FILTER_TYPES = {
  NOT_IN_USE: 'not_in_use',
  EXACT_MATCH: 'exact_match',
  PATTERN: 'pattern'
};

export const OEWA_DOMAIN = 'oewa.at';

export const OEWA_TYPES = {
  PUBLIC: 'public',
  PRIVATE: 'private'
};

export const API = {
  PUBLIC: Symbol('public'),
  PRIVATE: Symbol('private')
};

export const API_ROUTES = {
  RANKING_LIST: {
    [API.PUBLIC]: 'api.oewa.public.ranking.list',
    [API.PRIVATE]: 'api.oewa.ranking.list',

    FILTERS: {
      [API.PUBLIC]: 'api.oewa.public.ranking.filters',
      [API.PRIVATE]: 'api.oewa.ranking.filters'
    },
    EXPORT: {
      [API.PUBLIC]: 'api.oewa.public.ranking.list.export',
      [API.PRIVATE]: 'api.oewa.ranking.list.export'
    },
    DEVICE_AGGREGATIONS: {
      [API.PUBLIC]: 'api.oewa.public.ranking.list.device-aggregations',
      [API.PRIVATE]: 'api.oewa.ranking.list.device-aggregations'
    }
  },
  RANKING_ITEM: {
    [API.PUBLIC]: 'api.oewa.public.ranking.details',
    [API.PRIVATE]: 'api.oewa.ranking.details',

    FILTERS: {
      [API.PUBLIC]: 'api.oewa.public.ranking.details.filters',
      [API.PRIVATE]: 'api.oewa.ranking.details.filters'
    },
    APP_LOCALS: {
      [API.PUBLIC]: 'api.oewa.public.ranking.details.app-locals',
      [API.PRIVATE]: 'api.oewa.ranking.details.app-locals'
    },
    WEB_LOCALS: {
      [API.PUBLIC]: 'api.oewa.public.ranking.details.web-locals',
      [API.PRIVATE]: 'api.oewa.ranking.details.web-locals'
    },
    DEMOGRAPHICS_ATTRIBUTES: {
      [API.PUBLIC]: 'api.oewa.public.ranking.details.attributes',
      [API.PRIVATE]: 'api.oewa.ranking.details.attributes'
    },
    DEMOGRAPHICS_ATTRIBUTE: {
      [API.PUBLIC]: 'api.oewa.public.ranking.details.attributes.attribute',
      [API.PRIVATE]: 'api.oewa.ranking.details.attributes.attribute'
    },
    DEMOGRAPHICS_EXPORT: {
      [API.PUBLIC]: 'api.oewa.public.panel-attributes.export',
      [API.PRIVATE]: 'api.oewa.panel-attributes.export'
    },
    CHART: {
      [API.PUBLIC]: 'api.oewa.public.ranking.details.chart',
      [API.PRIVATE]: 'api.oewa.ranking.details.chart'
    },
    TABLE: {
      [API.PUBLIC]: 'api.oewa.public.ranking.details.visits-table',
      [API.PRIVATE]: 'api.oewa.ranking.details.visits-table'
    }
  }
};

export const DataVisibilityFlagsEnum = {
  WEB_PUBLISHED: 1,
  WEB_BLOCKED: 2,
  WEB_BLOCKED_TECHNICAL: 4,
  WEB_BLOCKED_CUSTOMER: 8,
  ZERVICE_PUBLISHED: 16,
  ZERVICE_BLOCKED: 32,
  IS_PRIVATE: 64
};

export const DeviceTypesEnum = {
  [DEVICE_TYPES.ALL]: 0,
  [DEVICE_TYPES.DESKTOP]: 1,
  [DEVICE_TYPES.WEB]: 2,
  [DEVICE_TYPES.MOBILE]: 3,
  [DEVICE_TYPES.MOBILE_WEB]: 4,
  [DEVICE_TYPES.MOBILE_APP]: 5
};

export const getMenu = (key, selectedItem) => {
  if (!key || !MENUS[key]) {
    return [];
  }

  return MENUS[key].map(record => {
    const newItems = record.items
      .map(item => {
        if (!!selectedItem && item.url?.name === selectedItem) {
          return {
            ...item,
            active: true
          };
        }

        return item;
      })
      .filter(item => {
        if (!item.module && !item.modules) {
          return true;
        }

        if (item.module && store.getters.currentUserModules[item.module]) {
          return true;
        }

        if (Array.isArray(item.modules)) {
          return item.modules.some(module => store.getters.currentUserModules[module]);
        }

        return false;
      });

    return {
      ...record,
      items: newItems
    };
  });
};
