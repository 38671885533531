import useAxios, { API_METHODS } from 'composables/useAxios';
import { getApiRoute } from 'routeconfig';

const useManageGroups = () => {
  const {
    state: { loading: fetchingAllGroups, error: fetchAllGroupsError, data: allGroups },
    fetcher: allGroupsFetcher
  } = useAxios({
    initialValue: []
  });
  const { fetcher: deleteFetcher } = useAxios();
  const {
    state: { loading: creatingOrUpdatingGroup },
    fetcher: createOrEditFetcher
  } = useAxios();
  const {
    state: { loading: fetchingGroup, data: group },
    fetcher: fetchGroupFetcher
  } = useAxios({
    initialValue: {}
  });

  const fetchGroups = () =>
    allGroupsFetcher({
      url: getApiRoute({
        name: 'api.admin.ratings.all',
        params: { type: 'group' }
      }),
      responseAdapter: ({ items }) => items ?? []
    });

  const fetchGroup = ({ id }) =>
    fetchGroupFetcher({
      url: getApiRoute({
        name: 'api.admin.ratings.group',
        params: {
          id: id
        }
      }),
      responseAdapter: ({ group }) => group
    });

  const createOrEditGroup = ({ id = null, name = null, country = null, services }) => {
    const routeName = `api.admin.ratings.group.${id ? 'update' : 'create'}`;

    const url = id
      ? getApiRoute({
          name: routeName,
          params: {
            id: id
          }
        })
      : getApiRoute({
          name: routeName
        });

    const requestBody = {
      services: services
    };

    if (name) {
      requestBody.name = name;
    }

    if (country) {
      requestBody.country = country;
    }

    return createOrEditFetcher({
      method: API_METHODS.POST,
      url: url,
      requestBody: requestBody
    });
  };

  const deleteGroup = ({ id }) =>
    deleteFetcher({
      method: API_METHODS.DELETE,
      url: getApiRoute({
        name: 'api.admin.ratings.group.delete',
        params: {
          id: id
        }
      })
    });
  return {
    fetchGroups,
    fetchingAllGroups,
    fetchAllGroupsError,
    allGroups,
    deleteGroup,
    createOrEditGroup,
    creatingOrUpdatingGroup,
    fetchGroup,
    fetchingGroup,
    group
  };
};

export default useManageGroups;
