export const adeffxAdministration = [
  {
    path: 'adeffx',
    component: {
      render: () => <RouterView />
    },
    children: [
      {
        path: 'manage-flights',
        name: 'admin.adeffx.flights',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '../../../views/pages/management/adeffx/AdeffxFlightsManagement.vue'
          ),
        meta: {
          auth: true,
          hasCountrySwitcher: true,
          module: 'adeffx_admin'
        }
      }
    ]
  }
];
