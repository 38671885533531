<template>
  <div id="app" :key="$route.fullPath">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Application'
};
</script>
