import useAxios, { API_METHODS } from 'composables/useAxios';
import { getApiRoute } from 'routeconfig';
import { buildFormData } from 'helper';

const useManageServices = () => {
  const { fetcher: deleteFetcher } = useAxios();
  const {
    state: { data: streamingTypes },
    fetcher: streamingTypesFetcher
  } = useAxios({
    initialValue: []
  });
  const {
    state: { loading: creatingService },
    fetcher: createServiceFetcher
  } = useAxios();
  const {
    state: { loading: updatingService },
    fetcher: updateServiceFetcher
  } = useAxios();

  const {
    state: { loading: fetchServicesLoading, error: fetchServicesError, data: services },
    fetcher: findAllServicesFetcher
  } = useAxios({
    initialValue: []
  });

  const {
    state: { loading: serviceDetailsLoading, data: service },
    fetcher: detailsFetcher
  } = useAxios({
    initialValue: {}
  });

  const fetchServices = ({ excludeOewaServices = false } = {}) =>
    findAllServicesFetcher({
      url: getApiRoute({
        name: 'api.admin.ratings.all',
        params: { type: 'service' },
        query: {
          ...(excludeOewaServices ? { owa: 0 } : {})
        }
      }),
      responseAdapter: ({ items }) => items ?? []
    });

  const deleteServiceById = id =>
    deleteFetcher({
      method: API_METHODS.DELETE,
      url: getApiRoute({
        name: 'api.admin.ratings.services.delete',
        params: {
          id: id
        }
      })
    });

  const getServiceDetails = ({ id }) =>
    detailsFetcher({
      url: getApiRoute({
        name: 'api.admin.ratings.services.detail',
        params: {
          id: id
        }
      }),
      responseAdapter: ({ service }) => {
        service.use_company_url = !!service.use_company_url;
        service.owner_only = !!service.owner_only;
        service.use_company_description = !!service.use_company_description;
        service.use_company_logo = !!service.use_company_logo;
        service.international = !!service.international;
        service.bookable = !!service.bookable;
        service.data_checked = !!service.data_checked;
        service.paid_ranking_list = !!service.paid_ranking_list;

        return service;
      }
    });

  const getStreamingTypes = () =>
    streamingTypesFetcher({
      url: getApiRoute({
        name: 'api.streaming.types'
      }),
      responseAdapter: ({ types }) => types ?? []
    });

  const createService = ({ name, company_id, private_customers, owner_only, country }) =>
    createServiceFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: 'api.admin.ratings.item.create',
        params: {
          type: 'service'
        }
      }),
      requestBody: {
        name,
        company_id,
        private_customers,
        owner_only,
        country
      }
    });

  const updateService = ({ id, formattedData }) =>
    updateServiceFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: 'api.admin.ratings.service.update',
        params: {
          id
        }
      }),
      requestBody: buildFormData(formattedData)
    });

  return {
    fetchServices,
    fetchServicesLoading,
    fetchServicesError,
    services,
    deleteServiceById,
    getServiceDetails,
    serviceDetailsLoading,
    service,
    getStreamingTypes,
    streamingTypes,
    createService,
    creatingService,
    updateService,
    updatingService
  };
};

export default useManageServices;
