import useAxios, { API_METHODS } from 'composables/useAxios';
import { getApiRoute } from 'routeconfig';

const useOewaManageAdNetworks = () => {
  const {
    state: { loading: findAllLoading, error: findAllError, data: adNetworks },
    fetcher: findAllFetcher
  } = useAxios({
    initialValue: []
  });
  const {
    state: { loading: findLoading, error: findError, data: adNetwork },
    fetcher: findFetcher
  } = useAxios();
  const {
    state: { loading: creating },
    fetcher: createFetcher
  } = useAxios();
  const {
    state: { loading: updating },
    fetcher: updateFetcher
  } = useAxios();
  const {
    state: { loading: removing },
    fetcher: removeFetcher
  } = useAxios();

  const findAll = () =>
    findAllFetcher({
      url: getApiRoute('api.oewa.adnetworks')
    });

  const find = id =>
    findFetcher({
      url: getApiRoute({
        name: 'api.oewa.adnetwork',
        params: {
          id
        }
      })
    });

  const create = newAdNetwork =>
    createFetcher({
      method: API_METHODS.POST,
      url: getApiRoute('api.oewa.adnetwork'),
      requestBody: newAdNetwork
    });

  const update = ({ id, payload }) =>
    updateFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: 'api.oewa.adnetwork',
        params: {
          id
        }
      }),
      requestBody: payload
    });

  const remove = id =>
    removeFetcher({
      method: API_METHODS.DELETE,
      url: getApiRoute({
        name: 'api.oewa.adnetwork',
        params: {
          id
        }
      })
    });

  return {
    findAllLoading,
    findAllError,
    adNetworks,
    findAll,
    findLoading,
    findError,
    adNetwork,
    find,
    create,
    creating,
    update,
    updating,
    remove,
    removing
  };
};

export default useOewaManageAdNetworks;
