import { computed } from 'vue';
import { useAxios } from 'composables';
import { getApiRoute } from 'routes/config';
import { getRatingsQueryParams } from 'views/ratings/helpers';
import { TRAFFIC_SOURCE } from 'views/ratings/RatingsConstants';

export const useRankingItem = () => {
  const {
    state: { loading, error: rankingItemError, data: rankingItem },
    fetcher
  } = useAxios();

  const rankingItemLoading = computed(() => loading.value || !rankingItem.value);

  const fetchRankingItem = ({ id, type, query }) =>
    fetcher({
      url: getApiRoute({
        name: 'api.ratings.details',
        params: {
          id,
          type
        },
        query: query || getRatingsQueryParams()
      }),
      responseAdapter: ({ data: rankingItem }) => ({
        ...rankingItem,
        isOewaService: rankingItem.traffic_source === TRAFFIC_SOURCE.OWA
      })
    });

  return {
    rankingItemLoading,
    rankingItemError,
    rankingItem,
    fetchRankingItem
  };
};
