export const contractAdministrationRoutes = [
  {
    path: 'customer',
    component: {
      render: () => <RouterView />
    },
    children: [
      {
        path: 'contracts',
        name: 'customer.contracts',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../../../views/pages/management/CustomerContracts.vue'),
        meta: { auth: true, hasCountrySwitcher: true, module: 'super_admin' }
      },
      {
        path: 'contract/create',
        name: 'customer.contract.create',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '../../../views/pages/management/CustomerContractCreate.vue'
          ),
        meta: { auth: true, hasCountrySwitcher: true, module: 'super_admin' }
      },
      {
        path: ':id/contract/:contract_id/items/create',
        name: 'customer.contract.items.create',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '../../../views/pages/management/CustomerContractItemsCreate.vue'
          ),
        meta: { auth: true, hasCountrySwitcher: true, module: 'super_admin' }
      }
    ]
  }
];
