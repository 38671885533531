<script setup>
const year = new Date().getFullYear();
</script>

<template>
  <div class="o-grid u-height-100 c-login u-flex u-flex--column">
    <main class="u-fluid u-flex u-flex--justify-center u-flex--middle">
      <slot></slot>
    </main>

    <footer class="o-type-12 u-color-text-secondary u-p-x4 u-text-center">
      &copy; {{ year }} Reppublika. All rights reserved.
    </footer>
  </div>
</template>
