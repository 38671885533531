import { computed } from 'vue';
import { appliedFilters } from 'composables/oewa/ratings/useOewaRatingsFilters';
import { INTERVAL_TYPES } from 'utils/constants';
import { FILTER_KEYS, RATINGS_VIEWS } from 'views/oewa/OewaConstants';

const useOewaRatingsView = () => {
  const currentView = computed(() => {
    const { interval_type, calculation_type } = appliedFilters?.value?.[FILTER_KEYS.DATA_FRAME] || {};

    if (!interval_type || interval_type === INTERVAL_TYPES.MONTH) {
      return RATINGS_VIEWS.MONTHLY;
    }

    if (!calculation_type) {
      return RATINGS_VIEWS.CHANNEL;
    }

    return RATINGS_VIEWS.ZERVICE;
  });

  return {
    currentView
  };
};

export default useOewaRatingsView;
