<template>
  <footer class="c-page">
    <div class="o-container u-text-center">
      <div class="o-grid u-text-left">
        <div class="o-grid__col u-6/24 u-24/24@600">
          <img :src="`${ASSET_PATH}img/ReppublikaColor.svg`" class="c-logo--small u-inline-block" /><br />
          <span class="u-inline-block u-mt"> &copy; {{ year }} Reppublika. All rights reserved.</span>
        </div>

        <div class="o-grid__col u-6/24 u-push-2/24 u-24/24@600 u-push-0@600 u-pv-x6@600">
          <h2 class="u-weight-300 o-type-18 u-text-strong">About</h2>
          <p class="u-mt-x4 u-mt-x3@800">
            <a class="c-link--secondary" href="mailto:contact@reppublika.com">contact@reppublika.com</a>
            <br />
            Tel: <a class="c-link--secondary" href="tel:+4312288810">+43 1 228 88 10</a><br />
            Karlsgasse 7/5<br />
            1040 Vienna<br />
            Austria<br />
            <a class="c-link" href="http://research.mindtake.com/imprint" target="_blank">Imprint</a>
          </p>
        </div>

        <div class="o-grid__col u-8/24 u-24/24@600 u-push-0@600">
          <h2 class="u-weight-300 o-type-18 u-text-strong">Social Media</h2>
          <p class="u-mt-x4 u-mt-x3@800">
            <a href="https://www.facebook.com/reppublika" class="u-inline-block u-mr-x2" target="_blank">
              <img :src="`${ASSET_PATH}img/icon-social-facebook.svg`" class="u-inline-block" />
            </a>

            <a
              href="https://www.linkedin.com/company/reppublika"
              target="_blank"
              class="u-inline-block u-mr-x2"
            >
              <img :src="`${ASSET_PATH}img/icon-social-linkedin.svg`" class="u-inline-block" />
            </a>

            <a
              href="https://www.xing.com/xbp/pages/reppublika"
              class="u-inline-block u-mr-x2"
              target="_blank"
            >
              <img :src="`${ASSET_PATH}img/icon-social-xing.svg`" class="u-inline-block" />
            </a>

            <a href="https://twitter.com/reppublika_" class="u-inline-block" target="_blank">
              <img :src="`${ASSET_PATH}img/icon-social-twitter.svg`" class="u-inline-block" />
            </a>
          </p>
        </div>
      </div>
      <img
        :src="`${ASSET_PATH}img/reppublika-logo-transparent.svg`"
        class="c-logo--footer-huge u-inline-block"
      />
    </div>
  </footer>
</template>

<script>
import { ASSET_PATH } from 'utils/constants.js';

export default {
  setup() {
    return {
      ASSET_PATH
    };
  },
  computed: {
    year() {
      return new Date().getFullYear();
    }
  }
};
</script>
