import { Action, Mutation } from '../types';
import { getApiRoute } from 'routeconfig';
import { errorlog, handleCallback } from 'helper';
import axios from 'axios';

export default {
  [Action.GET_PUBLISHERS]: ({ commit }, payload = {}) => {
    const url = getApiRoute('api.publishers');
    return axios
      .get(url)
      .then(({ data }) => {
        commit(Mutation.STORE_PUBLISHERS, data);
        handleCallback(payload.callback);
      })
      .catch(errorlog);
  },
  [Action.GET_CURRENT_PUBLISHER]: ({ commit }, payload = {}) => {
    const url = getApiRoute({ name: 'api.publisher.details', params: payload.params });
    return axios
      .get(url)
      .then(({ data }) => {
        commit(Mutation.STORE_CURRENT_PUBLISHER, data);
        handleCallback(payload.callback);
      })
      .catch(errorlog);
  },
  [Action.GET_CURRENT_PUBLISHER_INFO]: ({ commit }, payload = {}) => {
    const url = getApiRoute({ name: 'api.publisher.info', params: payload.params });
    return axios
      .get(url)
      .then(({ data }) => {
        commit(Mutation.STORE_CURRENT_PUBLISHER_INFO, data);
        handleCallback(payload.callback);
      })
      .catch(errorlog);
  },
  [Action.GET_CURRENT_PUBLISHER_CHART]: (
    { commit },
    { params = {}, query = {}, type = 'webInsights', callback }
  ) => {
    const url = getApiRoute({ name: 'api.publisher.reach.chart', params, query });
    return axios
      .get(url)
      .then(({ data }) => {
        commit(Mutation.STORE_CURRENT_PUBLISHER_CHART, { [type]: data });
        handleCallback(callback);
      })
      .catch(errorlog);
  },
  [Action.GET_CURRENT_PUBLISHER_INSIGHTS]: (
    { commit },
    { params = {}, query = {}, type = 'webInsights', callback }
  ) => {
    const url = getApiRoute({ name: 'api.publisher.insights', params, query });
    return axios
      .get(url)
      .then(({ data }) => {
        commit(Mutation.STORE_CURRENT_PUBLISHER_INSIGHTS, { [type]: data });
        handleCallback(callback);
      })
      .catch(errorlog);
  }
};
