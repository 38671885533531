export const i18nRoutes = [
  {
    path: 'i18n',
    component: {
      render: () => <RouterView />
    },
    children: [
      {
        path: 'translation',
        name: 'admin.i18n.translations',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '../../../views/pages/management/i18n/TranslationsManagement.vue'
          ),
        meta: { auth: true, hasCountrySwitcher: true, module: 'super_admin' }
      },
      {
        path: 'locales',
        name: 'admin.i18n.locales',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '../../../views/pages/management/i18n/LocalesManagement.vue'
          ),
        meta: { auth: true, hasCountrySwitcher: true, module: 'super_admin' }
      },
      {
        path: 'tags',
        name: 'admin.i18n.tags',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../../../views/pages/management/i18n/TagsManagement.vue'),
        meta: { auth: true, hasCountrySwitcher: true, module: 'super_admin' }
      }
    ]
  }
];
