import _Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import More from 'highcharts/highcharts-more';
import OfflineExport from 'highcharts/modules/offline-exporting';
import Annotations from 'highcharts/modules/annotations';
import SolidGauge from 'highcharts/modules/solid-gauge';
import {RChart, chartColorDuo, chartColorsMono} from '../utils/helpers.js';
import {countBy, groupBy, defaultsDeep} from 'lodash';

// Initialize exporting module for highcharts
window.Highcharts = _Highcharts;
window.RChart = RChart;
Exporting(window.Highcharts);
More(window.Highcharts);
OfflineExport(window.Highcharts);
Annotations(window.Highcharts);
SolidGauge(window.Highcharts);

window.MChart = {
  convertData(data, type, options) {
    if (this.isSingle(type)) {
        
      if (type === 'pie') {
        return [{name: options.name, data}];
      }
      
      // Any other single type convert:
      // from: {name: 'Name', data: [{name: 'Legend 1', y: '31'}]
      // to:   [{ name: 'Legend 1', data: [31] }, {...}]
      return data.map(item => {
        return { name: item.name, data: [{y: item.y, formatted: item.formatted || item.y, name: item.name}] };
      })
      
    } else {
      // Group by name
      const groupedData = groupBy(data, i => i.name);
      return Object.keys(groupedData).map(name => {
        if (groupedData[name].length > 1) {
          return {
            name: name,
            data: groupedData[name]
          }
        }
        
        return groupedData[name][0]
      })
    }
  },
  extractCategoriesFrom(data) {
    return data.map((item, index) => {
      return typeof item.name !== 'undefined' ? item.name : index
    })
  },
  isSingle(type) {
    return ['pie', 'bar', 'column'].indexOf(type) > -1
  },
  isMulti(type) {
    return ['line', 'area'].indexOf(type) > -1
  },
  generate(options) {
    //Supported types
    // line, area, pie, bar, column, scatter
    
    // Examples
    // Pie, bar, column
    // [{"name":"Brands","data":[{name: 'Brand 1', y:263.90383,"percentage":"33.11%","active":false}, {name: 'Brand 2', "y":153.45815,"percentage":"19.25%"}],
    // [{"name":"3 people","data":[{"y":153.45815,"percentage":"19.25%"}],
    
    //Area/Line
    // [{name: '2 people', data: [31231,30321]}]
    
    // Scatter 
    // [{
    //     name: 'Female',
    //     color: 'rgba(223, 83, 83, .5)',
    //     data: [[161.2, 51.6]]
      
    // }]
    
    if (options.data.length) {
      const uniqueData = countBy(countBy(options.data, i => i.name), i => i);

      if (Object.keys(uniqueData).length === 1) {
        if (!this.isSingle(options.type)) {
          console.error(`Type: '${options.type}' is not supported for the given data. We fallback to bar`);
          options.type = 'bar';
        }
      } else {
        if (!this.isMulti(options.type)) {
          console.error(`Type: '${options.type}' is not supported for the given data. We fallback to line`);
          options.type = 'line';
        }
      }
      
      // Convert the data for the final chart
      options.data = this.convertData(options.data, options.type, options);
      
      // Set categories for needed charts
      //const xCategories = options.type === 'bar' ? this.extractCategoriesFrom(options.data) : undefined;
      
      
      // Colors
      let colors;
      if (options.colors) {
        colors = options.colors.toLowerCase().trim();
        if (colors === 'duo') {
          colors = chartColorDuo;
        } else if (colors === 'mono') {
          colors = chartColorsMono;
        }
      }
      
      const config = defaultsDeep(options.highchartOptions, RChart.options({
        chart: {
          type: options.type
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: !options.legend
            },
            showInLegend: !!options.legend
          }
        },
        colors: colors || RChart.defaults.colors,
        series: options.data,
      }));

      window.Highcharts.chart(options.target, config);
    }
  }
}