import useAxios, { API_METHODS } from 'composables/useAxios';
import { getApiRoute } from 'routeconfig';
import { buildFormData } from 'helper';

export const useManageCompanies = () => {
  const {
    state: { loading: findAllLoading, error: findAllError, data: companies },
    fetcher: findAllFetcher
  } = useAxios({
    initialValue: []
  });
  const {
    state: { loading: removing },
    fetcher: removeFetcher
  } = useAxios();
  const {
    state: { loading: findLoading, error: findError, data: company },
    fetcher: findFetcher
  } = useAxios();
  const {
    state: { loading: updating },
    fetcher: updateFetcher
  } = useAxios();
  const {
    state: { loading: creating },
    fetcher: createFetcher
  } = useAxios();

  const findAll = () =>
    findAllFetcher({
      url: getApiRoute('api.ratings.companies'),
      responseAdapter: ({ companies }) => companies || []
    });

  const create = payload =>
    createFetcher({
      method: API_METHODS.POST,
      url: getApiRoute('api.admin.ratings.companies.create'),
      requestBody: buildFormData(payload)
    });

  const remove = id =>
    removeFetcher({
      method: API_METHODS.DELETE,
      url: getApiRoute({
        name: 'api.admin.ratings.companies.delete',
        params: {
          id
        }
      })
    });

  const find = id =>
    findFetcher({
      url: getApiRoute({
        name: 'api.admin.ratings.companies.details',
        params: {
          id
        }
      }),
      responseAdapter: ({ company }) => {
        if (!company) {
          return {};
        }

        let returnObj = { ...company };
        const booleanFields = ['data_checked', 'status', 'international'];

        // The checkbox component will be sad if values won't be booleans #REPFE-2882.
        booleanFields.forEach(booleanField => {
          returnObj[booleanField] = !!company[booleanField];
        });

        // Set country_id as '', if the value from API is empty #REPFE-2882.
        returnObj.country_id = !!company.country_id && company.country_id !== 0 ? company.country_id : '';

        // `logo` - a string value that represents the location to the image.
        // `logo_file` - a placeholder for a file / binary value when submitting form #REPFE-2882.
        returnObj.logo_file = '';

        return returnObj;
      }
    });

  const update = ({ id, payload }) =>
    updateFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: 'api.admin.ratings.companies.update',
        params: {
          id
        }
      }),
      requestBody: buildFormData(payload)
    });

  return {
    findAllLoading,
    findAllError,
    companies,
    findAll,
    findLoading,
    findError,
    company,
    findFetcher,
    find,
    updating,
    update,
    removing,
    remove,
    creating,
    create
  };
};
