import useAxios, { API_METHODS } from 'composables/useAxios';
import { getApiRoute } from 'routeconfig';

const useManageUsers = () => {
  const {
    state: { loading: findUsersLoading, error: findUsersError, data: users },
    fetcher: findAllUsersFetcher
  } = useAxios({
    initialValue: []
  });
  const { fetcher: deleteFetcher } = useAxios();
  const { fetcher: restoreFetcher } = useAxios();
  const { fetcher: statusFetcher } = useAxios();
  const { fetcher: impersonateFetcher } = useAxios();
  const { fetcher: depersonateFetcher } = useAxios();

  const findUsers = customerId =>
    findAllUsersFetcher({
      url: getApiRoute({ name: 'api.customer.users', query: { customer: customerId } }),
      responseAdapter: ({ users }) => {
        return users;
      }
    });

  const deleteUserById = id =>
    deleteFetcher({
      method: API_METHODS.DELETE,
      url: getApiRoute({
        name: 'api.customer.user.delete',
        params: {
          userID: id
        }
      })
    });

  const restoreUser = id =>
    restoreFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: 'api.customer.user.restore',
        params: {
          user_id: id
        }
      })
    });

  const updateUserStatus = ({ userId, newStatus }) =>
    statusFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: 'api.customer.user.change-active',
        params: {
          userID: userId
        }
      }),
      requestBody: {
        active: newStatus
      }
    });

  const impersonateUserById = userId =>
    impersonateFetcher({
      method: API_METHODS.POST,
      url: getApiRoute('api.user.impersonate'),
      requestBody: { id: userId }
    });

  const depersonateUserById = payload =>
    depersonateFetcher({
      method: API_METHODS.POST,
      url: getApiRoute('api.user.depersonate'),
      requestBody: payload
    });

  return {
    findUsersLoading,
    findUsersError,
    users,
    findUsers,
    deleteUserById,
    restoreUser,
    updateUserStatus,
    impersonateUserById,
    depersonateUserById
  };
};

export default useManageUsers;
