export const Action = {
  GET_COMPARE_BEFORE_CATEGORIES: 'GET_COMPARE_BEFORE_CATEGORIES',
  GET_COMPARE_AFTER_CATEGORIES: 'GET_COMPARE_AFTER_CATEGORIES',
  GET_COMPARE_TRAFFIC_DATA: 'GET_COMPARE_TRAFFIC_DATA',
  GET_COMPARE_AUDIENCE_ATTRIBUTES: 'GET_COMPARE_AUDIENCE_ATTRIBUTES'
};
export const Mutation = {
  STORE_COMPARE_BEFORE_CATEGORIES: 'STORE_COMPARE_BEFORE_CATEGORIES',
  STORE_COMPARE_DATA_BEFORE: 'STORE_COMPARE_DATA_BEFORE',
  STORE_COMPARE_AFTER_CATEGORIES: 'STORE_COMPARE_AFTER_CATEGORIES',
  STORE_COMPARE_DATA_AFTER: 'STORE_COMPARE_DATA_AFTER',
  STORE_COMPARE_TRAFFIC_DATA: 'STORE_COMPARE_TRAFFIC_DATA',
  STORE_COMPARE_AUDIENCE_ATTRIBUTES: 'STORE_COMPARE_AUDIENCE_ATTRIBUTES',
  STORE_COMPARE_AUDIENCE_ATTRIBUTES_DATA: 'STORE_COMPARE_AUDIENCE_ATTRIBUTES_DATA'
};
