import { Message } from 'reppublika_components';
import axios from 'axios';
import { computed, ref, watch } from 'vue';
import { getApiRoute } from 'routeconfig';
import { useAxios, useDebouncedSearch } from 'composables';
import { debounce } from 'helper';

const DEFAULT_SELECTED_PIXEL_PATHS = [];

let axiosCancelTokenSource = axios.CancelToken.source();

const useOewaPixelPaths = (selectedTrackingIdentifiers, selectedDeviceType) => {
  const selectedPixelPaths = ref(DEFAULT_SELECTED_PIXEL_PATHS);
  const isFetchPixelPathsRequestPending = ref(false);
  const { searchString: pixelPathSearchPattern, debouncedSearchString: debouncedPixelPathSearchPattern } =
    useDebouncedSearch();
  const { searchString: pixelPathSearchString, debouncedSearchString: debouncedPixelPathSearchString } =
    useDebouncedSearch();
  const {
    state: { data, loading: loadingPixelPaths },
    fetcher,
    resetState
  } = useAxios({
    initialValue: []
  });

  const allPixelPaths = computed(() =>
    data.value.map(({ pixel_path, tracking_identifier, ...rest }) => ({
      ...rest,
      pixel_path,
      tracking_identifier,
      key: `${pixel_path}_${tracking_identifier}`
    }))
  );

  const selectedRowKeys = computed(() => selectedPixelPaths.value.map(({ key }) => key));

  const pixelPathsRowSelection = computed(() => ({
    onChange: (_, newSelectedPixelPaths) => {
      selectedPixelPaths.value = newSelectedPixelPaths;
    },
    selectedRowKeys: selectedRowKeys.value,
    onSelectAll: (_, newSelectedRowKeys) => {
      selectedPixelPaths.value = newSelectedRowKeys;
    }
  }));

  const pixelPathsMatchingSearchPattern = computed(() => {
    if (!debouncedPixelPathSearchPattern.value) {
      return allPixelPaths.value;
    }

    const prefix = debouncedPixelPathSearchPattern.value.startsWith('*') ? '' : '^';
    const affix = debouncedPixelPathSearchPattern.value.endsWith('*') ? '' : '$';

    // when .replace() is provided a string, it only replaces the first occurence
    // this can be worked around by providing a regex with 'g' for global
    // lastest browsers also provide a .replaceAll function. regex is still fine to use and backwardscompatible.
    const regex = new RegExp(
      `${prefix}${debouncedPixelPathSearchPattern.value.replace(/\*/g, '.*')}${affix}`,
      'i'
    );

    return allPixelPaths.value.filter(({ pixel_path }) => regex.test(pixel_path));
  });

  const pixelPathsMatchingSearchString = computed(() => {
    if (!debouncedPixelPathSearchString.value) {
      return allPixelPaths.value;
    }

    return allPixelPaths.value.filter(({ pixel_path }) =>
      pixel_path?.toLowerCase()?.includes(debouncedPixelPathSearchString.value.toLowerCase())
    );
  });

  const fetchPixelPathsAPIRoute = computed(() =>
    getApiRoute({
      name: 'api.oewa.tracking-identifiers.aggregations',
      query: {
        'tracking_identifiers[]': selectedTrackingIdentifiers.value,
        device_type: selectedDeviceType.value
      }
    })
  );

  watch(selectedTrackingIdentifiers, newSelectedTrackingIdentifiers => {
    const isEmpty = !newSelectedTrackingIdentifiers.length;

    // if the user clears the dropdown, the new value will be an empty array
    // in that case, we reset the pixel paths to hide the configuration tabs
    if (isEmpty) {
      return reset();
    }

    isFetchPixelPathsRequestPending.value = true;

    fetchPixelPaths();
  });

  const fetchPixelPaths = debounce(() => {
    isFetchPixelPathsRequestPending.value = false;

    // if the value evaluates to truthy, it implies that there's an on-going request
    if (loadingPixelPaths.value) {
      axiosCancelTokenSource.cancel();
      axiosCancelTokenSource = axios.CancelToken.source();
    }

    return fetcher({
      url: fetchPixelPathsAPIRoute.value,
      cancelToken: axiosCancelTokenSource.token
    }).catch(() => {
      Message.error('Something went wrong while loading pixel paths. Please try again later.');
    });
  }, 500);

  const resetSelectedPixelPaths = () => {
    selectedPixelPaths.value = DEFAULT_SELECTED_PIXEL_PATHS;
  };

  const resetSearchInputs = () => {
    pixelPathSearchPattern.value = '';
    pixelPathSearchString.value = '';
  };

  const reset = () => {
    resetState();
    resetSelectedPixelPaths();
    resetSearchInputs();
  };

  return {
    allPixelPaths,
    loadingPixelPaths,
    fetchPixelPaths,
    selectedPixelPaths,
    pixelPathSearchPattern,
    pixelPathSearchString,
    pixelPathsMatchingSearchPattern,
    pixelPathsMatchingSearchString,
    pixelPathsRowSelection,
    isFetchPixelPathsRequestPending
  };
};

export default useOewaPixelPaths;
