import { Action, Mutation } from '../types';
import axios from 'axios';
import { errorlog, handleCallback } from 'helper';
import { getApiRoute } from 'routeconfig';

export default {
  //  #region Users
  [Action.UPDATE_USERDATA]: ({ commit }, payload = {}) => {
    const url = !!payload.params
      ? getApiRoute({ name: `api.customer.user.${payload.type}`, params: payload.params })
      : getApiRoute({ name: `api.customer.user.${payload.type}` });
    return axios
      .get(url)
      .then(({ data }) => {
        commit(`${Mutation.STORE_USERDATA}`, data);
        handleCallback(payload.callback);
      })
      .catch(errorlog);
  },
  //  #endregion
  //  #region Connected Customers
  [Action.UPDATE_CONNECTABLE_CUSTOMERS]: ({ commit }, payload = {}) => {
    return axios
      .get(
        getApiRoute({
          name: 'api.customer.connected-customers',
          params: { id: payload.selected_customer_id }
        })
      )
      .then(({ data }) => {
        commit(`${Mutation.STORE_CONNECTABLE_CUSTOMERS}`, data.customers_can_be_connected);
        handleCallback(payload.callback);
        return data.selected_customer;
      })
      .catch(errorlog);
  },
  [Action.ADD_NEW_CONNECTION]: (_, payload = {}) => {
    return axios
      .post(
        getApiRoute({
          name: 'api.customer.connected-customer.store',
          params: { id: payload.selected_customer_id }
        }),
        { customer: payload.newConnected }
      )
      .then(() => {
        handleCallback(payload.callback);
      })
      .catch(errorlog);
  },
  [Action.DELETE_CONNECTION]: (_, payload = {}) => {
    return axios
      .delete(
        getApiRoute({
          name: 'api.customer.connected-customer.delete',
          params: { id: payload.id }
        })
      )
      .catch(errorlog);
  }
  //  #endregion
};
