import { useAxios } from 'composables';
import { getApiRoute } from 'routeconfig';

const useOewaAllTimeframes = () => {
  const {
    state: { loading: loadingAllTimeframes },
    fetcher
  } = useAxios();

  function fetchAllTimeframes() {
    const fetchAllTimeframesAPIRoute = getApiRoute('api.oewa.channels.available-quarters');

    return fetcher({
      url: fetchAllTimeframesAPIRoute
    });
  }

  return {
    loadingAllTimeframes,
    fetchAllTimeframes
  };
};

export default useOewaAllTimeframes;
