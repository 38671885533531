import * as jquery from 'jquery';
window.$ = jquery;
window.jQuery = jquery;
export const $ = jquery;

import dropdown from './components/dropdown';
jquery.fn.customDropdown = dropdown(jquery);

export { default as Modal } from './components/modal';
export { default as Collapser } from './components/collapser';
export { default as Sorting } from './components/sorting';
export { default as Tooltip } from './components/tooltip';
export { default as Sticky } from './components/sticky';
export { default as Search } from './components/search';
export const laroute = {};
