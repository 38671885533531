import axios from 'axios';
import { Message } from 'reppublika_components';
import { errorlog, handleCallback, applyDefaultFilterValuesBasedOnQueryParams } from 'helper';
import router, { getApiRoute } from 'routeconfig';
import { Action, Mutation } from '../types';
import { Mutation as GlobalMutation } from '../../_global/types';

export default {
  [Action.FETCH_CONNECTED_SEARCH_TERMS]: ({ commit }, payload = {}) => {
    const url = getApiRoute({
      name: 'api.keywords.search-terms-distribution'
    });

    return axios
      .post(url, payload)
      .then(({ data }) => {
        const { success, message, graph } = data;
        if (success) {
          if (graph) {
            commit(`${Mutation.STORE_CONNECTED_SEARCH_TERMS}`, graph);
            handleCallback(payload.callback);
          }
        } else {
          commit(`${Mutation.STORE_CONNECTED_SEARCH_TERMS}`, { success, message });
        }
      })
      .catch(errorlog);
  },
  [Action.FETCH_SEARCH_ENGINE_DISTRIBUTION]: ({ commit }, payload = {}) => {
    const url = getApiRoute({
      name: 'api.keywords.search-engine-distribution'
    });

    return axios
      .post(url, payload)
      .then(({ data }) => {
        const { success, message, graph } = data;
        if (success) {
          if (graph) {
            commit(`${Mutation.STORE_SEARCH_ENGINE_DISTRIBUTION}`, graph);
            handleCallback(payload.callback);
          }
        } else {
          commit(`${Mutation.STORE_SEARCH_ENGINE_DISTRIBUTION}`, { success, message });
        }
      })
      .catch(errorlog);
  },
  [Action.FETCH_PAGE_FILTERS]({ commit }) {
    const fetchAllKeywordsFiltersRoute = getApiRoute('api.keywords.filters');

    commit(GlobalMutation.SET_PAGE_FILTERS_LOADING, true, { root: true });

    return axios
      .get(fetchAllKeywordsFiltersRoute)
      .then(({ data }) => {
        if (data?.filters) {
          const dateFilter = data.filters.find(filter => filter.general_name === 'date');

          if (!!dateFilter?.value?.fromDate) {
            commit(Mutation.SET_DEFAULT_FILTER_DATE, dateFilter.value.fromDate);
          }

          const newFilters = applyDefaultFilterValuesBasedOnQueryParams({
            filters: data.filters,
            queryParams: router.currentRoute.value.query
          });

          commit(GlobalMutation.SET_PAGE_FILTERS, newFilters, { root: true });
        }
      })
      .catch(error => {
        const errorMessage = error.response?.data || error.message;

        console.error('Error while fetching filters for KEYWRORDS module: ', errorMessage);

        Message.error('Something went wrong while loading filters. Please try again later.');

        commit(GlobalMutation.RESET_PAGE_FILTERS, null, { root: true });
      })
      .finally(() => {
        commit(GlobalMutation.SET_PAGE_FILTERS_LOADING, false, { root: true });
      });
  }
};
