import useAxios from '../../useAxios';
import { getApiRoute } from 'routeconfig';

const useOewaActivateChannel = () => {
  const {
    state: { loading: activating },
    fetcher
  } = useAxios();

  function activateChannel(id, { year, quarter }) {
    const activateChannelAPIRoute = getApiRoute({
      name: 'api.oewa.channel.activate',
      params: { id },
      query: { year, quarter }
    });

    return fetcher({
      method: 'put',
      url: activateChannelAPIRoute
    });
  }

  return {
    activating,
    activateChannel
  };
};

export default useOewaActivateChannel;
