import Login from '../../views/pages/Login.vue';
import Logout from '../../views/pages/Logout.vue';
import OAuth from '../../views/pages/OAuth.vue';

const validateQueryEmail = (to, next) => {
  if (!to.query.email) {
    next({ name: 'login' });
  } else {
    next();
  }
};

export const authRoutes = [
  {
    path: '/',
    component: {
      render: () => <RouterView />
    },
    children: [
      {
        path: 'login',
        name: 'login',
        component: Login
      },
      {
        path: 'logout',
        name: 'logout',
        component: Logout
      },
      {
        path: 'oauth/authorize',
        name: 'oauth.authorization',
        component: OAuth,
        meta: { auth: true }
      },
      {
        path: 'password',
        component: {
          render: () => <RouterView />
        },
        children: [
          {
            path: 'email',
            name: 'password.email',
            component: () => import(/* webpackChunkName: "public" */ '../../views/pages/PasswordEmail.vue')
          },
          {
            path: 'reset/:token',
            name: 'password.reset',
            component: () => import(/* webpackChunkName: "public" */ '../../views/pages/PasswordReset.vue'),
            beforeEnter: (to, _, next) => validateQueryEmail(to, next)
          },
          {
            path: 'set/:token',
            name: 'password.set',
            component: () => import(/* webpackChunkName: "public" */ '../../views/pages/PasswordSet.vue'),
            beforeEnter: (to, _, next) => validateQueryEmail(to, next)
          }
        ]
      }
    ]
  }
];
