import { computed } from 'vue';
import { useAxios } from 'composables';
import { getApiRoute } from 'routeconfig';

const useOewaActiveChannels = () => {
  const {
    state: {
      loading: loadingActiveChannels,
      data: activeChannels,
      error: hasActiveChannelsRequestErroredOut
    },
    fetcher
  } = useAxios({
    initialValue: []
  });

  const fetchActiveChannelsAPIRoute = computed(() => getApiRoute('api.oewa.channels'));

  function fetchActiveChannels() {
    return fetcher({
      url: fetchActiveChannelsAPIRoute.value
    });
  }

  return {
    fetchActiveChannels,
    loadingActiveChannels,
    activeChannels,
    hasActiveChannelsRequestErroredOut
  };
};

export default useOewaActiveChannels;
