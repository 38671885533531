<template>
  <div>
    <section class="u-pt-x10">
      <slot name="header"></slot>
    </section>

    <slot></slot>

    <slot name="footer"></slot>
  </div>
</template>
