import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useAxios } from 'composables';
import { API_ROUTES } from '../../../views/oewa/OewaConstants';
import { getOewaRatingsApiRoute } from 'views/oewa/helpers';

const useOewaRatingsRankingList = (searchString, type) => {
  const route = useRoute();
  const {
    state: { loading, data, error },
    fetcher
  } = useAxios({
    initialValue: []
  });

  const rankingItems = computed(() =>
    data.value.filter(service => !service.parent_id && service.parent_id !== 0)
  );

  const subRankingItems = computed(() =>
    data.value.filter(service => !!service.parent_id || service.parent_id === 0)
  );

  const rankingItemsMatchingSearchString = computed(() => {
    if (!searchString.value) {
      return rankingItems.value;
    }

    return rankingItems.value.filter(service =>
      service.name.toLowerCase().includes(searchString.value.toLowerCase())
    );
  });

  const fetchRankingList = () =>
    fetcher({
      url: getOewaRatingsApiRoute({
        name: API_ROUTES.RANKING_LIST[route.meta.api],
        params: {
          type
        }
      })
    });

  return {
    rankingItemsMatchingSearchString,
    subRankingItems,
    fetchRankingList,
    loading,
    error
  };
};

export default useOewaRatingsRankingList;
