import { APPS } from 'utils/constants.js';
import { exportRoutesWithAppMetaField } from 'utils/helpers';

export const socialAnalyticsRoutes = exportRoutesWithAppMetaField(APPS.SOCIAL_ANALYTICS, [
  {
    name: 'socialanalytics.requests',
    path: '/:country_code/socialanalytics/requests',
    component: () =>
      import(
        /* webpackChunkName: "socialanalytics" */ '../../views/pages/socialAnalytics/SocialAnalyticsRequests.vue'
      ),
    meta: { auth: true }
  },
  {
    name: 'socialanalytics.content',
    path: '/:country_code/socialanalytics/content',
    component: () =>
      import(
        /* webpackChunkName: "socialanalytics" */ '../../views/pages/socialAnalytics/SocialAnalyticsContent.vue'
      ),
    meta: { auth: true }
  },
  {
    name: 'socialanalytics.scrapings',
    path: '/:country_code/socialanalytics/scrapings',
    component: () =>
      import(
        /* webpackChunkName: "socialanalytics" */ '../../views/pages/socialAnalytics/SocialAnalyticsScrapings.vue'
      ),
    meta: { auth: true }
  },
  {
    name: 'socialanalytics.websites',
    path: '/:country_code/socialanalytics/websites',
    component: () =>
      import(
        /* webpackChunkName: "socialanalytics" */ '../../views/pages/socialAnalytics/SocialAnalyticsWebsites.vue'
      ),
    meta: { auth: true }
  }
]);
