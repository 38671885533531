import useAxios, { API_METHODS } from 'composables/useAxios';

export const useManageUserProfile = () => {
  const {
    state: { loading: savingUserProfile },
    fetcher: saveUserProfileFetcher
  } = useAxios();

  const saveUserProfile = ({ url, payload }) => {
    return saveUserProfileFetcher({
      method: API_METHODS.POST,
      url,
      requestBody: payload
    });
  };

  return {
    saveUserProfile,
    savingUserProfile
  };
};
