import { computed, ref } from 'vue';
import useAxios from '../useAxios';
import moment from 'moment';
import { SOCIAL_ANALYTICS_API } from 'constants';

const platforms = [
  { key: 'facebook', name: 'Facebook' },
  { key: 'pinterest', name: 'Pinterest' },
  { key: 'instagram', name: 'Instagram' },
  { key: 'twitter', name: 'Twitter' },
  { key: 'xing', name: 'Xing' },
  { key: 'oewa', name: 'Oewa' }
];

const selectedFilters = ref({});
const loading = {};
const rawRequests = {};
platforms.forEach(platform => {
  loading[platform.key] = ref(false);
  rawRequests[platform.key] = ref([]);
});

const useSocialAnalyticsRequests = () => {
  function setRequests(platform, requests) {
    rawRequests[platform].value = requests.map(item => ({
      ...item,
      scrapedAt: moment(item.GSISK).format('YYYY-MM-DD HH:mm')
    }));
  }

  const { fetcher: loadFetcher } = useAxios({
    initialValue: []
  });

  async function loadRequests(platform, queryParams = {}) {
    let query = '';
    const paramItems = Object.entries({ ...selectedFilters.value, ...queryParams });

    if (paramItems.length) {
      query = `?${paramItems.map(([key, value]) => `${key}=${value}`).join('&')}`;
    }

    let data;
    try {
      loading[platform].value = true;
      data = await loadFetcher({
        method: 'GET',
        url: `${SOCIAL_ANALYTICS_API}/${platform}/requests${query}`
      });
      loading[platform].value = false;
    } catch (err) {
      console.error('failed to fetch social analytics requests');
    }
    if (!data) return;
    setRequests(platform, data);
  }

  function requests(platform) {
    return rawRequests[platform].value || [];
  }

  const hasFilters = computed(() => Object.keys(selectedFilters.value).length);

  function clearFilters() {
    selectedFilters.value = {};
  }

  function setFilters(filterValues) {
    selectedFilters.value = filterValues;
  }

  return {
    loading,
    loadRequests,
    requests,
    selectedFilters,
    hasFilters,
    clearFilters,
    setFilters
  };
};

export default useSocialAnalyticsRequests;
