import axios from 'axios';
import { reactive, toRefs, onBeforeUnmount } from 'vue';

export const API_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
};

export const RESPONSE_TYPES = {
  JSON: 'json',
  BLOB: 'blob'
};

const useAxios = ({ initialValue = null, headers = false } = {}) => {
  const { token: localCancelToken, cancel: cancelRequest } = axios.CancelToken.source();
  const state = reactive({
    data: initialValue,
    loading: false,
    error: false,
    timeout: false,
    notFound: false
  });

  const resetState = () => {
    state.data = initialValue;
    state.loading = false;
    state.error = false;
    state.timeout = false;
    state.notFound = false;
  };

  const fetcher = async ({
    method = API_METHODS.GET,
    url,
    cancelToken,
    requestBody,
    requestHeaders,
    responseType = RESPONSE_TYPES.JSON,
    responseAdapter
  }) => {
    resetState();

    state.loading = true;

    const config = {
      method,
      url,
      cancelToken: cancelToken || localCancelToken,
      responseType
    };

    if (!!requestBody) {
      config.data = requestBody;
    }

    if (!!requestHeaders) {
      config.headers = requestHeaders;
    }

    try {
      const { data, headers: responseHeaders } = await axios(config);

      if (!data) return;

      const hasSuccessProperty = Object.prototype.hasOwnProperty.call(data, 'success');

      if (hasSuccessProperty && !data.success) {
        throw data;
      }

      try {
        const adaptedData = responseAdapter?.(data) ?? data;

        state.data = adaptedData;

        if (headers) {
          return {
            data: adaptedData,
            headers: responseHeaders
          };
        }

        return adaptedData;
      } catch (error) {
        console.error('Error while adapting response: ', error);

        throw error;
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.info(`${method} ${url} - request has been canceled.`);

        return;
      }

      state.error = true;

      if (error?.response?.status === 404) {
        state.notFound = true;
      }

      if (error?.code === 'ECONNABORTED') {
        state.timeout = true;
      }

      const errorMessage = error?.response?.data?.error || error?.message;

      console.error(`Error: ${method} ${url} request failed. Message: ${errorMessage}`);

      throw error;
    } finally {
      state.loading = false;
    }
  };

  onBeforeUnmount(() => {
    cancelRequest();
  });

  return { state: toRefs(state), fetcher, resetState };
};

export default useAxios;
