import useAxios, { API_METHODS } from 'composables/useAxios';
import { getApiRoute } from 'routeconfig';

const useOewaManageMediaHouses = () => {
  const {
    state: { loading: findAllLoading, error: findAllError, data: mediaHouses },
    fetcher: findAllFetcher
  } = useAxios({
    initialValue: []
  });
  const {
    state: { loading: findLoading, error: findError, data: mediaHouse },
    fetcher: findFetcher
  } = useAxios();
  const {
    state: { loading: creating },
    fetcher: createFetcher
  } = useAxios();
  const {
    state: { loading: updating },
    fetcher: updateFetcher
  } = useAxios();
  const {
    state: { loading: removing },
    fetcher: removeFetcher
  } = useAxios();

  const findAll = () =>
    findAllFetcher({
      url: getApiRoute('api.oewa.mediahouses')
    });

  const find = id =>
    findFetcher({
      url: getApiRoute({
        name: 'api.oewa.mediahouse',
        params: {
          id
        }
      })
    });

  const create = newMediaHouse =>
    createFetcher({
      method: API_METHODS.POST,
      url: getApiRoute('api.oewa.mediahouse'),
      requestBody: newMediaHouse
    });

  const update = ({ id, payload }) =>
    updateFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: 'api.oewa.mediahouse',
        params: {
          id
        }
      }),
      requestBody: payload
    });

  const remove = id =>
    removeFetcher({
      method: API_METHODS.DELETE,
      url: getApiRoute({
        name: 'api.oewa.mediahouse',
        params: {
          id
        }
      })
    });

  return {
    findAllLoading,
    findAllError,
    mediaHouses,
    findAll,
    findLoading,
    findError,
    mediaHouse,
    find,
    create,
    creating,
    update,
    updating,
    remove,
    removing
  };
};

export default useOewaManageMediaHouses;
