import { ref } from 'vue';
import { useRankingItem } from 'composables';

export const useRankingItems = () => {
  const rankingItems = ref([]);
  const rankingItemsError = ref(false);
  const rankingItemsLoading = ref(false);

  const { fetchRankingItem } = useRankingItem();

  const fetchRankingItems = ({ items, query }) => {
    if (!Array.isArray(items)) {
      throw new Error(`${items} must be of type array`);
    }

    rankingItemsLoading.value = true;

    const promises = items.map(({ id, type }) =>
      fetchRankingItem({
        id,
        type,
        query
      })
    );

    return Promise.all(promises)
      .then(rankingItemsRes => {
        rankingItems.value = rankingItemsRes;

        return rankingItemsRes;
      })
      .catch(error => {
        rankingItemsError.value = true;

        throw error;
      })
      .finally(() => {
        rankingItemsLoading.value = false;
      });
  };

  return {
    fetchRankingItems,
    rankingItems,
    rankingItemsLoading,
    rankingItemsError
  };
};
