import Cookies from 'js-cookie';
import createBreadcrumb from '../../../../store/breadcrumbs.js';
import axios from 'axios';
import { cloneDeep, sortBy } from 'lodash';
import { Mutation } from '../types';
import { isObject, getTokenFromCookies } from 'helper';
import { ALLOWED_TOKEN_PROPERTIES } from 'utils/constants';

export default {
  [Mutation.STORE_TOOLTIPS](state, value) {
    state.tooltips = value;
  },
  [Mutation.STORE_CAMPAIGN_ID](state, value) {
    state.campaign_id = value;
  },
  [Mutation.STORE_CUSTOMERS](state, value) {
    state.customers = value;
  },
  [Mutation.SET_CALCULATION_TYPE](state, value) {
    state.calculationType = value;
  },
  [Mutation.CURRENT_MAINTENANCE_NOTIFICATION](state, value) {
    state.maintenance_notification = value;
  },
  [Mutation.UPDATE_RATINGS](state, pool) {
    state.ratingsPool = pool;
  },
  [Mutation.SAVE_TOKEN](state, token) {
    if (!isObject(token)) return;

    Object.entries(token).map(([property, value]) => {
      const isPropertyAllowed = Object.values(ALLOWED_TOKEN_PROPERTIES).includes(property);

      if (isPropertyAllowed) {
        Cookies.set(property, value, {
          expires: (token.expires_in && token.expires_in / 3600 / 24) || 1
        });
      }
    });

    if (!!token[ALLOWED_TOKEN_PROPERTIES.ACCESS_TOKEN] && !!token[ALLOWED_TOKEN_PROPERTIES.TOKEN_TYPE]) {
      state.authorization = getTokenFromCookies();
    }
  },
  [Mutation.UPDATE_TOKEN](state) {
    // entirely unused
    // Save token to Vue Store
    const token = getTokenFromCookies();

    if (token) {
      state.authorization = token;

      // Change global axios headers
      axios.defaults.headers.common = {
        Authorization: token
      };
    }
  },
  [Mutation.UPDATE_USER](state, user) {
    // used in only one component
    // Clone user
    user = cloneDeep(user);

    // Sort countries
    const countries = sortBy(user.countries, c => c.name);

    // Update sorted countries
    if (user) {
      user.countries = countries;
    }

    // Update user
    state.currentUser = user;
  },
  [Mutation.REMOVE_TOKEN](state) {
    Cookies.remove(ALLOWED_TOKEN_PROPERTIES.ACCESS_TOKEN);
    Cookies.remove(ALLOWED_TOKEN_PROPERTIES.REFRESH_TOKEN);
    Cookies.remove(ALLOWED_TOKEN_PROPERTIES.TOKEN_TYPE);

    state.authorization = '';
    state.currentUser = null;
  },
  [Mutation.SET_REDIRECT_PATH](state, path) {
    // used in only one component
    state.redirectPath = path;
  },
  [Mutation.UPDATE_APPS](state, apps) {
    state.availableApps = apps;
  },
  [Mutation.SET_ACTIVE_APP](state, name) {
    state.activeApp = name || '';
  },
  [Mutation.SET_MAINTENANCE](state, value) {
    state.maintenance = value;
  },
  [Mutation.SET_NAVIGATION](state, value) {
    state.navigation = value;
  },
  [Mutation.SET_BREADCRUMBS](state, { key, payload }) {
    state.breadcrumbs = createBreadcrumb(key, payload);
  },
  [Mutation.SET_SELECTED_COUNTRY_CODE](state, countryCode) {
    state.selectedCountryCode = countryCode;
    localStorage.setItem('selectedCountryCode', countryCode);
  },
  [Mutation.SET_PAGE_FILTERS](state, newPageFilters) {
    state.pageFilters = newPageFilters;
  },
  [Mutation.RESET_PAGE_FILTERS](state) {
    state.pageFilters = [];
  },
  [Mutation.SET_PAGE_FILTERS_LOADING](state, newPageFiltersLoading) {
    state.pageFiltersLoading = newPageFiltersLoading;
  },
  [Mutation.SET_PAGE_LOADING](state, newPageLoading) {
    state.pageLoading = newPageLoading;
  }
};
