import { Action, Mutation } from '../types';
import { handleCallback, errorlog } from 'helper';
import { getApiRoute } from 'routeconfig';
import axios from 'axios';

export default {
  [Action.UPDATE_CONTRACTS]: ({ commit }, payload = {}) => {
    return axios
      .get(getApiRoute({ name: 'api.customer.contracts', query: { customer: payload.selected_customer_id } }))
      .then(({ data }) => {
        commit(`${Mutation.STORE_CONTRACTS}`, data);
        handleCallback(payload.callback);
      })
      .catch(errorlog);
  },
  [Action.UPDATE_CONTRACT_DATA]: ({ commit }, payload = {}) => {
    return axios
      .get(getApiRoute({ name: 'api.customer.contract.items.data', params: payload.params }))
      .then(({ data }) => {
        commit(`${Mutation.STORE_CONTRACT}`, data);
        handleCallback(payload.callback);
      })
      .catch(errorlog);
  },
  [Action.SAVE_CONTRACT_DATA]: (_, payload = {}) => {
    return axios
      .post(getApiRoute({ name: 'api.customer.contract.items.save', params: payload.params }), payload.data)
      .then(() => {
        handleCallback(payload.callback);
      })
      .catch(errorlog);
  }
};
