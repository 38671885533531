export const Action = {
  GET_PUBLISHERS: 'GET_PUBLISHERS',
  GET_CURRENT_PUBLISHER: 'GET_CURRENT_PUBLISHER',
  GET_CURRENT_PUBLISHER_INFO: 'GET_CURRENT_PUBLISHER_INFO',
  GET_CURRENT_PUBLISHER_CHART: 'GET_CURRENT_PUBLISHER_CHART',
  GET_CURRENT_PUBLISHER_INSIGHTS: 'GET_CURRENT_PUBLISHER_INSIGHTS'
};
export const Mutation = {
  STORE_PUBLISHERS: 'STORE_PUBLISHERS',
  STORE_CURRENT_PUBLISHER: 'STORE_CURRENT_PUBLISHER',
  STORE_CURRENT_PUBLISHER_INFO: 'STORE_CURRENT_PUBLISHER_INFO',
  STORE_CURRENT_PUBLISHER_CHART: 'STORE_CURRENT_PUBLISHER_CHART',
  STORE_CURRENT_PUBLISHER_INSIGHTS: 'STORE_CURRENT_PUBLISHER_INSIGHTS'
};
