export default {
  adnetworks: [],
  adnetwork: {},
  categories: [],
  categoryTargets: [],
  groups: [],
  mediahouses: [],
  mediahouse: {},
  services: [],
  service: [],
  streamingTypes: [],
  assignedTargets: [],
  unassignedTargets: [],
  widgets: []
};
