import { useAxios } from 'composables';
import { getApiRoute } from 'routeconfig';

const useAdminCustomers = () => {
  const {
    state: { loading: loadingAdminCustomers, data: adminCustomers },
    fetcher
  } = useAxios({
    initialValue: []
  });

  const fetchAdminCustomers = () =>
    fetcher({
      url: getApiRoute('api.customers.all-for-admin'),
      responseAdapter: ({ customers }) => customers
    });

  return {
    fetchAdminCustomers,
    loadingAdminCustomers,
    adminCustomers
  };
};

export default useAdminCustomers;
