export const ratingsAdministrationRoutes = [
  {
    path: 'ratings',
    component: {
      render: () => <RouterView />
    },
    children: [
      {
        path: 'companies',
        component: {
          render: () => <RouterView />
        },
        children: [
          {
            path: '',
            name: 'admin.ratings.companies',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../../../views/pages/management/companies/CompaniesManagement.vue'
              ),
            meta: { auth: true, hasCountrySwitcher: false, modules: ['ratings_admin'] }
          },
          {
            path: 'create',
            name: 'admin.ratings.companies.create',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../../../views/pages/management/companies/CompanyCreate.vue'
              ),
            meta: { auth: true, hasCountrySwitcher: false, modules: ['ratings_admin'] }
          },
          {
            path: ':id/edit',
            name: 'admin.ratings.companies.edit',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../../../views/pages/management/companies/CompanyEdit.vue'
              ),
            meta: { auth: true, hasCountrySwitcher: false, modules: ['ratings_admin'] }
          }
        ]
      },
      {
        path: 'services',
        component: {
          render: () => <RouterView />
        },
        children: [
          {
            path: '',
            name: 'admin.ratings.services',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../../../views/pages/management/services/ServicesManagement.vue'
              ),
            meta: { auth: true, hasCountrySwitcher: false, modules: ['ratings_admin'] }
          },
          {
            path: 'create',
            name: 'admin.ratings.services.create',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../../../views/pages/management/services/ServicesCreateOrEdit.vue'
              ),
            meta: { auth: true, hasCountrySwitcher: false, modules: ['ratings_admin'] }
          },
          {
            path: ':id/edit',
            name: 'admin.ratings.services.edit',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../../../views/pages/management/services/ServicesCreateOrEdit.vue'
              ),
            meta: { auth: true, hasCountrySwitcher: false, modules: ['ratings_admin'] }
          }
        ]
      },
      {
        path: 'adnetworks',
        component: {
          render: () => <RouterView />
        },
        children: [
          {
            path: '',
            name: 'admin.ratings.adnetworks',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../../../views/pages/management/AdnetworksManagement.vue'
              ),
            meta: { auth: true, hasCountrySwitcher: true, modules: ['ratings_admin'] }
          },
          {
            path: 'create',
            name: 'admin.ratings.adnetworks.create',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../../../views/pages/management/AdnetworksCreateOrEdit.vue'
              ),
            meta: { auth: true, hasCountrySwitcher: true, modules: ['ratings_admin'] }
          },
          {
            path: ':adnetworks_id/edit',
            name: 'admin.ratings.adnetworks.edit',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../../../views/pages/management/AdnetworksCreateOrEdit.vue'
              ),
            meta: { auth: true, hasCountrySwitcher: true, modules: ['ratings_admin'] }
          }
        ]
      },
      {
        path: 'mediahouses',
        component: {
          render: () => <RouterView />
        },
        children: [
          {
            path: '',
            name: 'admin.ratings.mediahouses',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../../../views/pages/management/MediahousesManagement.vue'
              ),
            meta: { auth: true, hasCountrySwitcher: true, modules: ['ratings_admin'] }
          },
          {
            path: 'create',
            name: 'admin.ratings.mediahouses.create',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../../../views/pages/management/MediaHousesCreateOrEdit.vue'
              ),
            meta: { auth: true, hasCountrySwitcher: true, modules: ['ratings_admin'] }
          },
          {
            path: ':id/edit',
            name: 'admin.ratings.mediahouses.edit',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../../../views/pages/management/MediaHousesCreateOrEdit.vue'
              ),
            meta: { auth: true, hasCountrySwitcher: true, modules: ['ratings_admin'] }
          }
        ]
      },
      {
        path: 'channels',
        component: {
          render: () => <RouterView />
        },
        children: [
          {
            path: '',
            name: 'admin.ratings.channels',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../../../views/pages/management/channels/ChannelsManagement.vue'
              ),
            meta: { auth: true, hasCountrySwitcher: true, modules: ['ratings_admin'] }
          },
          {
            path: 'create',
            name: 'admin.ratings.channels.create',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../../../views/pages/management/channels/ChannelCreate.vue'
              ),
            meta: { auth: true, hasCountrySwitcher: true, modules: ['ratings_admin'] }
          },
          {
            path: ':id/edit',
            name: 'admin.ratings.channels.edit',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../../../views/pages/management/channels/ChannelEdit.vue'
              ),
            meta: { auth: true, hasCountrySwitcher: true, modules: ['ratings_admin'] }
          }
        ]
      },
      {
        path: 'channel-types',
        name: 'admin.ratings.channel.types',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '../../../views/pages/management/ChannelTypesManagement.vue'
          ),
        meta: { auth: true, hasCountrySwitcher: true, modules: ['ratings_admin'] }
      },
      {
        path: 'targets/assign',
        name: 'admin.ratings.targets.assign',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../../../views/pages/management/TargetsAssign.vue'),
        meta: { auth: true, hasCountrySwitcher: true, modules: ['ratings_admin'] }
      },
      {
        path: 'widget-settings',
        name: 'admin.ratings.widget-settings',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '../../../views/pages/management/RatingsWidgetManagement.vue'
          ),
        meta: { auth: true, hasCountrySwitcher: true, modules: ['ratings_admin'] }
      },
      {
        path: 'categories',
        name: 'admin.ratings.categories',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../../../views/pages/management/CategoriesManagement.vue'),
        meta: { auth: true, hasCountrySwitcher: false, modules: ['ratings_admin'] }
      },
      {
        path: 'groups',
        component: {
          render: () => <RouterView />
        },
        children: [
          {
            path: '',
            name: 'admin.ratings.groups',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../../../views/pages/management/groups/GroupsManagement.vue'
              ),
            meta: { auth: true, hasCountrySwitcher: false, modules: ['ratings_admin'] }
          },
          {
            path: 'create',
            name: 'admin.ratings.groups.create',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../../../views/pages/management/groups/GroupsCreateOrEdit.vue'
              ),
            meta: { auth: true, hasCountrySwitcher: false, modules: ['ratings_admin'] }
          },
          {
            path: ':id/edit',
            name: 'admin.ratings.groups.edit',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../../../views/pages/management/groups/GroupsCreateOrEdit.vue'
              ),
            meta: { auth: true, hasCountrySwitcher: false, modules: ['ratings_admin'] }
          }
        ]
      }
    ]
  }
];
