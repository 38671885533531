import axios from 'axios';
import { Action, Mutation } from 'store/adminModule/_modules/ratings/types';
import { getApiRoute } from 'routeconfig';
import moment from 'moment';
import { errorlog, handleCallback } from 'helper';

const convertToReadableDate = date => {
  if (!date) return date;
  return moment(date).format('MMMM YYYY');
};

export default {
  //  #region AdNetworks
  [Action.UPDATE_ADNETWORKS]: ({ commit }, payload = {}) => {
    const url = getApiRoute({ name: 'api.admin.ratings.all', params: { type: 'adnetwork' } });

    return axios
      .get(url)
      .then(({ data }) => {
        const networks =
          data.items.map(item => {
            const enabled_from = convertToReadableDate(item.enabled_from);
            const enabled_until = convertToReadableDate(item.enabled_until);
            return { ...item, enabled_from, enabled_until };
          }) || [];
        commit(`${Mutation.STORE_ADNETWORKS}`, networks);
        handleCallback(payload.callback);
      })
      .catch(errorlog);
  },
  [Action.UPDATE_ADNETWORK]: ({ commit }, payload = {}) => {
    const url = getApiRoute({
      name: 'api.admin.ratings.adnetwork.data',
      params: {
        adnetworks_id: payload.id
      }
    });

    return axios
      .get(url)
      .then(({ data }) => {
        commit(`${Mutation.STORE_ADNETWORK}`, data.adnetwork);
        handleCallback(payload.callback);
      })
      .catch(errorlog);
  },
  [Action.SAVE_ADNETWORK]: (_, payload = {}) => {
    const url = payload.editMode
      ? getApiRoute({
          name: 'api.admin.ratings.adnetwork.update',
          params: {
            adnetwork_id: payload.id
          }
        })
      : getApiRoute({
          name: 'api.admin.ratings.adnetwork.create'
        });

    return axios.post(url, payload.data);
  },
  [Action.DELETE_ADNETWORK]: ({ dispatch }, payload = {}) => {
    const url = getApiRoute({
      name: 'api.admin.ratings.adnetwork.delete',
      params: { adnetworks_id: payload.id }
    });

    return axios.delete(url).then(() => {
      dispatch(`${Action.UPDATE_ADNETWORKS}`);
      handleCallback(payload.callback);
    });
  },
  //  #endregion
  //  #region Categories
  // update categories array in the store
  [Action.UPDATE_CATEGORIES]: ({ commit }, payload = {}) => {
    return axios
      .get(getApiRoute({ name: 'api.admin.ratings.categories' }))
      .then(({ data }) => {
        commit(`${Mutation.STORE_CATEGORIES}`, data.categories);
        handleCallback(payload.callback);
      })
      .catch(errorlog);
  },
  // create new or edit existing category and update store
  [Action.CREATE_OR_EDIT_CATEGORY]: ({ dispatch }, payload = {}) => {
    const url = getApiRoute({
      name: `api.admin.ratings.category.${payload.type}`,
      params: { id: payload.id }
    });
    const body = payload.body || null;
    return axios
      .post(url, body)
      .then(() => {
        dispatch(`${Action.UPDATE_CATEGORIES}`);
        handleCallback(payload.callback);
      })
      .catch(errorlog);
  },
  // combine multiple categories into one
  [Action.COMBINE_CATEGORY]: ({ dispatch }, payload = {}) => {
    const url = getApiRoute({
      name: `api.admin.ratings.categories.combine`
    });
    const body = payload.body || null;
    return axios
      .post(url, body)
      .then(() => {
        dispatch(`${Action.UPDATE_CATEGORIES}`);
        handleCallback(payload.callback);
      })
      .catch(errorlog);
  },
  // delete group and update store
  [Action.DELETE_CATEGORY]: ({ dispatch }, payload = {}) => {
    const url = getApiRoute({ name: 'api.admin.ratings.category.delete', params: { id: payload.id } });

    return axios
      .delete(url)
      .then(() => {
        dispatch(`${Action.UPDATE_CATEGORIES}`);
        handleCallback(payload.callback);
      })
      .catch(errorlog);
  },
  // update categoryTargets array whereas categoryTargets resemble the target array of the currently active category
  // in the category detail Dialog (CreateOrEditCategories.vue)
  [Action.UPDATE_CATEGORY_TARGETS]: ({ commit }, payload = {}) => {
    return axios
      .get(getApiRoute({ name: 'api.admin.ratings.category.detail', params: { id: payload.id } }))
      .then(({ data }) => {
        commit(`${Mutation.STORE_CATEGORY_TARGETS}`, data.category.targets);
        return data.category;
      })
      .catch(errorlog);
  },
  //  #endregion
  //  #region Media Houses
  [Action.UPDATE_MEDIAHOUSES]: ({ commit }, payload = {}) => {
    const url = getApiRoute({ name: 'api.admin.ratings.all', params: { type: 'mediahouse' } });
    return axios
      .get(url)
      .then(({ data }) => {
        const mediahouses =
          data.items.map(item => {
            const enabled_from = convertToReadableDate(item.enabled_from);
            const enabled_until = convertToReadableDate(item.enabled_until);
            return {
              ...item,
              enabled_from,
              enabled_until
            };
          }) || [];
        commit(`${Mutation.STORE_MEDIAHOUSES}`, mediahouses);
        handleCallback(payload.callback);
      })
      .catch(errorlog);
  },
  [Action.UPDATE_MEDIAHOUSE]: ({ commit }, payload = {}) => {
    const url = getApiRoute({ name: 'api.admin.ratings.mediahouse', params: { id: payload.id } });
    return axios
      .get(url)
      .then(({ data }) => {
        commit(`${Mutation.STORE_MEDIAHOUSE}`, data.mediahouse);
        handleCallback(payload.callback);
      })
      .catch(errorlog);
  },
  [Action.SAVE_MEDIAHOUSE]: (_, payload = {}) => {
    return axios.post(payload.url, payload.data).then(() => {
      handleCallback(payload.callback);
    });
  },
  [Action.DELETE_MEDIAHOUSE]: ({ dispatch }, payload = {}) => {
    const url = getApiRoute({
      name: 'api.admin.ratings.mediahouse.delete',
      params: { id: payload.id }
    });

    return axios.delete(url).then(() => {
      dispatch(`${Action.UPDATE_MEDIAHOUSES}`);
      handleCallback(payload.callback);
    });
  },
  //  #endregion
  //  #region Widgets
  [Action.UPDATE_WIDGETS]: ({ commit }, payload = {}) => {
    return axios
      .post(getApiRoute({ name: 'api.widgets.all', query: { page_key: 'ratings' } }))
      .then(({ data }) => {
        commit(`${Mutation.STORE_WIDGETS}`, data.widgets);
        handleCallback(payload.callback);
      })
      .catch(errorlog);
  },
  [Action.SAVE_WIDGET]: (_, payload = {}) => {
    return axios
      .post(payload.url, payload.body)
      .then(() => {
        handleCallback(payload.callback);
      })
      .catch(errorlog);
  },
  [Action.DELETE_WIDGET]: ({ dispatch }, payload = {}) => {
    const url = getApiRoute({
      name: 'api.widgets.delete',
      params: { id: payload.id }
    });
    return axios
      .delete(url)
      .then(() => {
        dispatch(`${Action.UPDATE_WIDGETS}`);
      })
      .catch(errorlog);
  }
  //  #endregion
};
