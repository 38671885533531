import { ref } from 'vue';
import useAxios from '../useAxios';
import { SOCIAL_ANALYTICS_API } from 'constants';
import { Message } from 'reppublika_components';
import moment from 'moment';

const platforms = [{ key: 'oewa', name: 'Oewa' }];

const loading = {};
const rawWebsites = {};
platforms.forEach(platform => {
  loading[platform.key] = ref(false);
  rawWebsites[platform.key] = ref([]);
});

const useSocialAnalyticsWebsites = () => {
  function setWebsites(platform, websites) {
    rawWebsites[platform].value = websites;
  }

  const { fetcher: loadFetcher } = useAxios({
    initialValue: []
  });
  const { fetcher: saveFetcher } = useAxios({
    initialValue: []
  });

  function websiteBaseSorter(a, b) {
    return +a.id - +b.id || a.url.localeCompare(b.url);
  }

  async function loadWebsites(platform) {
    let data;
    try {
      loading[platform].value = true;
      data = await loadFetcher({
        method: 'GET',
        url: `${SOCIAL_ANALYTICS_API}/${platform}/websites`
      });
      loading[platform].value = false;
    } catch (err) {
      console.error('failed to fetch websites');
    }
    if (!data) return;
    setWebsites(
      platform,
      data
        .sort(websiteBaseSorter)
        .map(item => ({ ...item, lastScrapedAt: moment(item.lastScrapedAt).format('YYYY-MM-DD HH:mm:ss') }))
    );
  }

  async function saveWebsite(platform, data) {
    try {
      const website = await saveFetcher({
        method: 'POST',
        url: `${SOCIAL_ANALYTICS_API}/${platform}/website`,
        requestBody: data
      });

      if (!website) throw new Error('no website passed back');
      setWebsites(
        platform,
        [...rawWebsites[platform].value.filter(site => site.PK !== website.PK), website].sort(
          websiteBaseSorter
        )
      );
      Message.success('Website saved.', 2500);
    } catch (err) {
      console.error('failed to save website', err);
    }
  }

  function websites(platform) {
    return rawWebsites[platform].value || [];
  }

  const { fetcher: testScrapeFetcher } = useAxios({
    initialValue: {}
  });

  async function testScrape(platform, url) {
    Message.success(
      'Test launched. Check Scrapings page in a couple minutes to see the results or check request page for errors.',
      5000
    );

    await testScrapeFetcher({
      method: 'POST',
      url: `${SOCIAL_ANALYTICS_API}/${platform}/scrape`,
      requestBody: [{ url }]
    });
  }

  return {
    loading,
    loadWebsites,
    saveWebsite,
    websites,
    platforms,
    testScrape
  };
};

export default useSocialAnalyticsWebsites;
