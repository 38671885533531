import { APPS } from 'utils/constants.js';
import { LAYOUTS } from 'views/oewa/layouts/useLayout';
import { API } from 'views/oewa/OewaConstants';
import { exportRoutesWithAppMetaField } from 'utils/helpers';
import { TRANSLATION_TAGS } from 'utils/translate';

const ensureRequiredMetaFieldsExist = (to, _, next) => {
  const apiExists = Object.values(API).includes(to.meta?.api);

  if (!apiExists) {
    return next(new Error(`Unrecognisable value of \`api\` meta property on route '${to.name}'.`));
  }

  const layoutExists = Object.values(LAYOUTS).includes(to.meta?.layout);

  if (!layoutExists) {
    return next(new Error(`Unrecognisable value of \`layout\` meta property on route '${to.name}'.`));
  }

  next();
};

export const publicOewaRatingsRoutes = exportRoutesWithAppMetaField(APPS.OEWA_RATINGS, [
  {
    path: '/',
    component: {
      render: () => <RouterView />
    },
    meta: {
      locale: 'de-DE'
    },
    children: [
      {
        path: '',
        name: 'oewa.ratings.public',
        component: () =>
          import(
            /* webpackChunkName: "oewa_ratings_public" */ '../../views/pages/oewa/OewaExternalPublicRatings.vue'
          ),
        meta: {
          layout: LAYOUTS.PUBLIC_OVERVIEW,
          api: API.PUBLIC,
          translation_tags: [TRANSLATION_TAGS.OWA_RATINGS, TRANSLATION_TAGS.FILTERS]
        },
        beforeEnter: ensureRequiredMetaFieldsExist
      },
      {
        path: 'details/:id/:type',
        name: 'oewa.ratings.public.details',
        component: () =>
          import(
            /* webpackChunkName: "oewa_ratings_public" */ '../../views/pages/oewa/OewaExternalPublicRatingsDetails.vue'
          ),
        meta: {
          layout: LAYOUTS.PUBLIC_DETAILS,
          api: API.PUBLIC,
          translation_tags: [
            TRANSLATION_TAGS.OWA_RATINGS,
            TRANSLATION_TAGS.FILTERS,
            TRANSLATION_TAGS.ATTRIBUTES,
            TRANSLATION_TAGS.DEMOGRAPHICS,
            TRANSLATION_TAGS.REACH_CHART
          ]
        },
        beforeEnter: ensureRequiredMetaFieldsExist
      }
    ]
  }
]);

export const oewaRatingsModuleRoutes = exportRoutesWithAppMetaField(APPS.OEWA_RATINGS, [
  {
    path: '/:country_code/oewa-ratings',
    component: {
      render: () => <RouterView />
    },
    meta: {
      locale: 'de-DE'
    },
    children: [
      {
        path: '',
        name: 'oewa.ratings',
        component: () =>
          import(
            /* webpackChunkName: "oewa_ratings" */ '../../views/pages/oewa/OewaInternalPublicRatings.vue'
          ),
        meta: {
          auth: true,
          layout: LAYOUTS.INTERNAL_OVERVIEW,
          api: API.PUBLIC,
          translation_tags: [TRANSLATION_TAGS.OWA_RATINGS, TRANSLATION_TAGS.FILTERS]
        },
        beforeEnter: ensureRequiredMetaFieldsExist
      },
      {
        path: 'private',
        name: 'oewa.ratings.private',
        component: () =>
          import(/* webpackChunkName: "oewa_ratings" */ '../../views/pages/oewa/OewaInternalRatings.vue'),
        meta: {
          auth: true,
          modules: ['owa_admin', 'owa_dashboard'],
          layout: LAYOUTS.INTERNAL_OVERVIEW,
          api: API.PRIVATE,
          translation_tags: [TRANSLATION_TAGS.OWA_RATINGS, TRANSLATION_TAGS.FILTERS]
        },
        beforeEnter: ensureRequiredMetaFieldsExist
      },
      {
        path: 'details/:id/:type',
        name: 'oewa.ratings.details',
        component: () =>
          import(
            /* webpackChunkName: "oewa_ratings" */ '../../views/pages/oewa/OewaInternalPublicRatingsDetails.vue'
          ),
        meta: {
          auth: true,
          layout: LAYOUTS.INTERNAL_DETAILS,
          api: API.PUBLIC,
          translation_tags: [
            TRANSLATION_TAGS.OWA_RATINGS,
            TRANSLATION_TAGS.FILTERS,
            TRANSLATION_TAGS.ATTRIBUTES,
            TRANSLATION_TAGS.DEMOGRAPHICS,
            TRANSLATION_TAGS.REACH_CHART
          ]
        },
        beforeEnter: ensureRequiredMetaFieldsExist
      },
      {
        path: 'private/details/:id/:type',
        name: 'oewa.ratings.private.details',
        component: () =>
          import(
            /* webpackChunkName: "oewa_ratings" */ '../../views/pages/oewa/OewaInternalRatingsDetails.vue'
          ),
        meta: {
          auth: true,
          modules: ['owa_admin', 'owa_dashboard'],
          layout: LAYOUTS.INTERNAL_DETAILS,
          api: API.PRIVATE,
          translation_tags: [
            TRANSLATION_TAGS.OWA_RATINGS,
            TRANSLATION_TAGS.FILTERS,
            TRANSLATION_TAGS.ATTRIBUTES,
            TRANSLATION_TAGS.DEMOGRAPHICS,
            TRANSLATION_TAGS.REACH_CHART
          ]
        },
        beforeEnter: ensureRequiredMetaFieldsExist
      },
      {
        path: 'channels',
        component: {
          render: () => <RouterView />
        },
        children: [
          {
            path: '',
            name: 'oewa.channels',
            component: () =>
              import(
                /* webpackChunkName: "oewa_ratings_channels" */ '../../views/pages/oewa/OewaChannels.vue'
              ),
            meta: { auth: true, modules: ['owa_admin', 'owa_mapping_tool'] }
          },
          {
            path: 'create',
            name: 'oewa.channels.create',
            component: () =>
              import(
                /* webpackChunkName: "oewa_ratings_channels" */ '../../views/pages/oewa/OewaChannelCreate.vue'
              ),
            meta: { auth: true, modules: ['owa_admin', 'owa_mapping_tool'] }
          },
          {
            path: ':id/edit',
            name: 'oewa.channels.edit',
            component: () =>
              import(
                /* webpackChunkName: "oewa_ratings_channels" */ '../../views/pages/oewa/OewaChannelEdit.vue'
              ),
            meta: { auth: true, modules: ['owa_admin', 'owa_mapping_tool'] }
          }
        ]
      }
    ]
  }
]);
