export const customerAdministrationRoutes = [
  {
    path: 'user/customers',
    name: 'user.customers',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../../../views/pages/management/UserCustomers.vue'),
    meta: { auth: true, hasCountrySwitcher: true, module: 'super_admin' }
  },
  {
    path: 'customer',
    component: {
      render: () => <RouterView />
    },
    children: [
      {
        path: '',
        name: 'customer.details',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../../../views/pages/management/CustomerManagement.vue'),
        meta: { auth: true, hasCountrySwitcher: true, module: 'customers_admin' }
      },
      {
        path: 'create',
        name: 'customer.create',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '../../../views/pages/management/customer/CustomerCreate.vue'
          ),
        meta: { auth: true, hasCountrySwitcher: true, module: 'super_admin' }
      },
      {
        path: ':id/edit',
        name: 'customer.edit',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../../../views/pages/management/customer/CustomerEdit.vue'),
        meta: { auth: true, hasCountrySwitcher: true, module: 'customers_admin' }
      },
      {
        path: 'connected-customers',
        name: 'customer.connected-customers',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../../../views/pages/management/ConnectedCustomers.vue'),
        meta: { auth: true, hasCountrySwitcher: true, module: 'super_admin' }
      },
      {
        path: 'advertisers',
        component: {
          render: () => <RouterView />
        },
        children: [
          {
            path: '',
            name: 'customer.advertisers',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../../../views/pages/management/AdvertisersManagement.vue'
              ),
            meta: { auth: true, hasCountrySwitcher: true, module: 'advertisers_admin' }
          },
          {
            path: 'create',
            name: 'customer.advertiser.create',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../../../views/pages/management/AdvertiserCreateOrEdit.vue'
              ),
            meta: { auth: true, hasCountrySwitcher: true, module: 'advertisers_admin' }
          },
          {
            path: ':advertiser_id/customer/:customer_id/edit',
            name: 'customer.advertiser.edit',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../../../views/pages/management/AdvertiserCreateOrEdit.vue'
              ),
            meta: { auth: true, hasCountrySwitcher: true, module: 'advertisers_admin' }
          }
        ]
      },
      {
        path: 'users',
        component: {
          render: () => <RouterView />
        },
        children: [
          {
            path: '',
            name: 'customer.users',
            component: () =>
              import(/* webpackChunkName: "admin" */ '../../../views/pages/management/UsersManagement.vue'),
            meta: { auth: true, hasCountrySwitcher: true, module: 'users_admin' }
          },
          {
            path: 'create',
            name: 'customer.user.create',
            component: () =>
              import(/* webpackChunkName: "admin" */ '../../../views/pages/management/UserCreateOrEdit.vue'),
            meta: { auth: true, hasCountrySwitcher: true, module: 'users_admin' }
          },
          {
            path: ':userID/edit',
            name: 'customer.user.edit',
            component: () =>
              import(/* webpackChunkName: "admin" */ '../../../views/pages/management/UserCreateOrEdit.vue'),
            meta: { auth: true, hasCountrySwitcher: true, module: 'users_admin' }
          }
        ]
      }
    ]
  }
];
