import { getApiRoute } from 'routeconfig';
import { useAxios } from 'composables';

const useOewaDeactivateChannel = () => {
  const {
    state: { loading: deactivating },
    fetcher
  } = useAxios();

  function deactivateChannel(id) {
    const deactivateChannelAPIRoute = getApiRoute({
      name: 'api.oewa.channel.deactivate',
      params: { id }
    });

    return fetcher({
      method: 'delete',
      url: deactivateChannelAPIRoute
    });
  }

  return {
    deactivateChannel,
    deactivating
  };
};

export default useOewaDeactivateChannel;
