/* eslint-disable */
import { $ } from 'libs';
import tippy from 'tippy.js';

window.tippy = tippy;

const Tooltip = {
  tips: {
    normal: [],
    question: [],
    click: []
  }
};

const selectors = {
  normal: '.js-tip',
  question: '.js-tip-question',
  click: '.js-tip-click',
  popup: '.js-popup'
};

const bindNormalTooltips = (selector, options) => {
  selector = selector || selectors.normal;
  Tooltip.tips.normal.push(tippy(selector, $.extend(true, {}, Tooltip.defaultOptions, options)));
};

const bindQuestionTooltips = (selector, options) => {
  selector = selector || selectors.question;
  Tooltip.tips.question.push(
    tippy(
      selector,
      $.extend(
        true,
        {},
        $.extend(true, {}, Tooltip.defaultOptions, {
          //performance: true,
          placement: 'top-start',
          //html: '#js-tip-question',
          offset: '-25, 6',
          delay: [150, 0],
          inertia: false,
          interactive: false,
          // trigger: 'click',
          theme: 'light question',
          arrow: false,
          //arrowTransform: 'scale(1,1)',
          onShow: function () {
            $(this).find('.js-question-content').html(Tooltip.tips.question[0].activeText);
          },
          wait: function (show, e) {
            Tooltip.tips.question[0].activeText = $.trim(e.srcElement.getAttribute('data-original-title'));
            show();
          }
        }),
        options
      )
    )
  );
};

const bindClickTooltips = (selector, options) => {
  selector = selector || selectors.click;
  Tooltip.tips.click.push(
    tippy(
      selector,
      $.extend(
        true,
        {},
        $.extend(true, {}, Tooltip.defaultOptions, {
          trigger: 'click'
          //performance: true
        }),
        options
      )
    )
  );
};

const bindAllTooltips = () => {
  bindNormalTooltips();
  bindQuestionTooltips();
  bindClickTooltips();
};

const bindPopups = (selector, options) => {
  selector = selector || selectors.popup;
  tippy(
    selector,
    $.extend(
      true,
      {},
      {
        distance: 20,
        duration: [350, 150],
        delay: [0, 150],
        interactive: true,
        interactiveBorder: 10,
        placement: 'bottom',
        size: 'small',
        theme: 'light popup',
        onShown: function () {
          // Enable inner datepickers
          var $datepicker = $(this).find('.datepicker-inline');
          var $monthpicker = $(this).find('.js-monthpicker');
          if ($datepicker.length && !$monthpicker.hasClass('js-enabled')) {
            $datepicker.remove();
            $monthpicker.addClass('js-enabled');
            Datepicker.init();
          }

          // Enable inner popups
          var $innerPopups = $(this).find('.js-popup');
          if ($innerPopups.length && !$innerPopups.hasClass('js-enabled')) {
            $innerPopups.addClass('js-enabled');
            bindPopups($innerPopups[0]);
          }

          // Download chart popup
          $(document).on('click', '.js-chart-download', function (e) {
            e.preventDefault();
            const button = this;

            $($(button).data('target')).each(function () {
              var _chart = $(this).highcharts();

              if (_chart) {
                _chart.exportChart({ type: $(button).data('filetype') });
              } else if ($(button).data('export-type') && $(button).data('export-type') == 'text/csv') {
                url = $(button).data('url');
                var a = document.createElement('a');
                a.href = url;
                document.body.appendChild(a);
                a.click();
                a.remove();
              } else if ($(button).data('filetype') == 'text/csv') {
                id = $(button).data('id');
                url_params = '';

                timespan = $('select[name=timespan]').val();
                if (timespan == undefined) {
                  timespan = '';
                } else {
                  url_params += '&timespan=' + timespan;
                }

                type = $('.c-panel__tab--active').children().first().data('chart-key');
                if (type == undefined) {
                  type = '';
                } else {
                  url_params += '&type=' + type;
                }

                chart_type = $(button).data('chart-type');
                if (chart_type == undefined) {
                  chart_type = '';
                } else {
                  url_params += '&chart-type=' + chart_type;
                }

                attribute_id = $(button).data('attribute-id');
                if (attribute_id == undefined) {
                  attribute_id = '';
                } else {
                  url_params += '&attribute-id=' + attribute_id;
                }

                var a = document.createElement('a');
                a.href =
                  '/campaigns/details/' +
                  id +
                  '/exportGraphDataInCsvFile' +
                  window.location.search +
                  url_params;
                document.body.appendChild(a);
                a.click();
                a.remove();
              }
            });
          });
        }
      },
      options
    )
  );
};

const isFile = function (text) {
  return RegExp(/(export)|(pdf)|(csv)|(xls)|(xlsx)|(doc)|(docx)/gi).test(text);
};

export default {
  init(type = null, selector = null, options = {}) {
    if (type === null) {
      bindAllTooltips();
      bindPopups();
    } else if (type === 'normal') {
      bindNormalTooltips(selector, options);
    } else if (type === 'question') {
      bindQuestionTooltips(selector, options);
    } else if (type === 'question') {
      bindClickTooltips(selector, options);
    } else if (type === 'popup') {
      bindPopups(selector, options);
    }
  },
  destroyAll() {
    Tooltip.tips.normal.length ? Tooltip.tips.normal.forEach(item => item.destroyAll()) : '';
    Tooltip.tips.question.length ? Tooltip.tips.question.forEach(item => item.destroyAll()) : '';
    Tooltip.tips.click.length ? Tooltip.tips.click.forEach(item => item.destroyAll()) : '';
  },
  defaultOptions: {
    duration: [300, 200],
    delay: [0, 50],
    distance: 5,
    size: 'small',
    arrowType: 'round',
    arrowTransform: 'scale(0.7, 0.6)  translateY(-3px)',
    flip: false,
    animation: 'shift-away',
    inertia: true,
    interactive: true,
    dynamicTitle: true,
    interactiveBorder: 20,
    arrow: true
  }
};
