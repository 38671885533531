<script setup>
import { computed } from 'vue';
import { AccountSwitcher, AppSwitcher, Navigation } from 'views';
import { useStore } from 'vuex';

const store = useStore();

const pageLoading = computed(() => store.state.pageLoading);
</script>

<template>
  <div>
    <div class="c-app-switcher-wrapper">
      <AppSwitcher v-if="!pageLoading" />
    </div>
    <div class="o-container c-sidebar__content u-pb-x3">
      <slot name="sidebar-top" />
      <Navigation v-if="!pageLoading" />
      <slot name="sidebar-bottom" />
    </div>
    <AccountSwitcher />
  </div>
</template>
