import useAxios from 'composables/useAxios';
import { getApiRoute } from 'routeconfig';

export const useRatingsManageCountries = () => {
  const {
    state: { loading: fetchAllCountriesLoading, data: allCountries },
    fetcher: fetchAllCountriesFetcher
  } = useAxios({
    initialValue: []
  });

  const fetchAllCountries = () =>
    fetchAllCountriesFetcher({
      url: getApiRoute('api.ratings.available-countries'),
      responseAdapter: ({ data }) => data || []
    });

  return {
    fetchAllCountries,
    fetchAllCountriesLoading,
    allCountries
  };
};
