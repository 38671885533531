import { useAxios } from 'composables';
import { getApiRoute } from 'routeconfig';
import { API_METHODS } from 'composables/useAxios';

const useCampaignTrackingChannelGroups = () => {
  const {
    state: {
      loading: fetchingTrackingChannels,
      error: fetchingTrackingChannelsError,
      data: trackingChannels
    },
    fetcher: fetchTrackingChannels
  } = useAxios({
    initialValue: []
  });

  const {
    state: { loading: savingTrackingChannelGroup, error: savingTrackingChannelGroupError },
    fetcher: saveNewTrackingChannelGroup
  } = useAxios();

  const {
    state: { loading: updatingTrackingChannelGroup, error: updatingTrackingChannelGroupError },
    fetcher: updateTrackingChannelGroup
  } = useAxios();

  const {
    state: { loading: deletingTrackingChannelGroup, error: deletingTrackingChannelGroupError },
    fetcher: deleteTrackingChannelGroup
  } = useAxios();

  const {
    state: {
      loading: fetchingTrackingChannelGroups,
      error: fetchingTrackingChannelGroupsError,
      data: trackingChannelGroups
    },
    fetcher: trackingChannelGroupsFetcher
  } = useAxios({
    initialValue: []
  });

  const fetchTrackingChannelGroups = () => {
    const apiRoute = getApiRoute({
      name: 'api.campaigns.tracking.channels.groups'
    });

    return trackingChannelGroupsFetcher({
      url: apiRoute,
      responseAdapter: ({ channel_groups }) => channel_groups
    });
  };

  const getTrackingChannels = campaignId => {
    const apiRoute = getApiRoute({
      name: 'api.campaigns.tracking.channels',
      params: {
        id: campaignId
      }
    });

    return fetchTrackingChannels({
      url: apiRoute,
      responseAdapter: ({ channel_entries }) => channel_entries
    });
  };

  const saveTrackingChannelGroup = ({ name, entries, campaign_id }) => {
    const apiRoute = getApiRoute({
      name: 'api.campaigns.tracking.channels.groups.store'
    });

    return saveNewTrackingChannelGroup({
      method: API_METHODS.POST,
      url: apiRoute,
      requestBody: {
        name,
        entries,
        campaign_id
      }
    });
  };

  const updateTrackingChannelGroupById = ({ id, name, entries, campaign_id }) => {
    const apiRoute = getApiRoute({
      name: 'api.campaigns.tracking.channels.groups.update',
      params: {
        id
      }
    });

    return updateTrackingChannelGroup({
      method: API_METHODS.POST,
      url: apiRoute,
      requestBody: {
        name,
        entries,
        campaign_id
      }
    });
  };

  const deleteTrackingChannelGroupById = id => {
    const apiRoute = getApiRoute({
      name: 'api.campaigns.tracking.channels.groups.delete',
      params: {
        id
      }
    });

    return deleteTrackingChannelGroup({
      url: apiRoute,
      method: API_METHODS.DELETE
    });
  };

  return {
    savingTrackingChannelGroup,
    savingTrackingChannelGroupError,
    saveTrackingChannelGroup,
    updatingTrackingChannelGroup,
    updatingTrackingChannelGroupError,
    updateTrackingChannelGroupById,
    deletingTrackingChannelGroup,
    deletingTrackingChannelGroupError,
    deleteTrackingChannelGroupById,
    fetchingTrackingChannels,
    fetchingTrackingChannelsError,
    trackingChannels,
    getTrackingChannels,
    fetchingTrackingChannelGroups,
    fetchingTrackingChannelGroupsError,
    fetchTrackingChannelGroups,
    trackingChannelGroups
  };
};

export default useCampaignTrackingChannelGroups;
