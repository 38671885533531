import { createApp, h } from 'vue';
import { mapState, mapActions, mapMutations } from 'vuex';
import { Action, Mutation } from 'store/_global/types';
import axios from 'axios';
import qs from 'qs';
import router from 'routeconfig';
import Application from './views/Application.vue';
import { $, Modal, Tooltip, Sorting, Collapser, Sticky } from 'libs';
import { OEWA_BUILD, OEWA_PUBLIC_BUILD, BRANCHNAME } from './constants.js';
import { Notification, Message } from 'reppublika_components';
import { getTokenFromCookies, handleAsyncError } from 'helper';
import { useVersion } from 'composables';

import './charts/highcharts.js';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import 'reppublika_components/dist/style.css';
import '../styles/app.scss';
import '../node_modules/vex-js/src/vex.combined';

import { initVuex } from 'plugins/vuex';
import { initializeDatadogRum } from 'plugins/datadog-rum.js';

export const store = initVuex();

initializeDatadogRum();

axios.defaults.paramsSerializer = params => {
  return qs.stringify(params, { arrayFormat: 'brackets' });
};

const intervalIds = [];

const app = createApp({
  components: {
    Message
  },
  render: () => h(Application),
  setup() {
    const { checkVersion } = useVersion();

    return {
      checkVersion
    };
  },
  mounted() {
    this.start();

    intervalIds.push(
      setInterval(() => {
        this.maintenanceMethod();
      }, 1000 * 60)
    );

    intervalIds.push(
      setInterval(() => {
        this.checkAccessTokens();
      }, 1000 * 60)
    );

    if (!BRANCHNAME) return;

    if (!!OEWA_BUILD || !!OEWA_PUBLIC_BUILD) return;

    this.checkVersion();

    intervalIds.push(
      setInterval(() => {
        this.checkVersion();
      }, 1000 * 60 * 5)
    );
  },
  beforeUnmount() {
    intervalIds.forEach(intervalId => {
      clearInterval(intervalId);
    });
  },
  watch: {
    currentLocation() {
      this.maintenanceMethod();
    }
  },
  methods: {
    maintenanceMethod() {
      this.checkMaintenanceStatus().then(() => {
        let name;
        const display = this.maintenance.some(elem => {
          if (this.currentLocation.name.includes(elem.name)) {
            name = elem.display_name;
            return true;
          }
          return false;
        });
        if (display && this.maintenance_notification !== name) {
          this.setCurrentMaintenanceNotification(name);
          Notification.show({
            type: Notification.TYPES.WARNING,
            message: 'Maintenance',
            description: `${name} is currently under maintenance. Data may not be up-to-date.`,
            duration: 0,
            placement: 'bottomRight'
          });
        }
      });
    },
    async checkAccessTokens() {
      if (!this.currentUser) return;

      const token = getTokenFromCookies();

      if (!token) {
        return this.$router.push({ name: 'login' });
      }

      try {
        const isValid = await this.checkTokens({ token });

        if (isValid) return;

        const result = await this.handleNext({ to: this.$route });

        if (!result?.name) return;

        this.$router.push({ name: result.name });
      } catch (error) {
        handleAsyncError(error, 'Something went wrong. An error occurred while checking user session.');
      }
    },
    start() {
      Modal.init();
      Tooltip.init();

      // Load initial handlers
      this.bindHandlers();

      if (typeof RChart != 'undefined') {
        window.RChart.switcher().init();
      }
    },
    bindHandlers() {
      // Enable Custom Dropdowns
      this.bindDropdowns();

      // Enable sorting
      Sorting.init();

      // Init table collapser
      Collapser.init();

      // Init sticky headers
      Sticky.init();
    },
    bindDropdowns() {
      $('select.js-custom-dropdown').customDropdown();
    },
    ...mapMutations({
      setCurrentMaintenanceNotification: Mutation.CURRENT_MAINTENANCE_NOTIFICATION,
      updateApps: Mutation.UPDATE_APPS
    }),
    ...mapActions({
      checkMaintenanceStatus: Action.CHECK_MAINTENANCE_STATUS,
      checkVersion: Action.CHECK_VERSION,
      checkTokens: Action.CHECK_TOKENS,
      handleNext: Action.HANDLE_NEXT
    })
  },
  computed: {
    currentLocation() {
      return this.$route;
    },
    ...mapState(['maintenance_notification', 'maintenance', 'currentUser'])
  }
});

app.use(router);
app.use(store);

app.mount('#app');
