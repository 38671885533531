export const campaignControlAdministration = [
  {
    path: 'campaigns',
    component: {
      render: () => <RouterView />
    },
    children: [
      {
        path: 'brand-lift-surveys',
        name: 'admin.campaigns.brand-lift-surveys',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '../../../views/pages/management/campaigns/BrandLiftSurveysManagement.vue'
          ),
        meta: {
          auth: true,
          hasCountrySwitcher: true,
          module: 'BrandLift_questionnaire_management'
        }
      },
      {
        path: 'brands-list',
        name: 'admin.campaigns.brands-list',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '../../../views/pages/management/campaigns/BrandsListManagement.vue'
          ),
        meta: {
          auth: true,
          hasCountrySwitcher: true,
          module: 'BrandLift_brands_management'
        }
      }
    ]
  }
];
