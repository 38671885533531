import { computed } from 'vue';
import { RATINGS_VIEWS, TABLE_COLUMN_KEYS } from 'views/oewa/OewaConstants';
import { useOewaRatingsView } from 'composables';

const COLUMNS = {
  [RATINGS_VIEWS.CHANNEL]: [
    { key: TABLE_COLUMN_KEYS.UNIQUE_USERS },
    { key: TABLE_COLUMN_KEYS.IMPRESSIONS_AT }
  ],
  [RATINGS_VIEWS.ZERVICE]: [
    { key: TABLE_COLUMN_KEYS.UNIQUE_USERS },
    { key: TABLE_COLUMN_KEYS.NET_REACH },
    { key: TABLE_COLUMN_KEYS.IMPRESSIONS_AT, title: 'PI AT' }
  ],
  [RATINGS_VIEWS.MONTHLY]: [
    { key: TABLE_COLUMN_KEYS.UNIQUE_USERS, width: 150 },
    { key: TABLE_COLUMN_KEYS.NET_REACH, width: 105 },
    { key: TABLE_COLUMN_KEYS.UNIQUE_CLIENTS, width: 150 },
    { key: TABLE_COLUMN_KEYS.VISITS, width: 110 },
    { key: TABLE_COLUMN_KEYS.IMPRESSIONS, width: 170 },
    { key: TABLE_COLUMN_KEYS.IMPRESSIONS_AT, title: 'PI AT%', width: 95 },
    { key: TABLE_COLUMN_KEYS.IMPRESSIONS_PER_VISITS, width: 90 },
    { key: TABLE_COLUMN_KEYS.USETIME, width: 105 }
  ]
};

const useOewaRatingsAggregationsColumns = () => {
  const { currentView } = useOewaRatingsView();

  const columns = computed(() => COLUMNS[currentView.value] || []);

  return {
    columns
  };
};

export default useOewaRatingsAggregationsColumns;
