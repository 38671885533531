const filterTabs = ({ itemsById, tabs, searchString }) => {
  const matchingItems = Object.values(itemsById).filter(item =>
    item?.title?.toLowerCase().includes(searchString.toLowerCase())
  );

  return tabs.map(tab => {
    return {
      ...tab,
      items:
        tab.items?.filter(item => {
          return matchingItems.filter(matchingItem => {
            return (
              `${item.type}_${item.id}` === `${matchingItem.type}_${matchingItem.id}` ||
              itemsById?.[`${item.type}_${item.id}`]?.childIds?.[`${matchingItem.type}_${matchingItem.id}`]
            );
          }).length;
        }) || []
    };
  });
};

const filterTargets = ({ targets, searchString }) => {
  return targets.filter(({ name }) => name.toLowerCase().includes(searchString.toLowerCase()));
};

export default { filterTabs, filterTargets };
