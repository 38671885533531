import useAxios, { API_METHODS } from 'composables/useAxios';
import { useRoute } from 'vue-router';
import { getApiRoute } from 'routeconfig';

const useCampaignWidgets = () => {
  const route = useRoute();

  const {
    state: { loading: fetchingWidgetData, data: widgetData },
    fetcher: widgetDataFetcher
  } = useAxios();

  const {
    state: { loading: fetchingSurveyData, data: surveyData },
    fetcher: surveyDataFetcher
  } = useAxios({
    initialValue: []
  });

  const {
    state: { loading: fetchingKpiWidgets, data: kpiWidgets },
    fetcher: kpiWidgetsFetcher
  } = useAxios({
    initialValue: []
  });

  const fetchWidgetData = ({ url }) =>
    widgetDataFetcher({
      url
    });

  const fetchSurveyData = () => {
    const url = getApiRoute({ name: 'api.campaigns.surveys.data', params: route.params });

    return surveyDataFetcher({
      url,
      responseAdapter: ({ survey_data }) => survey_data
    });
  };

  const fetchKpiWidgets = ({ campaignId }) => {
    const url = getApiRoute({ name: 'api.widgets.all' });

    return kpiWidgetsFetcher({
      url,
      method: API_METHODS.POST,
      requestBody: {
        name: 'brandlift',
        options: { id: campaignId }
      },
      responseAdapter: ({ widgets }) => widgets
    });
  };

  return {
    fetchWidgetData,
    fetchingWidgetData,
    widgetData,
    fetchSurveyData,
    fetchingSurveyData,
    surveyData,
    fetchKpiWidgets,
    fetchingKpiWidgets,
    kpiWidgets
  };
};

export default useCampaignWidgets;
