import { Mutation } from '../types';

export default {
  [Mutation.STORE_COMPARE_BEFORE_CATEGORIES]: (store, value) => {
    store.compareBeforeServiceCategories = value;
  },
  [Mutation.STORE_COMPARE_DATA_BEFORE]: (store, value) => {
    store.compareDataBefore = value;
  },
  [Mutation.STORE_COMPARE_AFTER_CATEGORIES]: (store, value) => {
    store.compareAfterServiceCategories = value;
  },
  [Mutation.STORE_COMPARE_DATA_AFTER]: (store, value) => {
    store.compareDataAfter = value;
  },
  [Mutation.STORE_COMPARE_TRAFFIC_DATA]: (store, value) => {
    store.compareTrafficData = value;
  },
  [Mutation.STORE_COMPARE_AUDIENCE_ATTRIBUTES]: (store, value) => {
    store.compareAudienceAttributes = value;
  },
  [Mutation.STORE_COMPARE_AUDIENCE_ATTRIBUTES_DATA]: (store, value) => {
    store.compareAudienceAttributesData = value;
  }
};
