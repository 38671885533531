import { computed } from 'vue';
import useAxios, { API_METHODS } from 'composables/useAxios';
import { getOewaRatingsApiRoute } from 'views/oewa/helpers';

const useOewaBlockOrUnblock = () => {
  const {
    state: { loading: blocking },
    fetcher: blockFetcher
  } = useAxios();
  const {
    state: { loading: unblocking },
    fetcher: unblockFetcher
  } = useAxios();

  const blockingOrUnblocking = computed(() => blocking.value || unblocking.value);

  const block = ({ id, type, reason }) => {
    const apiRoute = getOewaRatingsApiRoute({
      name: 'api.oewa.ranking.details.block',
      params: {
        id,
        type
      },
      query: {
        reason
      }
    });

    return blockFetcher({
      url: apiRoute,
      method: API_METHODS.POST
    });
  };

  const unblock = ({ id, type, reason }) => {
    const apiRoute = getOewaRatingsApiRoute({
      name: 'api.oewa.ranking.details.block',
      params: {
        id,
        type
      },
      query: {
        reason
      }
    });

    return unblockFetcher({
      url: apiRoute,
      method: API_METHODS.DELETE
    });
  };

  return {
    block,
    blocking,
    unblock,
    unblocking,
    blockingOrUnblocking
  };
};

export default useOewaBlockOrUnblock;
