import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

import billing from './_modules/billing';
import contract from './_modules/contract';
import customer from './_modules/customer';
import ratings from './_modules/ratings';

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    billing,
    contract,
    customer,
    ratings
  }
};
