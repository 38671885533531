import useAxios, { API_METHODS } from 'composables/useAxios';
import { getApiRoute } from 'routes/config';
import { buildFormData } from 'helper';

export const useManageAdvertisers = () => {
  const {
    state: { loading: advertisersLoading, error: advertisersError, data: advertisers },
    fetcher: advertisersFetcher
  } = useAxios({
    initialValue: []
  });
  const {
    state: { loading: advertiserLoading, error: advertiserError, data: advertiser },
    fetcher: advertiserFetcher
  } = useAxios();
  const {
    state: { loading: savingAdvertiser },
    fetcher: saveAdvertiserFetcher
  } = useAxios();

  const fetchAdvertisers = customerId =>
    advertisersFetcher({
      url: getApiRoute({
        name: 'api.customer.advertisers',
        query: {
          customer: customerId
        }
      })
    });

  const fetchAdvertiser = ({ customerId, advertiserId }) =>
    advertiserFetcher({
      url: getApiRoute({
        name: 'api.customer.advertiser.edit',
        params: {
          customer_id: customerId,
          advertiser_id: advertiserId
        }
      })
    });

  const saveAdvertiser = ({ customerId, advertiser }) =>
    saveAdvertiserFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: 'api.customer.advertiser.save',
        params: {
          customer_id: customerId
        }
      }),
      requestBody: buildFormData(advertiser)
    });

  return {
    fetchAdvertisers,
    advertisers,
    advertisersError,
    advertisersLoading,
    fetchAdvertiser,
    advertiserLoading,
    advertiserError,
    advertiser,
    saveAdvertiser,
    savingAdvertiser
  };
};
