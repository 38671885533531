<template>
  <PrivateLayout>
    <section class="u-text-center u-height-100 u-p-x10 u-pb-no">
      <div class="u-pt-x10 u-relative u-inline-block js-autotrigger-menu">
        <img :src="`${ASSET_PATH}img/icon-select-service.svg`" class="u-inline-block u-mb-x4" />
        <h2 class="o-type-16 u-color-text-secondary u-weight-500 u-block">Select a service from the menu</h2>
        <img :src="`${ASSET_PATH}img/icon-handraw-arrow-left.svg`" class="c-handrawn-arrow" />
      </div>
    </section>
  </PrivateLayout>
</template>

<script>
import PrivateLayout from '../layout/PrivateLayout.vue';
import { ASSET_PATH } from 'utils/constants.js';

export default {
  setup() {
    return {
      ASSET_PATH
    };
  },
  components: { PrivateLayout }
};
</script>
