import useAxios, { API_METHODS } from '../useAxios';
import { getApiRoute } from 'routeconfig';

const useLocales = () => {
  const {
    state: { loading, error, data: locales },
    fetcher: findAllFetcher
  } = useAxios({
    initialValue: []
  });
  const { fetcher: createFetcher } = useAxios();
  const { fetcher: updateFetcher } = useAxios();
  const { fetcher: removeFetcher } = useAxios();

  const findAll = () =>
    findAllFetcher({
      url: getApiRoute('api.admin.i18n.locales'),
      responseAdapter: data => data?.data || []
    });

  const create = newLocale =>
    createFetcher({
      url: getApiRoute('api.admin.i18n.locales'),
      method: API_METHODS.POST,
      requestBody: newLocale
    });

  const update = (id, updatedLocale) =>
    updateFetcher({
      url: getApiRoute({
        name: 'api.admin.i18n.locales.locale',
        params: {
          id
        }
      }),
      method: API_METHODS.POST,
      requestBody: updatedLocale
    });

  const remove = id =>
    removeFetcher({
      url: getApiRoute({
        name: 'api.admin.i18n.locales.locale',
        params: {
          id
        }
      }),
      method: API_METHODS.DELETE
    });

  return {
    loading,
    error,
    locales,
    findAll,
    create,
    update,
    remove
  };
};

export default useLocales;
