import useAxios, { API_METHODS } from 'composables/useAxios';
import { getApiRoute } from 'routeconfig';

const useCampaignAdvertisers = () => {
  const {
    state: { loading: savingAdvertiser, data: advertiser },
    fetcher: saveAdvertiserFetcher
  } = useAxios();

  const saveNewAdvertiser = ({ customerId, payload }) => {
    const apiRoute = getApiRoute({
      name: 'api.customer.save-advertiser',
      params: {
        id: customerId
      }
    });

    return saveAdvertiserFetcher({
      method: API_METHODS.POST,
      url: apiRoute,
      requestBody: payload,
      responseAdapter: data => data.advertiser
    });
  };

  return {
    saveNewAdvertiser,
    savingAdvertiser,
    advertiser
  };
};

export default useCampaignAdvertisers;
