import { computed, ref } from 'vue';
import useAxios, { RESPONSE_TYPES } from '../useAxios';
import { SOCIAL_ANALYTICS_API } from 'constants';
import { saveFile } from 'utils/helpers';
import moment from 'moment';

const platforms = [
  { key: 'facebook', name: 'Facebook' },
  { key: 'pinterest', name: 'Pinterest' },
  { key: 'instagram', name: 'Instagram' },
  { key: 'twitter', name: 'Twitter' },
  { key: 'xing', name: 'Xing' }
];

const selectedFilters = ref({});
const loading = {};
const rawContent = {};
platforms.forEach(platform => {
  loading[platform.key] = ref(false);
  rawContent[platform.key] = ref([]);
});

const useSocialAnalyticsContent = () => {
  function setContent(platform, content) {
    rawContent[platform].value = content;
  }

  const { fetcher: loadFetcher } = useAxios({
    initialValue: []
  });
  const { fetcher: exportFetcher } = useAxios({
    initialValue: ''
  });

  async function loadContent(platform, queryParams = {}) {
    let query = '';
    const paramItems = Object.entries({ ...selectedFilters.value, ...queryParams });

    if (paramItems.length) {
      query = `?${paramItems.map(([key, value]) => `${key}=${value}`).join('&')}`;
    }

    let data;
    try {
      loading[platform].value = true;
      data = await loadFetcher({
        method: 'GET',
        url: `${SOCIAL_ANALYTICS_API}/${platform}/contents${query}`
      });
      loading[platform].value = false;
    } catch (err) {
      console.error('failed to fetch social analytics content');
    }
    if (!data) return;
    setContent(platform, data);
  }

  async function loadExport(platform, queryParams = { format: 'csv' }) {
    let query = '';
    const paramItems = Object.entries({ ...selectedFilters.value, ...queryParams });

    if (paramItems.length) {
      query = `?${paramItems.map(([key, value]) => `${key}=${value}`).join('&')}`;
    }

    try {
      const blob = await exportFetcher({
        method: 'GET',
        url: `${SOCIAL_ANALYTICS_API}/${platform}/contents${query}`,
        responseType: RESPONSE_TYPES.BLOB
      });

      let dateString = '-';
      if (selectedFilters.value.start)
        dateString = `${dateString}${moment(selectedFilters.value.start).format('YYYY-MM-DD')}`;
      if (selectedFilters.value.start && selectedFilters.value.end) dateString = `${dateString}-`;
      if (selectedFilters.value.end)
        dateString = `${dateString}${moment(selectedFilters.value.end).format('YYYY-MM-DD')}`;

      saveFile({ blob, fileName: `${platform}${dateString}` });
    } catch (err) {
      console.error('failed to fetch social analytics content');
    }
  }

  function content(platform) {
    return rawContent[platform].value || [];
  }

  const hasFilters = computed(() => Object.keys(selectedFilters.value).length);

  function clearFilters() {
    selectedFilters.value = {};
  }

  function setFilters(filterValues) {
    selectedFilters.value = filterValues;
  }

  return {
    loading,
    loadContent,
    selectedFilters,
    hasFilters,
    clearFilters,
    setFilters,
    content,
    platforms,
    loadExport
  };
};

export default useSocialAnalyticsContent;
