import useAxios, { API_METHODS } from 'composables/useAxios';
import { getApiRoute } from 'routeconfig';
import Cookie from 'js-cookie';
import { ALLOWED_TOKEN_PROPERTIES } from 'utils/constants';
import { ref } from 'vue';

const useAuth = () => {
  const { fetcher: tokensFetcher } = useAxios();
  const { fetcher: checkTokenValidFetcher } = useAxios();

  const fetchTokensPromise = ref(null);
  const checkTokenValidPromise = ref(null);

  const fetchTokens = () => {
    if (!fetchTokensPromise.value) {
      fetchTokensPromise.value = tokensFetcher({
        method: API_METHODS.POST,
        url: getApiRoute('api.oauth.refresh-token'),
        requestBody: {
          refresh_token: Cookie.get(ALLOWED_TOKEN_PROPERTIES.REFRESH_TOKEN) || ''
        },
        responseAdapter: ({ data }) => data
      }).finally(() => {
        fetchTokensPromise.value = null;
      });
    }
    return fetchTokensPromise.value;
  };

  const checkTokenValid = token => {
    if (!checkTokenValidPromise.value) {
      checkTokenValidPromise.value = checkTokenValidFetcher({
        method: API_METHODS.POST,
        url: getApiRoute('api.token.check-validity'),
        requestHeaders: {
          Authorization: token
        },
        responseAdapter: ({ token_is_valid }) => token_is_valid
      }).finally(() => {
        checkTokenValidPromise.value = null;
      });
    }
    return checkTokenValidPromise.value;
  };

  return {
    fetchTokens,
    checkTokenValid
  };
};

export default useAuth;
