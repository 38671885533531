export const Action = {
  FETCH_HELPERS: 'FETCH_HELPERS',
  CHECK_USER_SESSION: 'CHECK_USER_SESSION',
  GO_TO_LOGIN: 'GO_TO_LOGIN',
  GO_TO_HOME: 'GO_TO_HOME',
  FETCH_USER_SESSION: 'FETCH_USER_SESSION',
  FETCH_USER_INFO: 'FETCH_USER_INFO',
  CHECK_RIGHTS: 'CHECK_RIGHTS', // unused
  HAS_ACCESS_TO_PAGE: 'HAS_ACCESS_TO_PAGE',
  HANDLE_NEXT: 'HANDLE_NEXT',
  CHECK_MAINTENANCE_STATUS: 'CHECK_MAINTENANCE_STATUS',
  CHECK_VERSION: 'CHECK_VERSION',
  CHECK_TOKENS: 'CHECK_TOKENS'
};

export const Mutation = {
  STORE_TOOLTIPS: 'STORE_TOOLTIPS',
  STORE_CAMPAIGN_ID: 'STORE_CAMPAIGN_ID',
  STORE_CUSTOMERS: 'STORE_CUSTOMERS',
  UPDATE_RATINGS: 'UPDATE_RATINGS',
  SAVE_TOKEN: 'SAVE_TOKEN', // used in only one component
  UPDATE_TOKEN: 'UPDATE_TOKEN', // unused
  UPDATE_USER: 'UPDATE_USER', // used in only one component
  REMOVE_TOKEN: 'REMOVE_TOKEN', // unused
  SET_REDIRECT_PATH: 'SET_REDIRECT_PATH', // used in only one component
  UPDATE_APPS: 'UPDATE_APPS',
  SET_ACTIVE_APP: 'SET_ACTIVE_APP',
  SET_NAVIGATION: 'SET_NAVIGATION',
  SET_MAINTENANCE: 'SET_MAINTENANCE',
  SET_BREADCRUMBS: 'SET_BREADCRUMBS',
  CURRENT_MAINTENANCE_NOTIFICATION: 'CURRENT_MAINTENANCE_NOTIFICATION',
  SET_CALCULATION_TYPE: 'SET_CALCULATION_TYPE',
  SET_PAGE_FILTERS: 'SET_PAGE_FILTERS',
  RESET_PAGE_FILTERS: 'RESET_PAGE_FILTERS',
  SET_PAGE_FILTERS_LOADING: 'SET_PAGE_FILTERS_LOADING',
  SET_SELECTED_COUNTRY_CODE: 'SET_SELECTED_COUNTRY_CODE',
  SET_PAGE_LOADING: 'SET_PAGE_LOADING'
};
