import { pick } from 'lodash';
import router from 'routeconfig';
import { currentDataFrame } from 'composables/ratings/useRatingsFilters';
import { FILTER_KEYS } from 'views/ratings/RatingsConstants';

export const getRatingsQueryParams = ({ query, extraQuery = {}, allowedKeys = [] } = {}) => {
  const routeQuery = query || router.currentRoute.value.query;
  const { date, interval_type, calculation_type } = currentDataFrame.value || {};

  const queryParams = {
    ...routeQuery,
    ...extraQuery,
    ...(!routeQuery.date && !extraQuery.date ? { date } : {}),
    ...(!routeQuery.interval_type && !extraQuery.interval_type ? { interval_type } : {}),
    ...(!routeQuery.calculation_type && !extraQuery.calculation_type ? { calculation_type } : {})
  };

  if (!allowedKeys.length) {
    return queryParams;
  }

  // TODO temporary, until #REPFE-2752 gets resolved
  const _allowedKeys = allowedKeys.flatMap(key => {
    if (key !== FILTER_KEYS.DATA_FRAME) {
      return [key];
    }

    return ['date', 'interval_type', 'calculation_type'];
  });

  return pick(queryParams, _allowedKeys);
};
