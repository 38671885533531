import { Mutation } from '../types';

export default {
  [Mutation.STORE_CONNECTED_SEARCH_TERMS]: (store, value) => {
    store.connectedSearchTermsGraph = value;
  },
  [Mutation.STORE_SELECTED_KEYWORDS]: (store, value) => {
    store.selectedKeywords = [...value];
  },
  [Mutation.STORE_SEARCH_ENGINE_DISTRIBUTION]: (store, value) => {
    store.searchEnginesGraph = value;
  },
  [Mutation.SET_DEFAULT_FILTER_DATE](state, newDefaultFilterDate) {
    state.defaultFilterDate = newDefaultFilterDate;
  }
};
