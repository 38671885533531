export const billingRoutes = [
  {
    path: 'customer/billing',
    component: {
      render: () => <RouterView />
    },
    children: [
      {
        path: 'overview',
        name: 'customer.billing.overview',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '../../../views/pages/management/CustomerBillingOverview.vue'
          ),
        meta: { auth: true, hasCountrySwitcher: true, module: 'billing_admin' }
      },
      {
        path: 'details',
        name: 'customer.billing.details',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '../../../views/pages/management/CustomerBillingDetails.vue'
          ),
        meta: { auth: true, hasCountrySwitcher: true, module: 'billing_admin' }
      }
    ]
  }
];
