import { stringify } from 'qs';
import { useAxios } from 'composables';
import { getApiRoute } from 'routeconfig';
import { getRatingsQueryParams, getItemName } from 'views/ratings/helpers';

export const useRatingsItems = () => {
  const {
    state: { loading: itemsLoading, error: itemsError, data: items },
    fetcher
  } = useAxios({
    initialValue: []
  });

  const fetchItems = ({ types = [], extraQuery = {} } = {}) => {
    const query = stringify(
      {
        types,
        ...getRatingsQueryParams({
          extraQuery
        })
      },
      { arrayFormat: 'indices' }
    );

    return fetcher({
      url: `${getApiRoute('api.ratings.items')}?${query}`,
      responseAdapter: items =>
        items.map(item => ({
          ...item,
          name: getItemName({
            name: item.name,
            trafficSource: item.traffic_source
          }),
          key: `${item.id}_${item.type}`
        }))
    });
  };

  return {
    fetchItems,
    itemsLoading,
    itemsError,
    items
  };
};
