import { BRANCHNAME, VERSION } from 'constants';
import useAxios from './useAxios';
import { Notification, ReloadOutlined, Button } from 'reppublika_components';
import { h } from 'vue';

const useMaintenance = () => {
  const { fetcher: versionJsonFetcher } = useAxios();

  const versionFileUrl = `${window.location.origin}/${
    !!BRANCHNAME && BRANCHNAME !== 'master' ? BRANCHNAME + '/' : ''
  }version.json`;

  const checkVersion = () =>
    versionJsonFetcher({
      url: versionFileUrl
    })
      .then(data => {
        if (!data?.version || data.version === VERSION) return;

        Notification.show({
          message: 'New version available',
          description: 'A new Version of Reppublika is available.',
          style: {
            width: '500px',
            marginLeft: `${384 - 500}px`
          },
          duration: 0,
          placement: 'bottomRight',
          closeIcon: h(
            Button,
            {
              type: 'secondary',
              size: 'sm',
              onClick: () => {
                if ('serviceWorker' in navigator) {
                  navigator.serviceWorker.getRegistrations().then(function (registrations) {
                    // Unregister all service workers
                    for (let registration of registrations) {
                      registration.unregister();
                    }
                  });
                }

                // Clear all caches
                if ('caches' in window) {
                  caches.keys().then(function (names) {
                    for (let name of names) {
                      caches.delete(name);
                    }
                  });
                }

                location.replace(`${window.location.href}?refresh=true`);
              }
            },
            {
              prefix: () => h(ReloadOutlined),
              default: () => 'Refresh'
            }
          ),
          key: 'appVersion'
        });
      })
      .catch(e => {
        console.error(e);
      });

  return {
    checkVersion
  };
};

export default useMaintenance;
