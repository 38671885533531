import useAxios, { API_METHODS } from 'composables/useAxios';
import { getApiRoute } from 'routeconfig';

const useOewaManageTrackingIdentifiers = () => {
  const {
    state: { loading: findAllLoading, error: findAllError, data: trackingIdentifiers },
    fetcher: findAllFetcher
  } = useAxios({
    initialValue: []
  });
  const {
    state: { loading: findLoading, error: findError, data: trackingIdentifier },
    fetcher: findFetcher
  } = useAxios({
    initialValue: {}
  });
  const {
    state: { loading: creating },
    fetcher: createFetcher
  } = useAxios();
  const {
    state: { loading: updating },
    fetcher: updateFetcher
  } = useAxios();

  const findAll = () =>
    findAllFetcher({
      url: getApiRoute('api.oewa.tracking-identifiers')
    });

  const find = id =>
    findFetcher({
      url: getApiRoute({
        name: 'api.oewa.tracking-identifier',
        params: {
          id
        }
      })
    });

  const create = newTrackingIdentifier =>
    createFetcher({
      url: getApiRoute('api.oewa.tracking-identifier'),
      method: API_METHODS.POST,
      requestBody: newTrackingIdentifier
    });

  const update = ({ id, payload }) =>
    updateFetcher({
      url: getApiRoute({
        name: 'api.oewa.tracking-identifier',
        params: {
          id
        }
      }),
      method: API_METHODS.POST,
      requestBody: payload
    });

  return {
    findAll,
    findAllLoading,
    findAllError,
    trackingIdentifiers,
    find,
    findLoading,
    findError,
    trackingIdentifier,
    create,
    creating,
    update,
    updating
  };
};

export default useOewaManageTrackingIdentifiers;
