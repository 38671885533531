import { ALL_METHODS } from './index';
import { WORKER_TYPES, RATINGS_METHOD_NAMES } from './constants';
import { encode, decode, constantToMethodName } from './shared';

const useWorker = () => {
  const runInWorker = ({ workerType, method, payload }) => {
    const worker = new Worker(new URL('./worker.js', import.meta.url), {
      type: 'module'
    });

    if (!ALL_METHODS[WORKER_TYPES[workerType]][constantToMethodName(method)]) {
      return Promise.reject(new Error(`Worker ${workerType} does not have a method called ${method}`));
    }

    return new Promise(resolve => {
      worker.postMessage(encode({ method, workerType, payload }));

      worker.onmessage = ({ data }) => {
        resolve(decode(data));

        worker.terminate();
      };
    });
  };

  return {
    runInWorker,
    WORKER_TYPES,
    RATINGS_METHOD_NAMES
  };
};

export default useWorker;
