/* eslint-disable */
export default $ =>
  function () {
    this.each(function () {
      try {
        var $dropdown = $(this);

        if ($dropdown.length && !$dropdown.hasClass('is-ready')) {
          // Check if it is already initialized and remove it
          const $parent = $dropdown.closest('.c-custom-dropdown');
          if ($parent.length) {
            $dropdown.insertBefore($parent[0]);
            $parent.remove();
          }

          var $valueHolder = $dropdown.siblings('.js-custom-dropdown-holder'),
            hasHolder = $valueHolder.length,
            title = $dropdown.data('dropdown-title'),
            contents = [
              `
                <div class="c-custom-dropdown ${$dropdown.attr('class')} ${
                title ? 'c-custom-dropdown--special' : ''
              }">
                  <span class="c-custom-dropdown__text-wrapper">
                    ${
                      title
                        ? `
                      <span class="c-custom-dropdown__title">${title}</span>
                    `
                        : ``
                    }
                    <span class="c-custom-dropdown__text"></span>
                  </span>
                  <img src="/img/icon-up-down-arrow.svg" class="c-custom-dropdown__arrow u-inline-block">
                </div>
              `
            ];

          // Create and inset the custom style dropdown
          var $custom = $(contents.join('')).insertBefore($dropdown);

          // Move the real dropdown inside the custom
          $dropdown.appendTo($custom);

          // If
          if (hasHolder) {
            var $customText = $valueHolder;
            $custom.addClass('c-custom-dropdown--fixed');
          } else {
            // Find the text field
            var $customText = $custom.find('.c-custom-dropdown__text');
          }

          // Returns the current value of the dropdown
          function dropdownValue() {
            return $dropdown[0].options[$dropdown[0].selectedIndex].text;
          }

          // Update text for first time
          $customText.text(dropdownValue());

          $dropdown.on('change', function () {
            run($dropdown);
          });

          // Initialized
          $dropdown.addClass('is-ready');

          var run = function ($element) {
            // Updates custom text value
            $customText.text(dropdownValue());

            // Selected option
            var $selected = $($element[0].options[$element[0].selectedIndex]);
            var redirectPage = $selected.data('redirect');
            var managedView = $selected.data('view');

            if (redirectPage) {
              window.location = redirectPage;
            }

            if (managedView) {
              var viewClass = $element.data('view');
              $('.' + viewClass).addClass('u-hidden');
              $('#' + managedView).removeClass('u-hidden');
            }
          };

          if ($dropdown.data('init')) {
            run($dropdown);
          }
        }
      } catch (error) {
        console.log(error);
      }
    });

    /* Return the input to enable chaining */
    return this;
  };
