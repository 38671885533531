import useAxios, { API_METHODS } from 'composables/useAxios';
import { getApiRoute } from 'routeconfig';
import { CHANNEL_MODES } from 'views/ratings/RatingsConstants';

export const API_ROUTES = {
  LIST_CHANNELS: {
    [CHANNEL_MODES.ADMIN]: 'api.admin.ratings.channels',
    [CHANNEL_MODES.CUSTOMER]: 'api.ratings.customer-channels'
  },
  PARENT_CANDIDATES: {
    [CHANNEL_MODES.ADMIN]: 'api.admin.ratings.channels.candidates.parents',
    [CHANNEL_MODES.CUSTOMER]: 'api.ratings.customer-channels.candidates.parents'
  },
  CHANNEL_TYPES: {
    [CHANNEL_MODES.ADMIN]: 'api.admin.ratings.channels.candidates.channel-types',
    [CHANNEL_MODES.CUSTOMER]: 'api.ratings.customer-channels.candidates.channel-types'
  },
  UPLOAD_XLS: {
    [CHANNEL_MODES.ADMIN]: 'api.admin.ratings.channels.candidates.target-urls-xls-echo',
    [CHANNEL_MODES.CUSTOMER]: 'api.ratings.customer-channels.candidates.target-urls-xls-echo'
  },
  CREATE_CHANNEL: {
    [CHANNEL_MODES.ADMIN]: 'api.admin.ratings.channels.create',
    [CHANNEL_MODES.CUSTOMER]: 'api.ratings.customer-channels.create'
  },
  UPDATE_CHANNEL: {
    [CHANNEL_MODES.ADMIN]: 'api.admin.ratings.channels.update',
    [CHANNEL_MODES.CUSTOMER]: 'api.ratings.customer-channels.update'
  },
  DELETE_CHANNEL: {
    [CHANNEL_MODES.ADMIN]: 'api.admin.ratings.channels.delete',
    [CHANNEL_MODES.CUSTOMER]: 'api.ratings.customer-channels.delete'
  },
  PUBLISH_CHANNEL: {
    [CHANNEL_MODES.ADMIN]: 'api.admin.ratings.channels.publish',
    [CHANNEL_MODES.CUSTOMER]: 'api.ratings.customer-channels.publish'
  }
};

export const useManageChannels = ({ mode }) => {
  const {
    state: { loading: channelsLoading, error: channelsError, data: channels },
    fetcher: fetchChannelsFetcher
  } = useAxios({
    initialValue: []
  });
  const {
    state: { loading: channelLoading, error: channelError, data: channel },
    fetcher: fetchChannelFetcher
  } = useAxios();
  const {
    state: { loading: creating },
    fetcher: createChannelFetcher
  } = useAxios();
  const {
    state: { loading: updating },
    fetcher: updateChannelFetcher
  } = useAxios();
  const {
    state: { loading: publishing },
    fetcher: publishChannelFetcher
  } = useAxios();
  const {
    state: { loading: deleting },
    fetcher: deleteChannelFetcher
  } = useAxios();
  const {
    state: { loading: parentCandidatesLoading, error: parentCandidatesError, data: parentCandidates },
    fetcher: fetchParentCandidatesFetcher
  } = useAxios({
    initialValue: []
  });
  const {
    state: {
      loading: channelTypeCandidatesLoading,
      error: channelTypeCandidatesError,
      data: channelTypeCandidates
    },
    fetcher: fetchChannelTypeCandidatesFetcher
  } = useAxios({
    initialValue: []
  });

  const fetchChannels = () =>
    fetchChannelsFetcher({
      url: getApiRoute(API_ROUTES.LIST_CHANNELS[mode]),
      responseAdapter: channels => {
        if (mode === CHANNEL_MODES.CUSTOMER) {
          return channels;
        }

        return channels.map(channel => ({
          ...channel,
          isPublic: !channel.customers?.length,
          isRequested: !!channel.public_request && !!channel.customers.length
        }));
      }
    });

  const fetchChannel = channelId =>
    fetchChannelFetcher({
      url: getApiRoute({
        name: API_ROUTES.LIST_CHANNELS[mode],
        params: {
          id: channelId
        }
      })
    });

  const createChannel = newChannel =>
    createChannelFetcher({
      method: API_METHODS.POST,
      url: getApiRoute(API_ROUTES.CREATE_CHANNEL[mode]),
      requestBody: newChannel
    });

  const updateChannel = ({ id, payload }) =>
    updateChannelFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: API_ROUTES.UPDATE_CHANNEL[mode],
        params: {
          id
        }
      }),
      requestBody: payload
    });
  const publishChannel = channelId =>
    publishChannelFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: API_ROUTES.PUBLISH_CHANNEL[mode],
        params: {
          id: channelId
        }
      })
    });

  const deleteChannel = channelId =>
    deleteChannelFetcher({
      method: API_METHODS.DELETE,
      url: getApiRoute({
        name: API_ROUTES.DELETE_CHANNEL[mode],
        params: {
          id: channelId
        }
      })
    });

  const fetchParentCandidates = () =>
    fetchParentCandidatesFetcher({
      url: getApiRoute(API_ROUTES.PARENT_CANDIDATES[mode])
    });

  const fetchChannelTypeCandidates = () =>
    fetchChannelTypeCandidatesFetcher({
      url: getApiRoute(API_ROUTES.CHANNEL_TYPES[mode])
    });

  return {
    channelsLoading,
    channelsError,
    channels,
    fetchChannels,
    channelLoading,
    channelError,
    channel,
    fetchChannel,
    creating,
    createChannel,
    updating,
    updateChannel,
    publishChannel,
    publishing,
    deleting,
    deleteChannel,
    parentCandidatesLoading,
    parentCandidatesError,
    fetchParentCandidates,
    parentCandidates,
    channelTypeCandidatesLoading,
    channelTypeCandidatesError,
    channelTypeCandidates,
    fetchChannelTypeCandidates
  };
};
