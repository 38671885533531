/* eslint-disable */
import { $ } from 'libs';
var init = function (collapser) {
  var target = collapser.data('target');
  var item = $('[data-collapse-item="' + target + '"]');
  var group = collapser.data('collapse-group');

  var getState = function (item) {
    return item.attr('data-collapse-state');
  };

  var setState = function (item, state) {
    if (state == 'open') {
      item.slideDown({
        duration: 200,
        complete: function () {
          item.attr('data-collapse-state', state);
          collapser.addClass('js-collapse-open');

          if (collapser.hasClass('c-table__row')) {
            collapser.addClass('c-table__row--open');
          }
        }
      });
    } else {
      item.slideUp({
        duration: 200,
        complete: function () {
          item.attr('data-collapse-state', state);
          collapser.removeClass('js-collapse-open');

          if (collapser.hasClass('c-table__row--open')) {
            collapser.removeClass('c-table__row--open');
          }
        }
      });
    }
  };

  collapser.on('click', function (event) {
    if (group) {
      // If collapser belongs to group, close other collapsers in that group
      $('.js-collapse-open[data-collapse-group="' + group + '"]').each(function (index) {
        var target = $(this).data('target');
        var item = $('[data-collapse-item="' + target + '"]');

        // Close group target items
        setState(item, 'closed');

        // Close group icon
        var icon = $(this).find('.js-collapser-icon');
        icon.attr('data-state', 'closed');
      });
    }

    // Toggle target Items
    var newState = getState(item) == 'open' ? 'closed' : 'open';
    setState(item, newState);

    // Set state for collapse icon
    var icon = collapser.find('.js-collapser-icon');
    icon.attr('data-state', newState);
  });
};

const initAll = () => {
  $(document)
    .find('[data-toggle="collapser"]')
    .each(function (index) {
      init($(this));
    });
};

export default {
  init() {
    initAll();
  }
};
