import useAxios from 'composables/useAxios';
import { getApiRoute } from 'routeconfig';

export const useUserModules = () => {
  const {
    state: { loading: fetchingUserModules },
    fetcher: userModulesFetcher
  } = useAxios();

  const fetchUserModules = () =>
    userModulesFetcher({
      url: getApiRoute('api.user.status'),
      responseAdapter: data => data?.modules || []
    });

  return {
    fetchingUserModules,
    fetchUserModules
  };
};
