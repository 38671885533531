import { computed } from 'vue';
import { useAxios } from 'composables';
import { RANKING_ITEM_TYPES } from 'views/oewa/OewaConstants';
import { getOewaRatingsApiRoute } from 'views/oewa/helpers';

const useOewaChannels = searchString => {
  const {
    state: { loading: loadingChannels, data, error: hasChannelsRequestErroredOut },
    fetcher
  } = useAxios({
    initialValue: []
  });

  const channels = computed(() => {
    if (!searchString.value) {
      return data.value;
    }

    return data.value.filter(channel =>
      channel.name.toLowerCase().includes(searchString.value.toLowerCase())
    );
  });

  const fetchChannels = () =>
    fetcher({
      url: getOewaRatingsApiRoute({
        name: 'api.oewa.ranking.list',
        params: {
          type: RANKING_ITEM_TYPES.CHANNEL
        }
      })
    });

  return {
    channels,
    fetchChannels,
    loadingChannels,
    hasChannelsRequestErroredOut
  };
};

export default useOewaChannels;
