import { useAxios } from 'composables';
import { getApiRoute } from 'routeconfig';
import { API_METHODS } from 'composables/useAxios';

const useCampaignKpiBreakdowns = () => {
  const {
    state: { loading: loadingBreakdownContactsStatistic, data: breakdownContactsStatistic },
    fetcher: breakdownContactsStatisticFetcher
  } = useAxios();

  const {
    state: { loading: loadingBreakdownPublisherStatistic, data: breakdownPublisherStatistic },
    fetcher: breakdownPublisherStatisticFetcher
  } = useAxios();

  const {
    state: { loading: loadingBreakdownAttribute, data: breakdownAttributeStatistic },
    fetcher: breakdownAttributeFetcher
  } = useAxios();

  const fetchBreakdownContactsStatistic = ({ surveyId, widgetId }) => {
    return breakdownContactsStatisticFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: 'api.campaigns.kpi.breakdown.campaign-contacts-statistic',
        params: { survey_id: surveyId }
      }),
      requestBody: { widget_id: widgetId },
      responseAdapter: data => data.chart
    });
  };

  const fetchBreakdownPublisherStatistic = ({ surveyId, widgetId }) => {
    return breakdownPublisherStatisticFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: 'api.campaigns.kpi.breakdown.publisher-statistic',
        params: { survey_id: surveyId }
      }),
      requestBody: { widget_id: widgetId },
      responseAdapter: data => data.chart
    });
  };

  const fetchBreakdownAttribute = ({ surveyId, widgetId, attributeId }) => {
    return breakdownAttributeFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: 'api.campaigns.kpi.breakdown.attribute',
        params: { survey_id: surveyId, attribute_id: attributeId }
      }),
      requestBody: { widget_id: widgetId },
      responseAdapter: data => data.chart
    });
  };

  return {
    fetchBreakdownContactsStatistic,
    loadingBreakdownContactsStatistic,
    breakdownContactsStatistic,
    fetchBreakdownPublisherStatistic,
    loadingBreakdownPublisherStatistic,
    breakdownPublisherStatistic,
    fetchBreakdownAttribute,
    loadingBreakdownAttribute,
    breakdownAttributeStatistic
  };
};

export default useCampaignKpiBreakdowns;
