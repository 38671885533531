import {
  DD_RUM_ClIENT_TOKEN,
  DD_RUM_APPLICATION_ID,
  DD_RUM_SITE,
  DD_RUM_ENV,
  DD_RUM_SESSION_SR,
  DD_RUM_SESSION_REPLAY_SR,
  DD_RUM_APP_VERSION,
  DD_RUM_ENABLED
} from 'constants';

export function initializeDatadogRum() {
  if (DD_RUM_ENABLED) {
    let datadogScript = document.createElement('script');

    datadogScript.src = 'https://www.datadoghq-browser-agent.com/eu1/v5/datadog-rum.js';
    datadogScript.onload = initDatadog;
    document.body.appendChild(datadogScript);
  }

  function initDatadog() {
    window.DD_RUM &&
      window.DD_RUM.init({
        clientToken: DD_RUM_ClIENT_TOKEN,
        applicationId: DD_RUM_APPLICATION_ID,
        site: DD_RUM_SITE,
        service: 'audience-tools',
        env: DD_RUM_ENV,
        version: DD_RUM_APP_VERSION,
        sessionSampleRate: DD_RUM_SESSION_SR,
        sessionReplaySampleRate: DD_RUM_SESSION_REPLAY_SR,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input'
      });
  }
}
