import useAxios, { API_METHODS } from '../useAxios';
import { getApiRoute } from 'routeconfig';

export const useDistributions = () => {
  const {
    state: { loading: fetchingPageData, error: fetchPageDataError, data: pageData },
    fetcher: pageDataFetcher
  } = useAxios({
    initialValue: []
  });

  const {
    state: {
      loading: updatingAttributeValues,
      error: updateAttributeValuesError,
      data: updatedAttributeValues
    },
    fetcher: updateAttributeValuesFetcher
  } = useAxios({
    initialValue: []
  });

  const {
    state: { loading: savingDistribution, error: saveDistributionError },
    fetcher: saveDistributionFetcher
  } = useAxios();

  const {
    state: { loading: fetchingCurrentDistribution, error: fetchCurrentDistributionError, data: distribution },
    fetcher: currentDistributionFetcher
  } = useAxios();

  const {
    state: { loading: fetchingDistributions, error: fetchDistributionsError, data: distributions },
    fetcher: distributionsFetcher
  } = useAxios({
    initialValue: []
  });

  const {
    state: { loading: deletingDistribution, error: deleteDistributionError },
    fetcher: deleteDistributionFetcher
  } = useAxios();

  const fetchPageData = () =>
    pageDataFetcher({
      url: getApiRoute('api.admin.distribution.page.data')
    });

  const updateAttributeValues = ({ attributes }) =>
    updateAttributeValuesFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: 'api.admin.distribution.attributes.values'
      }),
      requestBody: { attributes },
      responseAdapter: data => {
        if (!data?.attributes) return [];

        return data.attributes.map(attribute => {
          return {
            ...attribute,
            values: attribute.values.map(value => {
              return {
                ...value,
                key: `${attribute.id}_${value.id}`
              };
            })
          };
        });
      }
    });

  const saveDistribution = payload =>
    saveDistributionFetcher({
      method: API_METHODS.POST,
      url: getApiRoute('api.admin.distribution.save'),
      requestBody: payload
    });

  const fetchCurrentDistribution = ({ id }) =>
    currentDistributionFetcher({
      url: getApiRoute({
        name: 'api.admin.distribution',
        params: { id }
      }),
      responseAdapter: ({ distribution }) => {
        if (!distribution) return {};

        if (!distribution.distribution) return distribution;

        return {
          ...distribution,
          distribution: distribution.distribution.map(attribute => {
            return {
              ...attribute,
              id: attribute.attribute_id,
              name: attribute.attribute_name,
              values: attribute.attribute_values.map(value => {
                return {
                  ...value,
                  id: value.attribute_value_id,
                  name: value.attribute_value_name,
                  value: parseInt(value.attribute_value_percentage),
                  key: `${attribute.attribute_id}_${value.attribute_value_id}`
                };
              })
            };
          })
        };
      }
    });

  const fetchDistributions = () =>
    distributionsFetcher({
      url: getApiRoute('api.admin.distributions'),
      responseAdapter: distributions => distributions?.distributions || []
    });

  const deleteDistribution = ({ distribution_id }) =>
    deleteDistributionFetcher({
      method: API_METHODS.DELETE,
      url: getApiRoute({
        name: 'api.admin.distribution.delete',
        params: { distribution_id }
      })
    });

  return {
    fetchPageData,
    fetchingPageData,
    fetchPageDataError,
    pageData,
    updateAttributeValues,
    updatingAttributeValues,
    updateAttributeValuesError,
    updatedAttributeValues,
    saveDistribution,
    savingDistribution,
    saveDistributionError,
    fetchCurrentDistribution,
    fetchingCurrentDistribution,
    fetchCurrentDistributionError,
    distribution,
    fetchDistributions,
    fetchingDistributions,
    fetchDistributionsError,
    distributions,
    deleteDistribution,
    deletingDistribution,
    deleteDistributionError
  };
};
