export const generalAdministrationRoutes = [
  {
    path: 'general',
    component: {
      render: () => <RouterView />
    },
    children: [
      {
        path: 'distributions',
        component: {
          render: () => <RouterView />
        },
        children: [
          {
            path: '',
            name: 'admin.distributions',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../../../views/pages/management/DistributionManagement.vue'
              ),
            meta: { auth: true, hasCountrySwitcher: true, module: 'distributions_admin' }
          },
          {
            path: 'create',
            name: 'admin.distribution.create',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../../../views/pages/management/DistributionManagementCreate.vue'
              ),
            meta: { auth: true, hasCountrySwitcher: true, module: 'distributions_admin' }
          },
          {
            path: ':distribution_id/edit',
            name: 'admin.distribution.edit',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../../../views/pages/management/DistributionManagementEdit.vue'
              ),
            meta: { auth: true, hasCountrySwitcher: true, module: 'distributions_admin' }
          }
        ]
      },
      {
        path: 'maintenance',
        name: 'admin.maintenance',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../../../views/pages/management/MaintenanceManagement.vue'),
        meta: { auth: true, hasCountrySwitcher: true, module: 'super_admin' }
      },
      {
        path: 'attributes',
        component: {
          render: () => <RouterView />
        },
        children: [
          {
            path: '',
            name: 'admin.attributes',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../../../views/pages/management/AttributesManagement.vue'
              ),
            meta: { auth: true, hasCountrySwitcher: true, module: 'attributes_admin' }
          },
          {
            path: ':id/edit',
            name: 'admin.attribute.edit',
            component: () =>
              import(/* webpackChunkName: "admin" */ '../../../views/pages/management/AttributeEdit.vue'),
            meta: { auth: true, hasCountrySwitcher: true, module: 'attributes_admin' }
          }
        ]
      },
      {
        path: 'modules',
        component: {
          render: () => <RouterView />
        },
        children: [
          {
            path: '',
            name: 'admin.modules',
            component: () =>
              import(/* webpackChunkName: "admin" */ '../../../views/pages/management/ModulesManagement.vue'),
            meta: { auth: true, hasCountrySwitcher: true, module: 'modules_admin' }
          },
          {
            path: ':id/edit',
            name: 'admin.modules.edit',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../../../views/pages/management/ModulesManagementEdit.vue'
              ),
            meta: { auth: true, hasCountrySwitcher: true, module: 'modules_admin' }
          },
          {
            path: 'create',
            name: 'admin.modules.create',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../../../views/pages/management/ModulesManagementCreate.vue'
              ),
            meta: { auth: true, hasCountrySwitcher: true, module: 'modules_admin' }
          }
        ]
      }
    ]
  }
];
