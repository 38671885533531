import { ref, watch, computed } from 'vue';
import { debounce } from 'helper';

const useDebouncedSearch = (delay = 500) => {
  const searchString = ref('');
  const debouncedSearchString = ref('');

  const trimmedSearchString = computed(() => searchString.value.trim?.());

  const debouncedSearch = debounce(newTrimmedSearchString => {
    if (!newTrimmedSearchString) {
      debouncedSearchString.value = '';
    } else {
      debouncedSearchString.value = newTrimmedSearchString;
    }
  }, delay);

  watch(trimmedSearchString, debouncedSearch);

  const handleSearch = newSearchString => {
    searchString.value = newSearchString;
  };

  const resetSearchString = () => {
    searchString.value = '';
  };

  return {
    searchString,
    debouncedSearchString,
    handleSearch,
    resetSearchString
  };
};

export default useDebouncedSearch;
