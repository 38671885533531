<template>
  <AuthLayout>
    <Panel class="c-login__content u-p-x10">
      <AppLogo class="u-width-full u-mb-x4" />

      <div v-if="error" class="u-mb-x3">
        <div class="c-input-error">
          <h4 class="c-input-error--invalid">{{ errorMessage }}</h4>
        </div>
      </div>

      <SimpleForm :formSchema="formSchema" :defaultValues="formDefaultValues" @submit="handleLogin">
        <Button class="u-mv-x2" :loading="loggingIn" type="primary" htmlType="submit" full>
          {{ loggingIn ? 'Signing in...' : 'Sign in' }}
        </Button>

        <div class="u-text-center">
          <router-link :to="{ name: 'password.email' }" class="c-link--secondary">
            Forgot password?
          </router-link>
        </div>
      </SimpleForm>
    </Panel>
  </AuthLayout>
</template>

<script>
import { useRoute } from 'vue-router';
import { Button, SimpleForm } from 'reppublika_components';
import { mapState, mapMutations, mapActions } from 'vuex';
import { Action, Mutation } from 'store/_global/types';
import axios from 'axios';
import AuthLayout from 'views/layout/AuthLayout.vue';
import { getApiRoute } from 'routeconfig';
import useAxios, { API_METHODS } from 'composables/useAxios';
import { store } from '../../main';
import { Panel, AppLogo } from 'views';

const useLogin = () => {
  const route = useRoute();
  const {
    state: { loading: loggingIn, error },
    fetcher
  } = useAxios();

  const login = ({ email, password }) =>
    fetcher({
      url: getApiRoute('api.login'),
      method: API_METHODS.POST,
      requestBody: {
        ...route.query,
        email,
        password
      }
    });

  return {
    login,
    loggingIn,
    error
  };
};

export default {
  components: {
    AuthLayout,
    SimpleForm,
    Button,
    Panel,
    AppLogo
  },
  setup() {
    const { login, loggingIn, error } = useLogin();

    return {
      login,
      loggingIn,
      error
    };
  },
  data: () => ({
    errorMessage: ''
  }),
  computed: {
    formSchema() {
      return {
        fields: {
          email: {
            inputType: 'text',
            label: 'Email',
            props: {
              full: true
            }
          },
          password: {
            inputType: 'password',
            label: 'Password',
            props: {
              full: true
            }
          }
        },
        rules: {
          email: [
            {
              type: 'email',
              required: true,
              whitespace: true,
              message: 'Please enter a valid email'
            }
          ],
          password: [
            {
              required: true,
              whitespace: true,
              message: 'Password is required'
            }
          ]
        }
      };
    },
    formDefaultValues() {
      const email = this.$route.query.email;

      return !!email ? { email } : {};
    },
    year() {
      return new Date().getFullYear();
    },
    ...mapState(['authorization', 'redirectPath'])
  },
  created() {
    if (!this.$route.query.redirect && !this.$route.query['sso-redirect']) return;

    !!this.$route.query.redirect ? this.redirectForExternalClient() : this.handleSsoLogin();
  },
  methods: {
    handleLogin({ email, password }) {
      return this.login({
        email,
        password
      })
        .then(data => {
          this.saveToken(data);

          if (!!data.redirect) {
            window.location.href = data.redirect;
          } else {
            const nextRoute = this.redirectPath || { name: 'startpage' };

            this.setRedirectPath('');

            this.$router.push(nextRoute);
          }
        })
        .catch(error => {
          this.errorMessage = error.response?.data?.error;
        });
    },
    redirectForExternalClient() {
      this.updateToken();
      const authorize_url = getApiRoute('api.authorize.redirect');
      axios
        .post(authorize_url, { ...this.$route.query })
        .then(({ data }) => {
          if (!data) return;
          // Redirect user to external client site
          if (!!data.redirect) window.location.href = data.redirect;
        })
        .catch(error => {
          this.error = true;
          this.errorMessage = error.response.data.error;
        });
    },
    handleSsoLogin() {
      this.updateToken();
      if (!this.authorization) return;

      if (this.$route.query['sso-redirect'] === 'proprofs-kb') {
        const authorize_url = getApiRoute('api.authorize.proprofs');
        axios
          .get(authorize_url)
          .then(({ data }) => {
            if (!data) return;
            // Redirect user to external client site
            if (!!data.redirect) window.location.href = data.redirect;
          })
          .catch(error => {
            this.error = true;
            this.errorMessage = error.response.data.error;
          });
      }
    },
    ...mapActions({
      checkUserSession: Action.CHECK_USER_SESSION
    }),
    ...mapMutations({
      saveToken: Mutation.SAVE_TOKEN,
      updateToken: Mutation.UPDATE_TOKEN,
      setRedirectPath: Mutation.SET_REDIRECT_PATH
    })
  },
  beforeRouteEnter(to) {
    if (!!to.query.redirect || !!to.query['sso-redirect']) return;

    return store.dispatch(Action.CHECK_USER_SESSION, {
      to,
      alreadyOnLoginPage: true
    });
  }
};
</script>
