import useAxios from 'composables/useAxios';
import router, { getApiRoute } from 'routeconfig';
import { applyDefaultFilterValuesBasedOnQueryParams } from 'helper';

export const useManageAdeffxAndCampaignPageFilters = () => {
  const {
    state: { loading: fetchingPageFilters, data: pageFilters },
    fetcher: filtersFetcher
  } = useAxios({
    initialValue: []
  });

  const fetchPageFilters = ({ adeffx = false, flightId = null }) => {
    let fetchCampaignFiltersAPIRoute = getApiRoute({
      name: 'api.campaigns.filters',
      query: { adeffx }
    });

    if (!!router.currentRoute.value.params.id || router.currentRoute.value.params.id === 0) {
      fetchCampaignFiltersAPIRoute = getApiRoute({
        name: 'api.campaigns.filters',
        query: {
          id: router.currentRoute.value.params.id,
          adeffx,
          ...(flightId ? { flight_id: flightId } : {})
        }
      });
    }

    return filtersFetcher({
      url: fetchCampaignFiltersAPIRoute,
      responseAdapter: data =>
        data?.filters
          ? applyDefaultFilterValuesBasedOnQueryParams({
              filters: data.filters,
              queryParams: router.currentRoute.value.query
            })
          : []
    });
  };

  return {
    fetchPageFilters,
    fetchingPageFilters,
    pageFilters
  };
};
