import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

import kpi from './_modules/kpi';
import general from './_modules/general';

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    kpi,
    general
  }
};
