import { getApiRoute } from 'routeconfig';
import { useAxios } from 'composables';

const useOewaUpdateChannel = () => {
  const {
    state: { loading: updating },
    fetcher
  } = useAxios();

  function updateChannel({ id, channel }) {
    const updateChannelAPIRoute = getApiRoute({
      name: 'api.oewa.channel.edit',
      params: { id }
    });

    return fetcher({
      method: 'post',
      url: updateChannelAPIRoute,
      requestBody: channel
    });
  }

  return {
    updating,
    updateChannel
  };
};

export default useOewaUpdateChannel;
