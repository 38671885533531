const useTimeout = (cb, interval) => {
  let timer = null;

  const stop = () => {
    clearTimeout(timer);
  };

  const start = (...args) => {
    stop();

    timer = setTimeout(() => {
      timer = null;

      cb(...args);
    }, interval);
  };

  return {
    start,
    stop
  };
};

export default useTimeout;
