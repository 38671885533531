<template>
  <div class="oauth c-login">
    <div class="oauth__spinner" v-if="fetchingAuthorizationData">
      <Spin :indicator="{ type: 'loading', style: { color: 'white' } }" size="large" />
    </div>

    <Modal
      v-else
      :visible="true"
      :closable="false"
      class="oauth__modal"
      title="Sign in with Reppublika"
      centered
    >
      <p class="u-mb-x2">
        <strong>{{ clientName }}</strong> wants to access these resources on Reppublika:
      </p>

      <CheckboxGroup :options="scopesCheckboxGroupOptions" align="vertical" v-model="selectedScopes" />

      <template #footer>
        <div class="u-mt-x3 footer">
          <Button :loading="fetchingToken" :disabled="!selectedScopes.length" type="primary" @click="approve">
            Continue as {{ currentUser.name }}
          </Button>
          <Button size="sm" type="link" @click="deny">Cancel</Button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import { parse as parseQuery } from 'qs';
import { BasicModal as Modal, Spin, Button, CheckboxGroup } from 'reppublika_components';
import { capitalize } from 'helper';
import { getApiRoute } from 'routeconfig';

export default {
  name: 'OAuth',
  components: {
    Spin,
    Modal,
    Button,
    CheckboxGroup
  },
  beforeRouteEnter(to, _, next) {
    if (to.query?.client_id) {
      next();
    } else {
      next({ name: 'not-found' });
    }
  },
  data() {
    const selectedScopes = this.$route?.query?.scopes?.split(',') || [];

    return {
      fetchingAuthorizationData: true,
      fetchingToken: false,
      clientName: '',
      redirectUri: '',
      selectedScopes
    };
  },
  created() {
    const requestParams = {
      ...parseQuery(this.$route.query),
      user_id: this.currentUser.id
    };

    axios
      .post(this.fetchAutorizationDataAPIEndpoint, requestParams)
      .then(({ data }) => {
        if (data?.client) {
          this.redirectUri = data.client.redirect_uri;
          this.clientName = data.client.name;

          this.$nextTick(() => {
            if (!this.$route?.query?.scopes) {
              window.location.href = `${this.redirectUriWithDeniedStatus}&message=${encodeURIComponent(
                'no scopes provided in query params'
              )}`;

              return;
            }

            if (data.authorized) {
              return this.fetchToken().then(token => {
                window.location.href = `${this.redirectUriWithApprovedStatus}&code=${token}`;
              });
            }

            this.fetchingAuthorizationData = false;
          });
        }
      })
      .catch(error => {
        this.handleFetchError(error);
      });
  },
  methods: {
    fetchToken() {
      this.fetchingToken = true;

      const requestParams = {
        ...parseQuery(this.$route.query),
        user_id: this.currentUser.id,
        scopes: this.selectedScopes.join(',')
      };

      return axios.post(this.fetchTokenAPIRoute, requestParams).then(({ data }) => {
        if (data?.code) {
          return data.code;
        }
      });
    },
    approve() {
      return this.fetchToken()
        .then(token => {
          window.location.href = `${this.redirectUriWithApprovedStatus}&code=${token}`;
        })
        .catch(error => {
          this.handleFetchError(error);
        });
    },
    deny() {
      window.location.href = this.redirectUriWithDeniedStatus;
    },
    handleFetchError(error) {
      this.fetchingToken = false;
      this.fetchingAuthorizationData = false;

      const errorMessage = error.response?.data || error.message;
      console.error('OAuth flow error: ', errorMessage);

      window.location.href = this.redirectUriWithDeniedStatus;
    }
  },
  computed: {
    scopesCheckboxGroupOptions() {
      if (this.$route?.query?.scopes) {
        return this.$route.query.scopes.split(',').map(scope => ({
          label: capitalize(scope),
          value: scope
        }));
      }

      return [];
    },
    fetchAutorizationDataAPIEndpoint() {
      return getApiRoute('api.oauth.authorize.data');
    },
    fetchTokenAPIRoute() {
      return getApiRoute('api.oauth.authorize');
    },
    redirectUriWithApprovedStatus() {
      return `${this.redirectUri}?status=approved`;
    },
    redirectUriWithDeniedStatus() {
      return `${this.redirectUri}?status=denied`;
    },
    ...mapState(['currentUser'])
  }
};
</script>

<style lang="less" scoped>
.oauth {
  min-height: 100vh;
  height: 100%;
  position: relative;

  &__spinner {
    display: grid;
    place-items: center;
    height: 100%;
  }

  &__modal .footer {
    max-width: 60%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
}
</style>
