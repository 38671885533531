import { Mutation } from '../types';

export default {
  [Mutation.STORE_ADNETWORKS]: (store, value) => {
    store.adnetworks = value;
  },
  [Mutation.STORE_ADNETWORK]: (store, value) => {
    store.adnetwork = value;
  },
  [Mutation.STORE_CATEGORIES]: (store, value) => {
    store.categories = value;
  },
  [Mutation.STORE_CATEGORY_TARGETS]: (store, value) => {
    store.categoryTargets = value;
  },
  [Mutation.STORE_MEDIAHOUSES]: (store, value) => {
    store.mediahouses = value;
  },
  [Mutation.STORE_MEDIAHOUSE]: (store, value) => {
    store.mediahouse = value;
  },
  [Mutation.STORE_WIDGETS]: (store, value) => {
    store.widgets = value;
  }
};
