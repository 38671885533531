import { computed } from 'vue';
import { getApiRoute } from 'routeconfig';
import { useAxios } from 'composables';

const useOewaCreateChannel = () => {
  const {
    state: { loading: creatingChannel },
    fetcher
  } = useAxios();

  const createChannelAPIRoute = computed(() => getApiRoute('api.oewa.channel.create'));

  function createChannel(channel) {
    return fetcher({
      method: 'post',
      url: createChannelAPIRoute.value,
      requestBody: channel
    });
  }

  return {
    createChannel,
    creatingChannel
  };
};

export default useOewaCreateChannel;
