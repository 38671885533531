import { useAxios } from 'composables';

export const useRatingsReachChart = () => {
  const {
    state: { loading: fetchingReachChartData, error: fetchingReachChartDataError, data: reachChartData },
    fetcher
  } = useAxios({});

  const fetchRatingsReachChart = url => {
    return fetcher({
      url,
      responseAdapter: data => data.graph_data
    });
  };

  return {
    fetchRatingsReachChart,
    fetchingReachChartData,
    fetchingReachChartDataError,
    reachChartData
  };
};
