import useAxios, { API_METHODS } from '../useAxios';
import { getApiRoute } from 'routeconfig';

const useTags = () => {
  const {
    state: { loading, data: tags },
    fetcher: findAllFetcher
  } = useAxios({
    initialValue: []
  });
  const { fetcher: createFetcher } = useAxios();
  const { fetcher: updateFetcher } = useAxios();
  const { fetcher: removeFetcher } = useAxios();

  const findAll = () =>
    findAllFetcher({
      url: getApiRoute('api.admin.i18n.tags'),
      responseAdapter: data => data?.data || []
    });

  const create = newTag =>
    createFetcher({
      url: getApiRoute('api.admin.i18n.tags'),
      method: API_METHODS.POST,
      requestBody: newTag
    });

  const update = (id, updatedTag) =>
    updateFetcher({
      url: getApiRoute({
        name: 'api.admin.i18n.tags.tag',
        params: {
          id
        }
      }),
      method: API_METHODS.POST,
      requestBody: updatedTag
    });

  const remove = id =>
    removeFetcher({
      url: getApiRoute({
        name: 'api.admin.i18n.tags.tag',
        params: {
          id
        }
      }),
      method: API_METHODS.DELETE
    });

  return {
    tags,
    loading,
    findAll,
    create,
    update,
    remove
  };
};

export default useTags;
