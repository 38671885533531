import useAxios, { API_METHODS } from 'composables/useAxios';
import { getApiRoute } from 'routeconfig';

const useManageTargetsRegularExpressions = () => {
  const {
    state: { data: serviceTargetsRegularExpressions, loading: serviceTargetsRegularExpressionsLoading },
    fetcher: serviceTargetsRegularExpressionsFetcher
  } = useAxios({
    initialValue: []
  });

  const {
    state: { loading: creatingServiceTargetRegularExpression },
    fetcher: createServiceTargetRegularExpressionFetcher
  } = useAxios();

  const {
    state: { loading: deletingServiceTargetRegularExpression },
    fetcher: deleteServiceTargetRegularExpressionFetcher
  } = useAxios();

  const {
    state: { loading: updatingServiceTargetRegularExpression },
    fetcher: updateServiceTargetRegularExpressionFetcher
  } = useAxios();

  const fetchServiceTargetsRegularExpressions = ({ serviceId }) =>
    serviceTargetsRegularExpressionsFetcher({
      url: getApiRoute({
        name: 'api.ratings.admin.services.regex-patterns',
        params: { id: serviceId }
      }),
      responseAdapter: ({ data }) => data ?? []
    });

  const createServiceTargetRegularExpression = ({ serviceId, pattern }) =>
    createServiceTargetRegularExpressionFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: 'api.ratings.admin.services.regex-pattern.create',
        params: { id: serviceId }
      }),
      requestBody: {
        pattern
      }
    });

  const deleteServiceTargetRegularExpression = ({ serviceId, patternId }) =>
    deleteServiceTargetRegularExpressionFetcher({
      method: API_METHODS.DELETE,
      url: getApiRoute({
        name: 'api.ratings.admin.services.regex-patterns.delete',
        params: { id: serviceId, regex_id: patternId }
      })
    });

  const updateServiceTargetRegularExpression = ({ serviceId, patternId, pattern }) =>
    updateServiceTargetRegularExpressionFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: 'api.ratings.admin.services.regex-patterns.update',
        params: { id: serviceId, regex_id: patternId }
      }),
      requestBody: {
        pattern
      }
    });

  return {
    fetchServiceTargetsRegularExpressions,
    serviceTargetsRegularExpressionsLoading,
    serviceTargetsRegularExpressions,
    createServiceTargetRegularExpression,
    creatingServiceTargetRegularExpression,
    deleteServiceTargetRegularExpression,
    deletingServiceTargetRegularExpression,
    updateServiceTargetRegularExpression,
    updatingServiceTargetRegularExpression
  };
};

export default useManageTargetsRegularExpressions;
