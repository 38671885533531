import { ref } from 'vue';
import { requiredParam } from 'helper';

export const LAYOUTS = {
  PUBLIC_OVERVIEW: Symbol('publicOverviewLayout'),
  INTERNAL_OVERVIEW: Symbol('internalOverviewLayout'),
  PUBLIC_DETAILS: Symbol('publicDetailsLayout'),
  INTERNAL_DETAILS: Symbol('internalDetailsLayout')
};

export const useLayout = () => {
  const layout = ref();

  const setLayout = (layoutType = requiredParam('layoutType')) => {
    layout.value = layoutType;
  };

  return {
    layout,
    setLayout,
    LAYOUTS
  };
};
