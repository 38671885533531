import { computed, watch, unref, h } from 'vue';
import { Message, StarFilled } from 'reppublika_components';
import useAxios from './useAxios';

const useDemographics = searchString => {
  const FAVORITES = 'favorites';
  const {
    state: {
      data,
      loading: loadingDemographics,
      error: hasDemographicsRequestErroredOut,
      timeout: hasDemographicsRequestTimedOut
    },
    fetcher
  } = useAxios({
    initialValue: {}
  });

  const groups = computed(() =>
    (data.value?.groups || [])
      .map(group => {
        if (group.key !== FAVORITES) {
          return group;
        }

        return {
          ...group,
          icon: () =>
            h(StarFilled, {
              style: {
                color: '#efb93f'
              }
            })
        };
      })
      .sort((groupA, groupB) => {
        if (groupA.key === FAVORITES) {
          // sorts groupA (favorites) before groupB
          return -1;
        }

        if (groupB.key === FAVORITES) {
          // sorts groupB (favorites) before groupA
          return 1;
        }

        if (groupA.name < groupB.name) {
          return -1;
        }

        if (groupA.name > groupB.name) {
          return 1;
        }

        return 0;
      })
  );

  const groupsEmpty = computed(() => !groups.value.length);

  const groupsAndAttributesMatchingSearchString = computed(() => {
    if (!searchString.value) {
      return groups.value;
    }

    return groups.value.map(group => ({
      ...group,
      highlight: group.attributes.some(attribute =>
        attribute.name.toLowerCase().includes(unref(searchString)?.toLowerCase())
      ),
      attributes: group.attributes.filter(attribute =>
        attribute.name.toLowerCase().includes(unref(searchString)?.toLowerCase())
      )
    }));
  });

  const highlightedGroups = computed(() =>
    groupsAndAttributesMatchingSearchString.value.filter(group => !!group.highlight)
  );

  const activeGroup = computed(() =>
    groupsAndAttributesMatchingSearchString.value.find(group => group.active)
  );

  watch(searchString, newSearchString => {
    if (!!newSearchString && !!highlightedGroups.value?.length) {
      // highlight first group matching search string
      activateGroup(highlightedGroups.value[0].id);
    }
  });

  const fetchGroups = requestConfig =>
    fetcher(requestConfig)
      .then(({ groups: fetchedGroups }) => {
        if (!fetchedGroups?.length) return;

        const defaultGroup = !!fetchedGroups[0]?.attributes?.length ? fetchedGroups[0] : fetchedGroups[1];

        activateGroup(defaultGroup.id);
      })
      .catch(() => {
        Message.error('Something went wrong while loading social demographics. Please try again later.');
      });

  const activateGroup = newActiveGroupId => {
    const newGroups = groups.value.map(group => {
      // reset active group
      if (group.active && group.id !== newActiveGroupId) {
        return {
          ...group,
          active: false
        };
      }

      if (group.id !== newActiveGroupId) {
        return group;
      }

      return {
        ...group,
        active: true
      };
    });

    updateGroups(newGroups);
  };

  const updateGroups = newGroups => {
    data.value = {
      ...data.value,
      groups: newGroups
    };
  };

  const updateAttribute = updatedAttribute => {
    const updatedGroups = groups.value.map(group => {
      const isFavoritesGroup = group.key === FAVORITES;

      if (isFavoritesGroup) {
        const attributeExistsInFavorites = group.attributes.some(
          attribute => attribute.id === updatedAttribute.id
        );

        if (updatedAttribute.favorite && !attributeExistsInFavorites) {
          return {
            ...group,
            attributes: [...group.attributes, updatedAttribute]
          };
        }

        if (!updatedAttribute.favorite && attributeExistsInFavorites) {
          return {
            ...group,
            attributes: group.attributes.filter(attribute => attribute.id !== updatedAttribute.id)
          };
        }

        return {
          ...group,
          attributes: group.attributes.map(attribute => {
            if (attribute.id !== updatedAttribute.id) {
              return attribute;
            }

            return {
              ...attribute,
              ...updatedAttribute
            };
          })
        };
      }

      if (group.id !== updatedAttribute.group_id) {
        return group;
      }

      return {
        ...group,
        attributes: group.attributes.map(attribute => {
          if (attribute.id !== updatedAttribute.id) {
            return attribute;
          }

          return {
            ...attribute,
            ...updatedAttribute
          };
        })
      };
    });

    updateGroups(updatedGroups);
  };

  return {
    groupsEmpty,
    groupsAndAttributesMatchingSearchString,
    loadingDemographics,
    fetchGroups,
    hasDemographicsRequestErroredOut,
    hasDemographicsRequestTimedOut,
    activeGroup,
    activateGroup,
    updateAttribute
  };
};

export default useDemographics;
