import { APPS } from 'utils/constants.js';
import { exportRoutesWithAppMetaField } from 'utils/helpers.js';
import { TRANSLATION_TAGS } from 'utils/translate';

export const keywordToolModuleRoutes = exportRoutesWithAppMetaField(APPS.KEYWORD_TOOL, [
  {
    path: '/:country_code/keyword-tool',
    name: 'keyword-tool',
    component: () =>
      import(/* webpackChunkName: "ratings-digital" */ '../../views/pages/keys/KeywordsAnalyzer.vue'),
    meta: {
      locale: 'en-US',
      auth: true,
      module: 'ratings',
      hasCountrySwitcher: true,
      translation_tags: [TRANSLATION_TAGS.FILTERS]
    }
  }
]);
