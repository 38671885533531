import { useRoute } from 'vue-router';
import { useAxios } from 'composables';
import { getApiRoute } from 'routeconfig';
import { API_METHODS, RESPONSE_TYPES } from 'composables/useAxios';

export const useCampaignAndFlightTrackingCodes = () => {
  const route = useRoute();

  const {
    state: { loading: loadingTrackingCodes, error: trackingCodesError, data: trackingCodes },
    fetcher: trackingCodesFetcher
  } = useAxios({
    initialValue: []
  });

  const {
    state: { data: updatedTcfFields },
    fetcher: updateFetcher
  } = useAxios();

  const {
    state: { loading: uploadingTrackingCodes },
    fetcher: uploadTrackingCodesFetcher
  } = useAxios({
    headers: true
  });

  const fetchTrackingCodes = ({ campaignId, flightId = null } = {}) => {
    const query = flightId ? { flight_id: flightId } : {};

    return trackingCodesFetcher({
      url: getApiRoute({
        name: 'api.campaigns.tracking.codes',
        params: {
          id: campaignId
        },
        query
      })
    });
  };

  const updateTcfFields = ({ trackingCodesWithUpdatedTcfFields, flightId = null } = {}) => {
    const query = flightId ? { flight_id: flightId } : {};

    return updateFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: 'api.campaigns.trackingcodes.set-options',
        params: {
          id: route.params.id
        },
        query
      }),
      requestBody: {
        options: trackingCodesWithUpdatedTcfFields
      }
    });
  };

  const uploadTrackingCodes = ({ formData, campaignId, flightId = null } = {}) => {
    const query = flightId ? { flight_id: flightId } : {};

    const apiRoute = getApiRoute({
      name: 'api.campaigns.trackingcodes.upload',
      params: {
        id: campaignId.value
      },
      query
    });

    return uploadTrackingCodesFetcher({
      url: apiRoute,
      method: API_METHODS.POST,
      requestBody: formData,
      responseType: RESPONSE_TYPES.BLOB
    });
  };

  return {
    fetchTrackingCodes,
    loadingTrackingCodes,
    trackingCodesError,
    trackingCodes,
    updateTcfFields,
    updatedTcfFields,
    uploadTrackingCodes,
    uploadingTrackingCodes
  };
};
