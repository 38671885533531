import { getApiRoute } from 'routes/config';
import useAxios, { API_METHODS } from '../useAxios';

export const useBrandsList = () => {
  const {
    state: { loading: findAllLoading, error: findAllError, data: brands },
    fetcher: findAllFetcher
  } = useAxios({
    initialValue: []
  });
  const {
    state: { loading: findLoading, error: findError, data: brand },
    fetcher: findFetcher
  } = useAxios({
    initialValue: {}
  });
  const {
    state: { loading: creating },
    fetcher: createFetcher
  } = useAxios();
  const {
    state: { loading: updating },
    fetcher: updateFetcher
  } = useAxios();
  const {
    state: { loading: removing },
    fetcher: removeFetcher
  } = useAxios();
  const {
    state: { loading: bulkRemoving },
    fetcher: bulkRemoveFetcher
  } = useAxios();

  const findAll = () =>
    findAllFetcher({
      url: getApiRoute('api.admin.campaigns.brands'),
      responseAdapter: data => data?.data || []
    });

  const find = id =>
    findFetcher({
      url: getApiRoute({
        name: 'api.admin.campaigns.brands',
        params: {
          id
        }
      })
    });

  const create = brands =>
    createFetcher({
      method: API_METHODS.POST,
      url: getApiRoute('api.admin.campaigns.brands'),
      requestBody: {
        brands: brands.map(brand => ({
          name: brand
        }))
      }
    });

  const update = ({ id, name }) =>
    updateFetcher({
      method: API_METHODS.PUT,
      url: getApiRoute({
        name: 'api.admin.campaigns.brands',
        params: {
          id
        }
      }),
      requestBody: {
        name
      }
    });

  const remove = id =>
    removeFetcher({
      method: API_METHODS.DELETE,
      url: getApiRoute({
        name: 'api.admin.campaigns.brands',
        params: {
          id
        }
      })
    });

  const bulkRemove = brandIDs =>
    bulkRemoveFetcher({
      method: API_METHODS.DELETE,
      url: getApiRoute({
        name: 'api.admin.campaigns.brands'
      }),
      requestBody: {
        brands: brandIDs
      }
    });

  return {
    findAll,
    findAllLoading,
    findAllError,
    brands,
    find,
    findLoading,
    findError,
    brand,
    create,
    creating,
    update,
    updating,
    remove,
    removing,
    bulkRemove,
    bulkRemoving
  };
};
