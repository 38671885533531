import { getApiRoute } from 'routes/config';
import useAxios, { API_METHODS } from '../useAxios';

export const useSurveyTemplates = () => {
  const {
    state: { loading: findAllTemplatesLoading, error: findAllTemplatesError, data: surveyTemplates },
    fetcher: findAllTemplatesFetcher
  } = useAxios({
    initialValue: []
  });
  const {
    state: { loading: findTemplateLoading, error: findTemplateError, data: surveyTemplate },
    fetcher: findTemplateFetcher
  } = useAxios({
    initialValue: {}
  });
  const {
    state: { loading: creatingTemplate },
    fetcher: createTemplateFetcher
  } = useAxios();
  const {
    state: { loading: updatingTemplate },
    fetcher: updateTemplateFercher
  } = useAxios();
  const {
    state: { loading: removingTemplate },
    fetcher: removeTemplateFetcher
  } = useAxios();
  const {
    state: {
      loading: loadingImportableTemplates,
      error: importableTemplatesError,
      data: importableTemplates
    },
    fetcher: listImportableTemplatesFetcher
  } = useAxios({
    initialValue: []
  });
  const {
    fetcher: importTemplateFetcher,
    state: { loading: importingTemplate }
  } = useAxios();
  const {
    state: { loading: bulkRemovingTemplates },
    fetcher: bulkRemoveTemplatesFetcher
  } = useAxios();

  const findAllTemplates = () =>
    findAllTemplatesFetcher({
      url: getApiRoute('api.admin.campaigns.survey-templates'),
      responseAdapter: ({ data }) => data || []
    });

  const findTemplate = id =>
    findTemplateFetcher({
      url: getApiRoute({
        name: 'api.admin.campaigns.survey-templates',
        params: {
          id
        }
      })
    });

  const createTemplate = ({ name, isShared, data }) =>
    createTemplateFetcher({
      method: API_METHODS.POST,
      url: getApiRoute('api.admin.campaigns.survey-templates'),
      requestBody: {
        name,
        public: isShared,
        template_data: data
      }
    });

  const updateTemplate = ({ id, name, isShared, data }) =>
    updateTemplateFercher({
      method: API_METHODS.PUT,
      url: getApiRoute({
        name: 'api.admin.campaigns.survey-templates',
        params: {
          id
        }
      }),
      requestBody: {
        name,
        public: isShared,
        template_data: data
      }
    });

  const removeTemplate = id =>
    removeTemplateFetcher({
      method: API_METHODS.DELETE,
      url: getApiRoute({
        name: 'api.admin.campaigns.survey-templates',
        params: {
          id
        }
      })
    });

  const listImportableTemplates = () =>
    listImportableTemplatesFetcher({
      url: getApiRoute('api.admin.campaigns.survey-template-import.list'),
      responseAdapter: ({ data }) => data || []
    });

  const importTemplate = id =>
    importTemplateFetcher({
      method: API_METHODS.POST,
      url: getApiRoute({
        name: 'api.admin.campaigns.survey-template-import.add',
        params: {
          id
        }
      })
    });

  const bulkRemoveTemplates = templateIDs =>
    bulkRemoveTemplatesFetcher({
      method: API_METHODS.DELETE,
      url: getApiRoute('api.admin.campaigns.survey-templates'),
      requestBody: {
        templates: templateIDs
      }
    });

  return {
    findAllTemplates,
    findAllTemplatesLoading,
    findAllTemplatesError,
    surveyTemplates,
    findTemplate,
    findTemplateLoading,
    findTemplateError,
    surveyTemplate,
    createTemplate,
    creatingTemplate,
    updateTemplate,
    updatingTemplate,
    removeTemplate,
    removingTemplate,
    listImportableTemplates,
    loadingImportableTemplates,
    importableTemplatesError,
    importableTemplates,
    importTemplate,
    importingTemplate,
    bulkRemoveTemplates,
    bulkRemovingTemplates
  };
};
