import qs from 'qs';
import { appliedFilters } from 'composables/oewa/ratings/useOewaRatingsFilters';
import { getApiRoute } from 'routes/config';

export const getOewaRatingsApiRoute = route => {
  const [apiRouteWithoutQuery] = getApiRoute(route).split('?');

  const stringifiedQuery = qs.stringify({ ...(route?.query || {}), ...(appliedFilters.value || {}) });

  return `${apiRouteWithoutQuery}?${stringifiedQuery}`;
};
