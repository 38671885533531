import axios from 'axios';
import { errorlog, handleCallback } from 'helper';
import { getApiRoute } from 'routeconfig';
import { Action, Mutation } from '../types';

export default {
  [Action.GET_COMPARE_BEFORE_CATEGORIES]: ({ commit }, payload = {}) => {
    const url = getApiRoute({ name: 'api.ratings.compare.before-services' });

    return axios
      .post(url, payload.body)
      .then(({ data }) => {
        const { success, before_service_categories, compare_data } = data;

        if (success) {
          commit(`${Mutation.STORE_COMPARE_BEFORE_CATEGORIES}`, before_service_categories);
          commit(`${Mutation.STORE_COMPARE_DATA_BEFORE}`, compare_data);
          handleCallback(payload.callback);
        }
      })
      .catch(errorlog);
  },
  [Action.GET_COMPARE_AFTER_CATEGORIES]: ({ commit }, payload = {}) => {
    const url = getApiRoute({ name: 'api.ratings.compare.after-services' });

    return axios
      .post(url, payload.body)
      .then(({ data }) => {
        const { success, after_service_categories, compare_data } = data;

        if (success) {
          commit(`${Mutation.STORE_COMPARE_AFTER_CATEGORIES}`, after_service_categories);
          commit(`${Mutation.STORE_COMPARE_DATA_AFTER}`, compare_data);
          handleCallback(payload.callback);
        }
      })
      .catch(errorlog);
  },
  [Action.GET_COMPARE_TRAFFIC_DATA]: ({ commit }, payload = {}) => {
    const url = getApiRoute({ name: 'api.ratings.compare.traffic' });

    return axios
      .post(url, payload.body)
      .then(({ data }) => {
        const { success, compare_data } = data;

        if (success) {
          commit(`${Mutation.STORE_COMPARE_TRAFFIC_DATA}`, compare_data);
          handleCallback(payload.callback);
        }
      })
      .catch(errorlog);
  },
  [Action.GET_COMPARE_AUDIENCE_ATTRIBUTES]: ({ commit }, payload = {}) => {
    const url = getApiRoute({ name: 'api.ratings.compare.audience-profile' });

    return axios
      .post(url, payload.body)
      .then(({ data }) => {
        const { success, compare_data, availableAttributes } = data;

        if (success) {
          commit(`${Mutation.STORE_COMPARE_AUDIENCE_ATTRIBUTES}`, availableAttributes);
          commit(`${Mutation.STORE_COMPARE_AUDIENCE_ATTRIBUTES_DATA}`, compare_data);
          handleCallback(payload.callback);
        }
      })
      .catch(errorlog);
  }
};
