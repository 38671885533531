import { Mutation } from '../types';

export default {
  [Mutation.STORE_BILLING_DATA]: (store, value) => {
    store.billingData = value;
  },
  [Mutation.STORE_DETAIL_BILLING_DATA]: (store, value) => {
    store.detailBillingData = value;
  }
};
