import { computed, ref } from 'vue';
import useAxios from '../useAxios';
import { BRANDLIFT_API } from 'constants';

export const brandliftState = ref({ benchmarks: [] });
const loading = ref(false);
const rawBrandliftBenchmarks = ref([]);
const brandliftBenchmarkFilters = ref({});

const useBrandliftBenchmarks = () => {
  function setBenchmarks(benchmarks) {
    rawBrandliftBenchmarks.value = benchmarks;
  }

  const { fetcher: loadFetcher } = useAxios({
    initialValue: []
  });
  const { fetcher: deleteFetcher } = useAxios();
  const { fetcher: exportFetcher } = useAxios();
  const { fetcher: saveFetcher } = useAxios();
  const { fetcher: uploadFetcher } = useAxios();

  async function loadBrandliftBenchmarks(categories, queryParams = {}) {
    let query = '';
    const paramItems = Object.entries(queryParams);

    if (paramItems.length) {
      query = `&${paramItems.map(([key, value]) => `${key}=${value}`).join('&')}`;
    }

    let data;
    try {
      loading.value = true;
      data = await loadFetcher({
        method: 'GET',
        url: `${BRANDLIFT_API}/brandliftBenchmarks?categories=${
          Array.isArray(categories) ? categories.join('||') : categories
        }${query}`
      });
      loading.value = false;
    } catch (err) {
      console.error('failed to fetch benchmarks');
    }
    if (!data) return;
    setBenchmarks(data);
  }

  function deleteBrandliftBenchmarks(payload) {
    return deleteFetcher({
      method: 'DELETE',
      url: `${BRANDLIFT_API}/brandliftBenchmarks`,
      requestBody: payload
    });
  }

  function exportBrandliftBenchmarks(categories, queryParams = {}) {
    let query = '';
    const paramItems = Object.entries(queryParams);

    if (paramItems.length) {
      query = `&${paramItems.map(([key, value]) => `${key}=${value}`).join('&')}`;
    }

    return exportFetcher({
      method: 'GET',
      url: `${BRANDLIFT_API}/brandliftBenchmarks/export?categories=${
        Array.isArray(categories) ? categories.join('||') : categories
      }${query}`
    });
  }

  function saveBrandliftBenchmark(payload) {
    return saveFetcher({
      method: 'POST',
      url: `${BRANDLIFT_API}/brandliftBenchmark`,
      requestBody: payload
    });
  }

  function uploadBrandliftBenchmark(filename) {
    return uploadFetcher({
      method: 'GET',
      url: `${BRANDLIFT_API}/upload-url?fileName=${filename}`
    });
  }

  function setBrandliftBenchmarkFilters(filters) {
    brandliftBenchmarkFilters.value = filters;
  }

  function clearFilters() {
    brandliftBenchmarkFilters.value = {};
  }

  const brandliftBenchmarks = computed(() => {
    const filteredBenchmarks = rawBrandliftBenchmarks.value.filter(item => {
      const filterKeys = Object.keys(brandliftBenchmarkFilters.value);
      if (!filterKeys || filterKeys.length === 0) return true;

      let includeInResults = true;
      filterKeys.forEach(key => {
        const filter = brandliftBenchmarkFilters.value[key];
        if (filter.type === 'number') {
          if (!item[key]) {
            includeInResults = false;
          }

          if (filter.value.from && filter.value.from >= +item[key].replace(',', '.')) {
            includeInResults = false;
          }

          if (filter.value.to && filter.value.to <= +item[key].replace(',', '.')) {
            includeInResults = false;
          }
        } else if (filter.type === 'boolean' && filter.value.length !== 0) {
          if (filter.value.indexOf(item[key].toString()) === -1) {
            includeInResults = false;
          }
        } else if (filter.type === 'text' && filter.value.length !== 0) {
          if (filter.value.indexOf(item[key].toString()) === -1) {
            includeInResults = false;
          }
        }
      });
      return includeInResults;
    });

    return filteredBenchmarks;
  });

  return {
    loading,
    brandliftBenchmarks,
    loadBrandliftBenchmarks,
    deleteBrandliftBenchmarks,
    exportBrandliftBenchmarks,
    saveBrandliftBenchmark,
    uploadBrandliftBenchmark,
    setBrandliftBenchmarkFilters,
    brandliftBenchmarkFilters,
    clearFilters
  };
};

export default useBrandliftBenchmarks;
