import { useRoute } from 'vue-router';
import { useAxios } from 'composables';
import { getApiRoute } from 'routeconfig';
import { API_METHODS } from 'composables/useAxios';

const useCampaignCreatives = () => {
  const route = useRoute();

  const {
    state: {
      loading: loadingCampaignCreatives,
      error: loadingCampaignCreativesError,
      data: campaignCreatives
    },
    fetcher: campaignCreativesFetcher
  } = useAxios();

  const {
    state: { loading: deletingSujet },
    fetcher: deleteSujetFetcher
  } = useAxios();

  const {
    state: {
      loading: loadingDataForNewCreative,
      error: loadingDataForNewCreativeError,
      data: dataForNewCreative
    },
    fetcher: dataForNewCreativeFetcher
  } = useAxios();

  const {
    state: {
      loading: loadingDataForEditingCreative,
      error: loadingDataForEditingCreativeError,
      data: dataForEditingCreative
    },
    fetcher: dataForEditingCreativeFetcher
  } = useAxios();

  const {
    state: { loading: loadingStoreCreative },
    fetcher: storeCreativeFetcher
  } = useAxios();

  const {
    state: { loading: loadingUpdateCreative },
    fetcher: updateCreativeFetcher
  } = useAxios();

  const {
    state: { loading: deletingFallbackImage },
    fetcher: deleteFallbackImageFetcher
  } = useAxios();

  const fetchCampaignCreatives = ({ flightId = null } = {}) => {
    const query = flightId ? { ...route.query, flight_id: flightId } : { ...route.query };

    const apiRoute = getApiRoute({
      name: 'api.campaigns.creatives',
      query
    });

    return campaignCreativesFetcher({
      url: apiRoute
    });
  };

  const deleteSujet = ({ sujetId, campaignId, flightId = null } = {}) => {
    const query = flightId ? { flight_id: flightId } : {};

    const apiRoute = getApiRoute({
      name: 'api.campaigns.sujets.delete',
      params: {
        sujetId,
        campaignId
      },
      query
    });

    return deleteSujetFetcher({
      url: apiRoute,
      method: API_METHODS.DELETE,
      responseAdapter: ({ message }) => message
    });
  };

  const fetchDataForNewCreative = ({ campaignId, flightId = null } = {}) => {
    const query = flightId ? { flight_id: flightId } : {};

    const apiRoute = getApiRoute({
      name: 'api.campaigns.creatives.new',
      params: {
        campaignId
      },
      query
    });

    return dataForNewCreativeFetcher({
      url: apiRoute
    });
  };

  const fetchDataForEditingCreative = ({ campaignId, sujetId, flightId = null } = {}) => {
    const query = flightId ? { flight_id: flightId } : {};

    const apiRoute = getApiRoute({
      name: 'api.campaigns.creatives.edit',
      params: {
        campaignId,
        sujetId
      },
      query
    });

    return dataForEditingCreativeFetcher({
      url: apiRoute
    });
  };

  const storeCreative = ({ campaignId, payload, flightId = null } = {}) => {
    const query = flightId ? { flight_id: flightId } : {};

    const apiRoute = getApiRoute({
      name: 'api.campaigns.creatives.store',
      params: {
        id: campaignId
      },
      query
    });

    return storeCreativeFetcher({
      url: apiRoute,
      method: API_METHODS.POST,
      requestBody: payload,
      responseAdapter: ({ creative }) => creative
    });
  };

  const updateCreative = ({ campaignId, sujetId, payload, flightId = null } = {}) => {
    const query = flightId ? { flight_id: flightId } : {};

    const apiRoute = getApiRoute({
      name: 'api.campaigns.creatives.update',
      params: {
        campaignId,
        sujetId
      },
      query
    });

    return updateCreativeFetcher({
      url: apiRoute,
      method: API_METHODS.POST,
      requestBody: payload,
      responseAdapter: ({ creative }) => creative
    });
  };

  const deleteFallbackImage = ({ campaignId, sujetId, flightId = null } = {}) => {
    const query = flightId ? { flight_id: flightId } : {};

    const apiRoute = getApiRoute({
      name: 'api.campaigns.sujets.delete-fallback-image',
      params: {
        campaignId,
        sujetId
      },
      query
    });

    return deleteFallbackImageFetcher({
      url: apiRoute,
      method: API_METHODS.DELETE
    });
  };

  return {
    fetchCampaignCreatives,
    loadingCampaignCreatives,
    loadingCampaignCreativesError,
    campaignCreatives,
    deleteSujet,
    deletingSujet,
    fetchDataForNewCreative,
    loadingDataForNewCreative,
    loadingDataForNewCreativeError,
    dataForNewCreative,
    fetchDataForEditingCreative,
    loadingDataForEditingCreative,
    loadingDataForEditingCreativeError,
    dataForEditingCreative,
    storeCreative,
    loadingStoreCreative,
    updateCreative,
    loadingUpdateCreative,
    deleteFallbackImage,
    deletingFallbackImage
  };
};

export default useCampaignCreatives;
