import { Message } from 'reppublika_components';
import { getApiRoute } from 'routeconfig';
import { useAxios } from 'composables';

const useOewaChannelActiveConfiguration = () => {
  const {
    state: { loading: loadingActiveConfiguration, error: hasActiveConfigurationFetchErroredOut },
    fetcher
  } = useAxios();

  function fetchActiveConfiguration(id) {
    const fetchActiveConfigAPIRoute = getApiRoute({
      name: 'api.oewa.channel.history.active',
      params: { id }
    });

    return fetcher({
      url: fetchActiveConfigAPIRoute
    }).catch(() => {
      Message.error('Something went wrong while loading active configuration. Please try again later.');
    });
  }

  return {
    fetchActiveConfiguration,
    loadingActiveConfiguration,
    hasActiveConfigurationFetchErroredOut
  };
};

export default useOewaChannelActiveConfiguration;
